// contexts/SidebarContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "auth-context"; // Ensure this path is correct

// Import your routes
import routesData from "routes.js";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [visibleRoutes, setVisibleRoutes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";

  // Function to fetch visible routes from the backend
  const fetchVisibleRoutes = async () => {
    if (!accountID) {
      return null;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:visible-routes",
        },
      });

      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "http://localhost:8080"
          : "https://lysto-be-tf.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${accountID}/admin/visible-routes`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const fetchedVisibleRoutes = response.data;
      setVisibleRoutes(fetchedVisibleRoutes);
      setLoading(false);
      return fetchedVisibleRoutes; // Return the fetched data
    } catch (err) {
      console.error("Error fetching visible routes:", err);
      setError("Failed to load visible routes.");
      setLoading(false);
      return null; // Return null in case of error
    }
  };

  // Function to insert/update visible routes to the backend
  const updateVisibleRoutes = async (updatedRoutes) => {
    if (!accountID) {
      // Optionally, you can choose to wait until accountID is available
      setError("Account ID is missing.");
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "write:visible-routes", // Adjust scope as needed
        },
      });

      console.log("Updating visible routes:", updatedRoutes);
      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "http://localhost:8080"
          : "https://lysto-be-tf.azurewebsites.net";
      await axios.post(
        `${target}/api/accounts/${accountID}/admin/visible-routes`,
        JSON.stringify(updatedRoutes),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.error("Error updating visible routes:", err);
      setError("Failed to update visible routes.");
    }
  };

  // Initialize visibleRoutes by fetching from the backend or defaulting to all visible
  useEffect(() => {
    const initializeVisibility = async () => {
      if (!accountID) {
        setLoading(true);
        setError(null);
        return;
      }

      const fetchedRoutes = await fetchVisibleRoutes();

      if (!fetchedRoutes || Object.keys(fetchedRoutes).length === 0) {
        const initialVisibility = {};
        routesData.forEach((route) => {
          initialVisibility[route.name] = !route.hidden;
        });
        setVisibleRoutes(initialVisibility);
        await updateVisibleRoutes(initialVisibility);
      }
    };

    initializeVisibility();
  }, [accountID]);

  // Save visibility state to the backend whenever it changes
  useEffect(() => {
    // Avoid updating if still loading or if there's an error
    if (!loading && !error && accountID) {
      updateVisibleRoutes(visibleRoutes);
    }
  }, [visibleRoutes]);

  const toggleRouteVisibility = (routeName) => {
    setVisibleRoutes((prev) => ({
      ...prev,
      [routeName]: !prev[routeName],
    }));
  };

  // Optionally, you can handle the scenario where accountID becomes available after being unavailable
  useEffect(() => {
    if (accountID && loading) {
      fetchVisibleRoutes();
    }
  }, [accountID]);

  return (
    <SidebarContext.Provider
      value={{
        visibleRoutes,
        toggleRouteVisibility,
        loading,
        error,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
