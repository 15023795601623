const updatedConversationData = [
  {
    message_id: "msg001",
    external_message_id: "ext001",
    identity: "agent",
    user_name: "Manuel from Dyne",
    message_content: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <p>Hello!</p>
          <p>I'm Manuel from Dyne. We've helped over <strong>750 restaurants</strong> elevate their brand presence and drive growth. I'd love to show you how our all-in-one platform can help your restaurant with:</p>
          <ul>
            <li>Marketing</li>
            <li>Store operations</li>
            <li>Real-time analytics</li>
          </ul>
          <p>Would you be interested in booking a demo to see how we can fuel your restaurant's growth?</p>
          <p style="color: #4a90e2;">📅 <a href="#" style="color: #4a90e2; text-decoration: none;">Book a Demo</a></p>
        </div>
      `,
    status: "sent",
    type: "message",
    date_sent: "September 6, 2024",
    time_sent: "10:30 AM",
    is_forwarded: false,
    email: "sarah@dyne.com",
    notes: [],
  },
  {
    message_id: "msg002",
    external_message_id: "ext002",
    identity: "customer",
    user_name: "Alex Chen",
    message_content: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <p>Hi Manuel,</p>
          <p>Thanks for reaching out. I've heard about Dyne before. Can you tell me more about your AI capabilities? We're particularly interested in improving our digital brand presence.</p>
        </div>
      `,
    status: "sent",
    type: "message",
    date_sent: "September 6, 2024",
    time_sent: "2:15 PM",
    is_forwarded: false,
    email: "alex.chen@examplerestaurant.com",
    notes: [],
  },
  {
    message_id: "msg003",
    external_message_id: "ext003",
    identity: "agent",
    user_name: "Manuel from Dyne",
    message_content: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <p>Hello Alex,</p>
          <p>I'm glad to hear you're interested! Our AI capabilities are designed to help you build and enhance your digital brand. We offer tools for:</p>
          <ul>
            <li>Customer trend analysis</strong></li>
            <li>Advanced analytics and reporting</strong></li>
            <li>AI-powered content generation</strong></li>
          </ul>
          <p>This helps you engage with new audiences and drive tangible results. Would you like me to elaborate on any specific aspect of our AI features?</p>
        </div>
      `,
    status: "sent",
    type: "message",
    date_sent: "September 6, 2024",
    time_sent: "2:30 PM",
    is_forwarded: false,
    email: "sarah@dyne.com",
    notes: [],
  },
  {
    message_id: "msg004",
    external_message_id: "ext004",
    identity: "customer",
    user_name: "Alex Chen",
    message_content: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <p>That sounds interesting. I'm particularly curious about the content generation. How does that work, and how can it help our restaurant?</p>
        </div>
      `,
    status: "sent",
    type: "message",
    date_sent: "September 6, 2024",
    time_sent: "3:00 PM",
    is_forwarded: false,
    email: "alex.chen@examplerestaurant.com",
    notes: [],
  },
  {
    message_id: "msg005",
    external_message_id: null,
    identity: "agent",
    user_name: "Manuel from Dyne",
    message_content: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <p>Great question, Alex!</p>
          <p>Our AI-powered content generation tool helps you create:</p>
          <ul>
            <li>Engaging social media posts</li>
            <li>Email marketing campaigns</li>
            <li>Menu descriptions</li>
          </ul>
          <p>It learns your brand voice and can suggest content based on current trends and your customer data. This saves you time and ensures consistent, high-quality content across all your channels.</p>
          <p>Many of our clients, like <em>Urban Forest Bistro</em> and <em>Kozu Sushi Pizza</em>, have seen significant improvements in their online engagement using these tools.</p>
          <p>Would you like to see a demo of how this could work for your restaurant?</p>
        </div>
      `,
    status: "draft",
    type: "message",
    date_sent: null,
    time_sent: null,
    is_forwarded: false,
    email: "sarah@dyne.com",
    notes: [],
  },
];

export default updatedConversationData;
