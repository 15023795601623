import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Icon,
  Text,
  Tag,
  VStack,
} from '@chakra-ui/react';
import { FaBuilding, FaUser } from 'react-icons/fa';
import LystoLogo from 'assets/img/lysto-logo-simple.svg';
import DealStages from './DealStages'; // Import the new DealStages component

const DealDetail = ({ deal }) => {
  const { dealname, summary, account, contacts = [], tags = [], stages, currentStageIndex } = deal.properties;

  const renderTags = (tags) =>
    tags.map((tag, index) => (
      <Tag
        key={index}
        size="lg"
        borderRadius="lg"
        mr={2}
        mb={2}
        px={3}
        py={1}
        bg="white"
        borderWidth="1px"
        borderColor="gray.300"
      >
        <Flex alignItems="center">
          <Box height="10px" width="10px" borderRadius="full" bg={tag.color} mr={2}></Box>
          <Text fontSize="sm" color="black">
            {tag.name}
          </Text>
        </Flex>
      </Tag>
    ));

  return (
    <Box bg="white" p={6} pt={4} borderRadius="md" boxShadow="sm" mb={1}>
      <VStack spacing={4} align="stretch">
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>
            Summary
          </Text>
          <Flex alignItems="center" borderWidth="1px" borderColor="gray.300" borderRadius="lg" p={3}>
            <img src={LystoLogo} alt="Lysto Logo" style={{ width: '24px', height: '24px', marginRight: '12px' }} />
            <Text fontSize="sm" color="gray.600">
              {summary || 'No summary available for this deal.'}
            </Text>
          </Flex>
        </Box>

        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>
            Overview
          </Text>
          <Flex alignItems="center" flexWrap="wrap">
            <Tag
              size="lg"
              borderRadius="lg"
              mr={2}
              mb={2}
              px={3}
              py={1}
              bg="white"
              borderWidth="1px"
              borderColor="gray.300"
            >
              <Flex alignItems="center">
                <Icon as={FaBuilding} color="gray.500" mr={2} />
                <Text fontSize="sm" color="black">
                  {dealname}
                </Text>
              </Flex>
            </Tag>
            {renderTags(tags)}
          </Flex>
        </Box>

        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>
            Account
          </Text>
          <Flex alignItems="center" flexWrap="wrap">
            <Tag
              size="lg"
              borderRadius="lg"
              mr={2}
              mb={2}
              px={3}
              py={1}
              bg="white"
              borderWidth="1px"
              borderColor="gray.300"
            >
              <Flex alignItems="center">
                <Icon as={FaBuilding} color="gray.500" mr={2} />
                <Text fontSize="sm" color="black">
                  {account}
                </Text>
              </Flex>
            </Tag>
          </Flex>
        </Box>

        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>
            Contacts
          </Text>
          <Flex alignItems="center" flexWrap="wrap">
            {contacts.map((contact, index) => (
              <Tag
                key={index}
                size="lg"
                borderRadius="lg"
                mr={2}
                mb={2}
                px={3}
                py={1}
                bg="white"
                borderWidth="1px"
                borderColor="gray.300"
              >
                <Flex alignItems="center">
                  <Icon as={FaUser} color="gray.500" mr={2} />
                  <Text fontSize="sm" color="black">
                    {contact}
                  </Text>
                </Flex>
              </Tag>
            ))}
          </Flex>
        </Box>

        {/* Add Deal Stages component */}
        <DealStages stages={stages} currentStageIndex={currentStageIndex} />
      </VStack>
    </Box>
  );
};

// Define PropTypes
DealDetail.propTypes = {
  deal: PropTypes.shape({
    properties: PropTypes.shape({
      dealname: PropTypes.string.isRequired,
      summary: PropTypes.string,
      account: PropTypes.string.isRequired,
      contacts: PropTypes.arrayOf(PropTypes.string),
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ),
      stages: PropTypes.array.isRequired,
      currentStageIndex: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default DealDetail;
