import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

import Lysto from "assets/img/lysto-logo.svg";

export function SidebarBrand() {
  //   Chakra color modele
  return (
    <Flex align="left" direction="column">
      <Image src={Lysto} mb="10px" mt="5px" ml="2" mr="55" />
    </Flex>
  );
}

export default SidebarBrand;
