// src/components/RichCards/EmailCard.js

import React from "react";
import {
  Box,
  VStack,
  Text,
  Divider,
  Link,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { MdAttachment } from "react-icons/md";

// Mapping of email addresses to names
const emailToName = {
  "jane.doe@example.com": "Jane Doe",
  "john.smith@example.com": "John Smith",
  "sales@example.com": "Sales Team",
  "john.doe@example.com": "John Doe",
  "alice.brown@example.com": "Alice Brown",
  "kevin.lee@example.com": "Kevin Lee",
  "linda.kim@example.com": "Linda Kim",
  "nico@bonneetfilou.com": "Nico Nemeth",
  "garrett@shopcanal.com": "Garrett Schubiner",
  "kaili.vance@shopcanal.com": "Kaili Vance",
  // Add more mappings as needed
};

/**
 * Helper function to parse the email description.
 * Extracts To, CC, BCC, Attachment, Subject, and Body.
 *
 * @param {string} description - The raw description string from the activity.
 * @returns {object} An object containing the parsed fields.
 */
const parseEmailDescription = (description) => {
  const lines = description.split("\n");
  const parsed = {
    to: "N/A",
    cc: "N/A",
    bcc: "N/A",
    attachment: "None",
    subject: "No Subject",
    body: "No Content",
  };

  let bodyIndex = lines.findIndex((line) =>
    line.trim().toLowerCase().startsWith("body:")
  );

  // Iterate through each line to extract key-value pairs
  lines.forEach((line, index) => {
    // TODO: remove index
    console.log("index:", index);
    const trimmedLine = line.trim();
    if (trimmedLine.toLowerCase().startsWith("to:")) {
      parsed.to = trimmedLine.substring(3).trim() || "N/A";
    } else if (trimmedLine.toLowerCase().startsWith("cc:")) {
      parsed.cc = trimmedLine.substring(3).trim() || "N/A";
    } else if (trimmedLine.toLowerCase().startsWith("bcc:")) {
      const bccValue = trimmedLine.substring(4).trim();
      parsed.bcc =
        bccValue && !bccValue.toLowerCase().includes("attachment")
          ? bccValue
          : "N/A";
    } else if (trimmedLine.toLowerCase().startsWith("attachment:")) {
      const attachmentValue = trimmedLine.substring(11).trim();
      parsed.attachment =
        attachmentValue && attachmentValue.toLowerCase() !== "--none--"
          ? attachmentValue
          : "None";
    } else if (trimmedLine.toLowerCase().startsWith("subject:")) {
      parsed.subject = trimmedLine.substring(8).trim() || "No Subject";
    }
  });

  // Extract Body
  if (bodyIndex !== -1) {
    const bodyLines = lines.slice(bodyIndex + 1);
    parsed.body = bodyLines.join("\n").trim() || "No Content";
  }

  return parsed;
};

/**
 * Helper function to format the email body by removing excessive whitespace.
 * - Replaces multiple line breaks with two for paragraph separation.
 * - Trims leading and trailing whitespace.
 *
 * @param {string} body - The raw email body.
 * @returns {string} The formatted email body.
 */
const formatEmailBody = (body) => {
  if (!body) return "";

  // Replace three or more consecutive line breaks with two
  let formatted = body.replace(/(\r?\n){3,}/g, "\n\n");

  // Replace two consecutive line breaks with two (for paragraph separation)
  formatted = formatted.replace(/(\r?\n){2}/g, "\n\n");

  // Remove trailing spaces on each line
  formatted = formatted.replace(/[ \t]+$/gm, "");

  // Trim leading and trailing whitespace
  formatted = formatted.trim();

  return formatted;
};

/**
 * EmailCard Component
 *
 * @param {object} props
 * @param {object} props.activity - The activity object containing email details.
 * @returns {JSX.Element}
 */
const EmailCard = ({ activity }) => {
  // Parse the description to extract email details
  const { to, cc, bcc, attachment, subject, body } = parseEmailDescription(
    activity.description || ""
  );

  // Function to map email addresses to names
  const mapEmailsToNames = (emails) => {
    if (emails === "N/A") return emails;
    return emails
      .split(",")
      .map((email) => email.trim())
      .map((email) => emailToName[email] || email)
      .join(", ");
  };

  // Map 'To', 'CC', and 'BCC' fields
  const mappedTo = mapEmailsToNames(to);
  const mappedCc = mapEmailsToNames(cc);
  const mappedBcc = mapEmailsToNames(bcc);

  // Handle Attachments
  const hasAttachments = attachment && attachment !== "None";

  // Format the email body to remove excessive whitespace
  const formattedBody = formatEmailBody(body);

  return (
    <Box
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      width="100%"
      border="1px solid"
      borderColor="gray.200"
    >
      <VStack align="stretch" spacing={3}>
        <Divider />
        <Text>
          <strong>From:</strong>{" "}
          {emailToName[activity.user_email] || activity.user_email}
        </Text>
        <Text>
          <strong>To:</strong> {mappedTo}
        </Text>
        {cc !== "N/A" && (
          <Text>
            <strong>CC:</strong> {mappedCc}
          </Text>
        )}
        {bcc !== "N/A" && (
          <Text>
            <strong>BCC:</strong> {mappedBcc}
          </Text>
        )}
        <Text>
          <strong>Subject:</strong> {subject}
        </Text>
        <Box bg="gray.50" p={3} borderRadius="md">
          <Text fontSize="sm" whiteSpace="pre-wrap">
            {formattedBody}
          </Text>
        </Box>

        {/* Attachments */}
        {hasAttachments && (
          <HStack>
            <Icon as={MdAttachment} color="gray.500" />
            <VStack align="start" spacing={0}>
              {/* If attachments are multiple, split by comma or provide as an array */}
              {attachment.split(",").map((att, index) => (
                <Link key={index} href="#" color="blue.500" isExternal>
                  {att.trim() || `Attachment ${index + 1}`}
                </Link>
              ))}
            </VStack>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default EmailCard;
