import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Textarea,
  IconButton,
  Text,
  Image,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { Send } from "lucide-react";
import lystoLogo from "assets/img/lysto-logo-simple.svg";
import { useAuth0 } from "@auth0/auth0-react";

const InitialView = ({ onSendMessage }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [greeting, setGreeting] = useState("");
  const { user } = useAuth0();

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      let newGreeting = "";
      if (currentHour >= 5 && currentHour < 12) {
        newGreeting = "Good morning";
      } else if (currentHour >= 12 && currentHour < 18) {
        newGreeting = "Good afternoon";
      } else {
        newGreeting = "Good evening";
      }
      setGreeting(`${newGreeting}, ${user.name.split(" ")[0]}`);
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [user.name]);

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      onSendMessage(inputMessage);
      setInputMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const exampleActions = [
    "Generate a summary of today's customer interactions",
    "Update Hubspot with the latest client meeting notes",
    "Create a follow-up task in Jira based on recent customer feedback",
  ];

  return (
    <Flex direction="column" align="center" justify="top" height="100%" p={4}>
      <Flex align="center" mb={4} pt={16}>
        <Image src={lystoLogo} alt="Logo" boxSize="40px" mr={3} />
        <Text fontSize="4xl" fontWeight="medium">
          {greeting}
        </Text>
      </Flex>
      <Box
        width="100%"
        maxWidth="650px"
        bg="white"
        borderRadius="lg"
        boxShadow="md"
        p={4}
        mb={4}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
        >
          <Box position="relative">
            <Textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="How can Leo help you today?"
              size="md"
              resize="vertical"
              minHeight="150px"
              pr="40px"
              border="none"
              _focus={{
                boxShadow: "none",
                outline: "none",
              }}
              sx={{
                "&::placeholder": {
                  color: "gray.400",
                },
              }}
            />
            {inputMessage.trim() !== "" && (
              <IconButton
                icon={<Send size={15} />}
                colorScheme="blue"
                size="sm"
                type="submit"
                position="absolute"
                bottom="8px"
                right="8px"
                aria-label="Send message"
                onClick={handleSendMessage}
                zIndex={1}
              />
            )}
          </Box>
        </form>
      </Box>
      <SimpleGrid columns={3} spacing={4} width="100%" maxWidth="650px">
        {exampleActions.map((action, index) => (
          <Button
            key={index}
            onClick={() => onSendMessage(action)}
            variant="outline"
            size="sm"
            height="auto"
            whiteSpace="normal"
            textAlign="left"
            py={2}
            px={3}
          >
            {action}
          </Button>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default InitialView;
