// src/transformers.js

import { PRIMARY_COLOR, SECONDARY_COLOR, CHART_COLORS } from "./constants";

/**
 * Capitalizes the first letter of a string.
 * @param {string} string - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Transforms dashboard data into chart-ready formats
 * @param {Object} dashboardData - Raw dashboard data for the selected time range and representative
 * @param {Object} selectedRep - The selected representative ('team' or specific rep object)
 * @returns {Object} Transformed data for all dashboard components
 */
export const transformDashboardData = (dashboardData, selectedRep) => {
  if (!dashboardData) {
    console.error("No dashboard data provided to transformDashboardData.");
    return {};
  }

  // Ensure required properties exist
  const requiredProperties = [
    "totalLeads",
    "leadGrowth",
    "meetings",
    "meetingGrowth",
    "emails",
    "emailGrowth",
    "calls",
    "callGrowth",
    "linkedin_messages_sent",
    "linkedin_messages_received",
    "linkedin_invites",
    "leadSourceData",
    "velocityData",
    "stageDurationData",
    "activityData",
    "conversionData",
    "members",
  ];

  for (let prop of requiredProperties) {
    if (dashboardData[prop] === undefined) {
      console.warn(`Property "${prop}" is missing in dashboardData.`);
    }
  }

  // Function to get member data if a specific member is selected
  const getMemberData = () => {
    const member = dashboardData.members.find(
      (m) => m.id === selectedRep.id
    );
    if (!member) {
      console.error(
        `Member "${selectedRep.name}" not found in dashboardData.members.`
      );
      return null;
    }
    return member;
  };

  const memberData = getMemberData();

  // Dynamic stats transformation
  const statsData =
    selectedRep.name === "team"
      ? {
          totalLeads: dashboardData.totalLeads || 0,
          leadGrowth: (dashboardData.leadGrowth || 0).toFixed(2),
          meetings: dashboardData.meetings || 0,
          meetingGrowth: (dashboardData.meetingGrowth || 0).toFixed(2),
          emails: dashboardData.emails || 0,
          emailGrowth: (dashboardData.emailGrowth || 0).toFixed(2),
          calls: dashboardData.calls || 0,
          callGrowth: (dashboardData.callGrowth || 0).toFixed(2),
          // LinkedIn stats
          linkedinMessagesSent: dashboardData.linkedin_messages_sent || 0,
          linkedinMessagesSentGrowth: (
            dashboardData.linkedin_messages_sent_growth || 0
          ).toFixed(2),
          linkedinMessagesReceived:
            dashboardData.linkedin_messages_received || 0,
          linkedinMessagesReceivedGrowth: (
            dashboardData.linkedin_messages_received_growth || 0
          ).toFixed(2),
          linkedinInvites: dashboardData.linkedin_invites || 0,
          linkedinInvitesGrowth: (
            dashboardData.linkedin_invites_growth || 0
          ).toFixed(2),
          // Additional LinkedIn metrics
          newLeads: dashboardData.new_leads || 0,
          viewedProfiles: dashboardData.viewed_profiles || 0,
          // Total Activities (sum of all activity-related metrics)
          totalActivities:
            (dashboardData.meetings || 0) +
            (dashboardData.emails || 0) +
            (dashboardData.calls || 0) +
            (dashboardData.linkedin_messages_sent || 0) +
            (dashboardData.linkedin_messages_received || 0) +
            (dashboardData.linkedin_invites || 0),
          totalActivitiesGrowth: (
            dashboardData.totalActivitiesGrowth || 0
          ).toFixed(2),
        }
      : memberData
      ? {
          // For individual members
          totalLeads:
            memberData.totalLeads !== undefined
              ? memberData.totalLeads
              : "N/A",
          leadGrowth:
            memberData.leadGrowth !== undefined
              ? (memberData.leadGrowth || 0).toFixed(2)
              : "N/A",
          meetings: memberData.meetings || 0,
          meetingGrowth: (memberData.meetingGrowth || 0).toFixed(2),
          emails: memberData.emails || 0,
          emailGrowth: (memberData.emailGrowth || 0).toFixed(2),
          calls: memberData.calls || 0,
          callGrowth: (memberData.callGrowth || 0).toFixed(2),
          // LinkedIn stats
          linkedinMessagesSent: memberData.linkedinMessagesSent || 0,
          linkedinMessagesSentGrowth: (
            memberData.linkedinMessagesSentGrowth || 0
          ).toFixed(2),
          linkedinMessagesReceived: memberData.linkedinMessagesReceived || 0,
          linkedinMessagesReceivedGrowth: (
            memberData.linkedinMessagesReceivedGrowth || 0
          ).toFixed(2),
          linkedinInvites: memberData.linkedinInvites || 0,
          linkedinInvitesGrowth: (
            memberData.linkedinInvitesGrowth || 0
          ).toFixed(2),
          // Additional LinkedIn metrics
          newLeads: memberData.newLeads || 0,
          viewedProfiles: memberData.viewedProfiles || 0,
          // Total Activities
          totalActivities: memberData.totalActivities || 0,
          totalActivitiesGrowth: (
            memberData.totalActivitiesGrowth || 0
          ).toFixed(2),
        }
      : {
          // Fallback if member data is not found
          totalLeads: "N/A",
          leadGrowth: "N/A",
          meetings: 0,
          meetingGrowth: 0,
          emails: 0,
          emailGrowth: 0,
          calls: 0,
          callGrowth: 0,
          linkedinMessagesSent: "N/A",
          linkedinMessagesSentGrowth: "N/A",
          linkedinMessagesReceived: "N/A",
          linkedinMessagesReceivedGrowth: "N/A",
          linkedinInvites: "N/A",
          linkedinInvitesGrowth: "N/A",
          newLeads: "N/A",
          viewedProfiles: "N/A",
          totalActivities: 0,
          totalActivitiesGrowth: 0,
        };

  // Velocity trend data transformation
  const velocityTransformedData = {
    title: "Deal Velocity",
    labels:
      dashboardData.velocityData?.trend?.map((item) =>
        new Date(item.date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        })
      ) || [],
    datasets: [
      {
        label:
          selectedRep.name === "team" ? "Velocity Score" : "Velocity Score",
        data:
          selectedRep.name === "team"
            ? dashboardData.velocityData?.trend?.map(
                (item) => item.teamScore
              ) || []
            : dashboardData.velocityData?.trend?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.velocityCurrent || 0 : 0;
              }) || [],
        borderColor: PRIMARY_COLOR,
        tension: 0.4,
      },
      {
        label: "Benchmark",
        data:
          selectedRep.name === "team"
            ? dashboardData.velocityData?.trend?.map(
                (item) => item.benchmark
              ) || []
            : dashboardData.velocityData?.trend?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.velocityBenchmark || 0 : 0;
              }) || [],
        borderColor: SECONDARY_COLOR,
        tension: 0.4,
        borderDash: [5, 5],
      },
    ],
    summary: {
      value:
        selectedRep.name === "team"
          ? dashboardData.velocityData?.current?.team?.toFixed(1) || "N/A"
          : memberData
          ? memberData.velocityCurrent?.toFixed(1) || "N/A"
          : "N/A",
      benchmark:
        selectedRep.name === "team"
          ? dashboardData.velocityData?.teamAverage?.toFixed(1) || "N/A"
          : memberData
          ? memberData.velocityBenchmark?.toFixed(1) || "N/A"
          : "N/A",
    },
  };

  // Stage duration data transformation
  const durationTransformedData = {
    title: "Stage Duration Analysis",
    labels: dashboardData.stageDurationData?.map((item) => item.stage) || [],
    datasets: [
      {
        label: "Duration (days)",
        data:
          selectedRep.name === "team"
            ? dashboardData.stageDurationData?.map(
                (item) => item.team.duration
              ) || []
            : dashboardData.stageDurationData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.duration || 0 : 0;
              }) || [],
        backgroundColor:
          dashboardData.stageDurationData?.map((item) => {
            if (selectedRep.name === "team") {
              switch (item.team.status) {
                case "good":
                  return SECONDARY_COLOR;
                case "warning":
                  return "#ECC94B"; // Amber
                case "danger":
                  return "#F56565"; // Red
                default:
                  return PRIMARY_COLOR;
              }
            } else {
              const member = dashboardData.members.find(
                (m) => m.id === selectedRep.id
              );
              if (member) {
                switch (member.status) {
                  case "good":
                    return SECONDARY_COLOR;
                  case "warning":
                    return "#ECC94B"; // Amber
                  case "danger":
                    return "#F56565"; // Red
                  default:
                    return PRIMARY_COLOR;
                }
              }
              return PRIMARY_COLOR;
            }
          }) || [],
        borderRadius: 6,
      },
    ],
  };

  // Activity data transformation (Activity by Stage)
  const activityTransformedData = {
    title: "Activity by Stage",
    labels: dashboardData.activityData?.map((item) => item.stage) || [],
    datasets: [
      {
        label: "Invites",
        data:
          selectedRep.name === "team"
            ? dashboardData.activityData?.map((item) => item.linkedin_invites) ||
              []
            : dashboardData.activityData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.linkedinInvites || 0 : 0;
              }) || [],
        backgroundColor: CHART_COLORS[0],
        borderRadius: 6,
      },
      {
        label: "Messages Sent",
        data:
          selectedRep.name === "team"
            ? dashboardData.activityData?.map(
                (item) => item.linkedin_messages_sent
              ) || []
            : dashboardData.activityData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.linkedinMessagesSent || 0 : 0;
              }) || [],
        backgroundColor: CHART_COLORS[1],
        borderRadius: 6,
      },
      {
        label: "Messages Received",
        data:
          selectedRep.name === "team"
            ? dashboardData.activityData?.map(
                (item) => item.linkedin_messages_received
              ) || []
            : dashboardData.activityData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.linkedinMessagesReceived || 0 : 0;
              }) || [],
        backgroundColor: CHART_COLORS[2],
        borderRadius: 6,
      },
      {
        label: "Emails",
        data:
          selectedRep.name === "team"
            ? dashboardData.activityData?.map((item) => item.emails) || []
            : dashboardData.activityData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.emails || 0 : 0;
              }) || [],
        backgroundColor: CHART_COLORS[3],
        borderRadius: 6,
      },
      {
        label: "Calls",
        data:
          selectedRep.name === "team"
            ? dashboardData.activityData?.map((item) => item.calls) || []
            : dashboardData.activityData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.calls || 0 : 0;
              }) || [],
        backgroundColor: CHART_COLORS[4],
        borderRadius: 6,
      },
      {
        label: "Meetings",
        data:
          selectedRep.name === "team"
            ? dashboardData.activityData?.map((item) => item.meetings) || []
            : dashboardData.activityData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.meetings || 0 : 0;
              }) || [],
        backgroundColor: CHART_COLORS[5],
        borderRadius: 6,
      },
    ],
    options: {
      scales: {
        x: { stacked: true }, // Stack the bars
        y: { stacked: true },
      },
    },
  };

  // Conversion rates data transformation
  const conversionTransformedData = {
    title: "Stage Conversion Rates",
    labels:
      dashboardData.conversionData?.map(
        (item) => `${item.fromStage} → ${item.toStage}`
      ) || [],
    datasets: [
      {
        label: "Conversion Rate (%)",
        data:
          selectedRep.name === "team"
            ? dashboardData.conversionData?.map((item) => item.teamRate) || []
            : dashboardData.conversionData?.map(() => {
                const member = dashboardData.members.find(
                  (m) => m.id === selectedRep.id
                );
                return member ? member.rate || 0 : 0;
              }) || [],
        backgroundColor: PRIMARY_COLOR,
        borderRadius: 6,
      },
    ],
  };

  // Activity Chart Data Transformation (Activity by Rep or Type)
  let activityChartTransformedData;

  if (selectedRep.name === "team") {
    // Filter out members whose name is "team"
    const filteredMembers = dashboardData.members.filter(
      (member) => member.name.toLowerCase() !== "team"
    );

    // Activity by Rep
    activityChartTransformedData = {
      title: "Activity by Rep",
      labels: filteredMembers.map((member) => capitalize(member.name)),
      datasets: [
        {
          label: "Total Activity",
          data: filteredMembers.map(
            (member) =>
              (member.linkedinInvites || 0) +
              (member.linkedinMessagesSent || 0) +
              (member.linkedinMessagesReceived || 0) +
              (member.emails || 0) +
              (member.calls || 0) +
              (member.meetings || 0)
          ),
          backgroundColor: CHART_COLORS.slice(0, filteredMembers.length),
        },
      ],
    };
  } else {
    // Activity by Type for the selected rep
    if (memberData) {
      activityChartTransformedData = {
        title: `${capitalize(memberData.name)}'s Activity by Type`,
        labels: [
          "Invites",
          "Messages Sent",
          "Messages Received",
          "Emails",
          "Calls",
          "Meetings",
        ],
        datasets: [
          {
            label: "Activity",
            data: [
              memberData.linkedinInvites || 0,
              memberData.linkedinMessagesSent || 0,
              memberData.linkedinMessagesReceived || 0,
              memberData.emails || 0,
              memberData.calls || 0,
              memberData.meetings || 0,
            ],
            backgroundColor: CHART_COLORS.slice(0, 6),
          },
        ],
      };
    } else {
      activityChartTransformedData = null;
    }
  }

  // Lead Source Chart Data Transformation
  // Process leadSourceData to create chart data per lead source and for all lead sources
  const allStagesSet = new Set();
  Object.values(dashboardData.leadSourceData).forEach((stagesArray) => {
    stagesArray.forEach((stageObj) => {
      allStagesSet.add(stageObj.stage);
    });
  });
  const allStages = Array.from(allStagesSet);

  const totalCountsPerStage = {};
  allStages.forEach((stage) => {
    totalCountsPerStage[stage] = 0;
  });
  Object.values(dashboardData.leadSourceData).forEach((stagesArray) => {
    stagesArray.forEach((stageObj) => {
      const stage = stageObj.stage;
      const count = stageObj.team.count || 0;
      totalCountsPerStage[stage] += count;
    });
  });

  const leadSourceChartData = {};

  leadSourceChartData["All Lead Sources"] = {
    title: "All Lead Sources",
    labels: allStages,
    datasets: [
      {
        label: "Total Leads",
        data: allStages.map((stage) => totalCountsPerStage[stage]),
        backgroundColor: CHART_COLORS.slice(0, allStages.length),
        borderRadius: 6,
      },
    ],
  };

  Object.entries(dashboardData.leadSourceData).forEach(
    ([leadSource, stagesArray]) => {
      const countsPerStage = {};
      allStages.forEach((stage) => {
        countsPerStage[stage] = 0;
      });
      stagesArray.forEach((stageObj) => {
        const stage = stageObj.stage;
        const count = stageObj.team.count || 0;
        countsPerStage[stage] = count;
      });

      leadSourceChartData[leadSource] = {
        title: `${capitalize(leadSource)} Overview`,
        labels: allStages,
        datasets: [
          {
            label: "Total Leads",
            data: allStages.map((stage) => countsPerStage[stage]),
            backgroundColor: CHART_COLORS.slice(0, allStages.length),
            borderRadius: 6,
          },
        ],
      };
    }
  );

  return {
    statsData,
    velocityData: velocityTransformedData,
    durationData: durationTransformedData,
    activityData: activityTransformedData,
    conversionData: conversionTransformedData,
    activityChartData: activityChartTransformedData,
    leadSourceChartData, // Included the transformed lead source data
  };
};
