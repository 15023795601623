import React, { useState, useEffect, useCallback } from "react";
import { VStack, Box, Text } from "@chakra-ui/react";
import { FormatDate, GetCurrentOrNextMeetingIndex } from "./utils";
import EventCard from "./EventCard";

const Timeline = ({ initialEvents, selectedOption }) => {
  const [events, setEvents] = useState(initialEvents);

  useEffect(() => {
    setEvents(initialEvents);
  }, [initialEvents]);

  const handleDeleteEvent = useCallback((eventId) => {
    setEvents((prevEvents) => {
      const newEvents = prevEvents.filter((event) => event.id !== eventId);
      return newEvents;
    });
    // TODO: MAKE API CALL TO DELETE EVENT
  }, []);

  return (
    <VStack spacing={4} align="stretch">
      {events.map((event, index) => {
        const isCurrentOrNext = index === GetCurrentOrNextMeetingIndex(events);
        const showDateHeader =
          index === 0 ||
          FormatDate(event.date) !== FormatDate(events[index - 1].date);

        return (
          <Box key={event.id}>
            {showDateHeader && (
              <Text fontSize="md" fontWeight="semibold" mb={2}>
                {FormatDate(event.date)}
              </Text>
            )}
            <EventCard
              key={`${event.id}-${events.length}`}
              event={event}
              isCurrentOrNext={isCurrentOrNext}
              selectedOption={selectedOption}
              onDelete={handleDeleteEvent}
            />
          </Box>
        );
      })}
    </VStack>
  );
};

export default Timeline;
