import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import { FaSortDown, FaSortUp } from "react-icons/fa"; // Import the sorting icons
import Card from "components/card/Card";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  //useGlobalFilter, EG 12/1 - no longer using
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { ModalContents } from "./CustomModal.js";
import customGlobalFilter from "./GlobalFilter.js";
import { PaginationControls } from "./PaginationControls.js";
import { useAuthData } from "auth-context";

// Debounce function used when user types a search query
const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const fetchConversation = (account_ID, flow_ID, thread_ID) => {
  const target =
    process.env.REACT_APP_NODE_ENV === "local"
      ? "https://awning-ai-awning-ai-staging.azurewebsites.net"
      : "https://awning-ai.azurewebsites.net";
  return fetch(
    `${target}/api/accounts/${account_ID}/flows/${flow_ID}/tickets/${thread_ID}`
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok: " + response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      // Assuming the response data is already JSON and doesn't need to be parsed.
      return data;
    })
    .catch((error) => {
      console.error("Error fetching the conversation:", error);
      return []; // Return an empty array in case of error
    });
};

export default function ConversationTable(props) {
  const userData = useAuthData();
  const account_ID = userData?.account_ID || "";
  const flow_ID = userData?.flow_ID || "";
  const {
    columnsData,
    tableData,
    title,
    onMessageSent,
    sentMessages,
    onConversationUpdate,
  } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const [conversationData, setConversationData] = useState([]);
  const [selectedThreadData, setThreadData] = useState({});
  const [userID, setUserID] = useState("");

  // State to manage displayed data
  const [displayData, setDisplayData] = useState(tableData);

  // State to manage search errors
  const [searchError, setSearchError] = useState(null);

  // State to manage loading for search
  const [isLoading, setIsLoading] = useState(false);

  // const data = useMemo(() => {
  //   return tableData.map((item) => {
  //     return {
  //       ...item,
  //       conversation: item.conversation
  //         ? item.conversation.filter((msg) => msg.status !== "draft")
  //         : [], // EG 10/17 in case data doesn't have conversation object
  //     };
  //   });
  // }, [tableData]);

  // const data = tableData; - EG 12/1 - no longer used

  // New state to store the search query
  const [searchQuery, setSearchQuery] = useState("");

  // Helper function to make the API call to search endpoint
  const searchConversations = async (accountID, flowID, query, statuses) => {
    const apiUrl =
      process.env.REACT_APP_NODE_ENV === "local"
        ? "https://awning-ai-awning-ai-staging.azurewebsites.net"
        : "https://awning-ai.azurewebsites.net";

    const encodedQuery = encodeURIComponent(query);
    const encodedStatuses = statuses.map(encodeURIComponent).join(","); // Join array elements into a comma-separated string

    const endpoint = `${apiUrl}/api/accounts/${accountID}/flows/${flowID}/conversations/search/?search_term=${encodedQuery}&statuses=${encodedStatuses}`;

    try {
      const response = await fetch(endpoint);
      const text = await response.text();

      if (!response.ok) {
        // Attempt to parse the error as JSON to get a detailed error message
        try {
          const errorData = JSON.parse(text);
          setSearchError(
            errorData.message || `HTTP error! status: ${response.status}`
          );
        } catch (e) {
          // If the error response isn't JSON, use a generic error message
          setSearchError(`HTTP error! status: ${response.status}`);
        }
        setDisplayData([]); // Reset the display data to an empty array
        return [];
      }

      if (!text) {
        setSearchError("No results found for the given search criteria.");
        setDisplayData([]); // Reset the display data to an empty array
        return [];
      }

      const data = JSON.parse(text); // Then try to parse the text as JSON
      return data;
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchError("An unexpected error occurred.");
      setDisplayData([]); // Reset the display data to an empty array
      return []; // Return an empty array in case of an error
    }
  };

  // Mapping function to get statuses based on title
  const getStatusFromTitle = (title) => {
    switch (title) {
      case "Inbox":
        return ["Inbox"];
      case "Outbox":
        return ["Outbox", "Archived"];
      case "Follow-Ups":
        return ["FollowUp"];
      default:
        return [title]; // Default case to handle other titles directly
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      setIsLoading(true);
      setSearchError(null); // reset any previous search errors
      try {
        const statuses = getStatusFromTitle(title);
        const searchResults = await searchConversations(
          account_ID,
          flow_ID,
          query,
          statuses
        ); // now passing in status
        const parsed_results = JSON.parse(searchResults);
        if (Array.isArray(parsed_results)) {
          setDisplayData(parsed_results);
        } else {
          console.error("Data is not an array:", parsed_results);
          setDisplayData([]);
        }
      } catch (error) {
        setSearchError(error.message); // Set error state
      } finally {
        setIsLoading(false); // Set loading to false after search is complete
      }
    }, 500),
    [account_ID, flow_ID, title]
  );

  useEffect(() => {
    let isMounted = true;

    if (searchQuery && isMounted) {
      debouncedSearch(searchQuery);
    } else if (isMounted) {
      setDisplayData(tableData); // reset table to original data
    }

    return () => {
      isMounted = false;
    };
  }, [searchQuery, debouncedSearch, tableData]);

  const handleFetchConversation = (account_ID, flow_ID, thread_ID, userID) => {
    if (!account_ID || !flow_ID || !thread_ID) return;
    fetchConversation(account_ID, flow_ID, thread_ID).then((convData) => {
      setConversationData(convData);
      setUserID(userID);
      setIsConversationOpen(true); // Open the modal after fetching data
    });
  };

  const setThreadDataFunc = (threadData) => {
    const threadDataSelected = {
      thread_ID: threadData.thread_id,
      user_ID: threadData.user_id,
      status: threadData.status,
    };
    setThreadData(threadDataSelected);
  };

  const tableInstance = useTable(
    {
      columns,
      data: displayData, // Unconditionally use tableData here
      initialState: {
        pageSize: 10,
        sortBy: [{ id: "lastMessageDate", desc: true }],
      },
      globalFilter: customGlobalFilter,
    },
    // useGlobalFilter, EG 12/1 - no longer using
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = tableInstance;

  const textColor = useColorModeValue("#56595E", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [selectedThreadId, setselectedThreadId] = useState(null);
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [hoveredColumnId, setHoveredColumnId] = useState(null); // New state to track hovered column

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex justify="space-between" mr="25px" align="center">
        <Flex ml="22px" mb="12px" justify="center" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {title || "Live Leads"}
          </Text>
        </Flex>
        <Flex>
          <Button
            onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}
            mr={isSearchBarVisible ? "10px" : "0"}
            variant="action"
          >
            <Icon w="20px" h="20px" as={MdSearch} />
          </Button>
          {isSearchBarVisible && (
            <>
              <Input
                placeholder="Search..."
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {isLoading && <Spinner ml="10px" />} {/* Loading Spinner */}
            </>
          )}
        </Flex>
      </Flex>
      {searchError && (
        <>
          <Text color="red.500" px="25px" mt="10px">
            {searchError}
          </Text>{" "}
          {/* Error Message */}
        </>
      )}
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  onMouseEnter={() => setHoveredColumnId(column.id)}
                  onMouseLeave={() => setHoveredColumnId(null)}
                  style={{ position: "relative", paddingRight: "25px" }} // Set position to relative
                >
                  <Flex
                    align="center" // Vertically center the content
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color={textColor}
                  >
                    {column.render("Header")}
                    {(column.isSorted || hoveredColumnId === column.id) && (
                      <span
                        style={{
                          position: "absolute", // Set position to absolute
                          right: "10px", // Adjust this value to position the icon within the Th
                          padding: column.isSortedDesc
                            ? "0 0 5px 0"
                            : "10px 0 0 0", // Conditional padding
                        }}
                      >
                        <Icon
                          as={column.isSortedDesc ? FaSortDown : FaSortUp}
                          boxSize="1.5em"
                          style={{
                            textAlign: "center", // Center the icon within the fixed width
                          }}
                        />
                      </span>
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.column.id === "conversation" ? (
                          <Button
                            onClick={() => {
                              handleFetchConversation(
                                account_ID,
                                flow_ID,
                                row.original.thread_id,
                                row.original.user_id
                              );
                              setselectedThreadId(row.original.thread_id); // Set the selectedThreadId here
                              setThreadDataFunc(row.original);
                            }}
                            variant="action"
                          >
                            See More
                          </Button>
                        ) : (
                          cell.render("Cell")
                        )}
                      </Text>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <PaginationControls
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
      />
      <ModalContents
        isConversationOpen={isConversationOpen}
        selectedThreadData={selectedThreadData}
        currentConversation={conversationData}
        selectedThreadId={selectedThreadId}
        tableData={tableData}
        onMessageSent={onMessageSent}
        sentMessages={sentMessages}
        account_ID={account_ID}
        flow_ID={flow_ID}
        user_ID={userID}
        onClose={() => setIsConversationOpen(false)}
        onConversationUpdate={onConversationUpdate}
      />
    </Card>
  );
}
