import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Image,
  useToast,
  Stack,
  Switch,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import { useAuth0 } from '@auth0/auth0-react';
import GmailLogo from '../../../assets/img/Gmail_Logo_512px.png';
import GoogleCalendarLogo from '../../../assets/img/GoogleCalendar_Logo.png';
import HubSpotLogo from '../../../assets/img/HubSpot_Logo.png';

const services = [
  {
    name: 'Gmail',
    identifier: 'gmail',
    logo: GmailLogo,
  },
  {
    name: 'Google Calendar',
    identifier: 'calendar',
    logo: GoogleCalendarLogo,
  },
  {
    name: 'HubSpot CRM',
    identifier: 'hubspot',
    logo: HubSpotLogo,
  },
];

export default function IntegrationsSection() {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessTokenSilently } = useAuth0();
  const [connectingService, setConnectingService] = useState(null);
  const toast = useToast();
  const target =
    process.env.REACT_APP_NODE_ENV === 'local'
      ? 'http://localhost:8080'
      : 'https://lysto-be-tf.azurewebsites.net';

  const handleAuthenticationComplete = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:calendar',
        },
      });
      const response = await axios.get(
        `${target}/api/accounts/${accountID}/users/${userID}/${connectingService}/status`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.connected) {
        toast({
          title: 'Connected successfully',
          description: `${connectingService} has been connected to your account.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        throw new Error('Connection verification failed');
      }
    } catch (err) {
      console.error('Error verifying connection:', err);
      toast({
        title: 'Connection failed',
        description: err.message,
        status: 'error',
        duration: 7000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setConnectingService(null);
    }
  };

  useEffect(() => {
    const connectAccount = async () => {
      if (!accountID || !userID || !connectingService) return;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: 'read:calendar',
          },
        });

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = 500;
        const popupHeight = 600;
        const left = screenWidth / 2 - popupWidth / 2;
        const top = screenHeight / 2 - popupHeight / 2;

        const popupWindow = window.open(
          '',
          'authWindow',
          `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
        );

        if (!popupWindow) {
          throw new Error('Popup was blocked. Please allow popups for this site.');
        }

        const response = await axios.get(
          `${target}/api/accounts/${accountID}/users/${userID}/${connectingService}/auth`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const authURL = response.data.auth_url;

        if (authURL) {
          popupWindow.location.href = authURL;
        }

        const pollTimer = setInterval(() => {
          if (popupWindow.closed) {
            clearInterval(pollTimer);
            handleAuthenticationComplete();
          }
        }, 500);
      } catch (err) {
        console.error('Error Authenticating:', err);
        toast({
          title: 'Connection failed',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setConnectingService(null);
      }
    };

    connectAccount();
  }, [accountID, userID, getAccessTokenSilently, connectingService, toast]);

  const handleConnect = (service) => {
    setConnectingService(service.identifier);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="md" width="100%">
      <Heading as="h3" size="md" mb={6} textAlign="left">
        Integrations
      </Heading>

      <VStack align="start" spacing={4} width="100%">
        {services.map((service) => (
          <Box key={service.name} w="100%" p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
            <Stack direction="row" align="center" justify="flex-start">
              <Stack direction="row" align="center" spacing={4} w="100%">
                <Image src={service.logo} alt={`${service.name} logo`} boxSize="40px" objectFit="contain" />
                <Text fontWeight="medium" fontSize="lg">
                  {service.name}
                </Text>
              </Stack>
              <Stack direction="row" align="center" spacing={4} w="100%" justify="flex-end">
                <Switch size="md" colorScheme="blue" isChecked={connectingService === service.identifier} />
                <Button
                  onClick={() => handleConnect(service)}
                  isLoading={connectingService === service.identifier}
                  colorScheme="blue"
                  size="sm"
                  variant="action"
                >
                  Connect
                </Button>
              </Stack>
            </Stack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
}