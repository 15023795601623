import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Input,
  Button,
  Text,
  Container,
  Divider,
  useDisclosure,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Send, Settings, ChevronDown } from "lucide-react";
import { FaBuilding, FaUser } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import InitialView from "./InitialView";
import AIResponse from "./AIResponse";

const ChatInterface = () => {
  const [isInitialView, setIsInitialView] = useState(true);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allSelected, setAllSelected] = useState(true);
  const [deals, setDeals] = useState({
    main: true,
    options: {
      "Lysto AI - SpaceX": true,
      "Pilot with Starfleet Academy": true,
      "Enterprise Contract": true,
    },
  });
  const [contacts, setContacts] = useState({
    main: true,
    options: {
      option1: true,
      option2: true,
      option3: true,
    },
  });
  const [conversations, setConversations] = useState({
    main: true,
    options: {
      option1: true,
      option2: true,
      option3: true,
    },
  });

  const aiResponses = [
    `Last week, 67 potential customers showed interest in our product:
  
  | Category | Value |
  | --- | --- |
  | Inbound inquiries | 42 |
  | Positive responses from outbound emails | 25 |
  
  Need any specific breakdowns on this data?`,

    `I've drafted an email to your manager with a summary of the report.
  
  **Product Interest Report Email**
  
  [Click to open document](https://example.com)
  
  Would you like to review and make any changes before I send it?`,

    `I've placed the draft email in your inbox for your review.
  
  Is there anything else you'd like me to do with this report or any other data you need?`,
  ];

  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);

  const handleDataSourceChange = (category, option, isChecked) => {
    const updateCategory = (prev) => {
      if (option === "main") {
        return {
          ...prev,
          main: isChecked,
          options: Object.fromEntries(
            Object.entries(prev.options).map(([key]) => [key, isChecked])
          ),
        };
      } else {
        const newOptions = { ...prev.options, [option]: isChecked };
        const allSubOptionsChecked = Object.values(newOptions).every(Boolean);
        return { ...prev, options: newOptions, main: allSubOptionsChecked };
      }
    };

    switch (category) {
      case "deals":
        setDeals(updateCategory);
        break;
      case "contacts":
        setContacts(updateCategory);
        break;
      case "conversations":
        setConversations(updateCategory);
        break;
    }
    updateAllSelected();
  };

  const handleSendMessage = (message) => {
    setMessages([...messages, { text: message, sender: "user" }]);
    setIsInitialView(false);
    setTimeout(() => {
      if (currentResponseIndex < aiResponses.length) {
        setMessages((prev) => [
          ...prev,
          { content: aiResponses[currentResponseIndex], sender: "ai" },
        ]);
        setCurrentResponseIndex(currentResponseIndex + 1);
      }
    }, 1000);
  };

  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    setDeals((prev) => ({
      main: isChecked,
      options: Object.fromEntries(
        Object.keys(prev.options).map((key) => [key, isChecked])
      ),
    }));
    setContacts((prev) => ({
      main: isChecked,
      options: Object.fromEntries(
        Object.keys(prev.options).map((key) => [key, isChecked])
      ),
    }));
    setConversations((prev) => ({
      main: isChecked,
      options: Object.fromEntries(
        Object.keys(prev.options).map((key) => [key, isChecked])
      ),
    }));
  };

  const updateAllSelected = () => {
    const allChecked =
      deals.main &&
      contacts.main &&
      conversations.main &&
      Object.values(deals.options).every(Boolean) &&
      Object.values(contacts.options).every(Boolean) &&
      Object.values(conversations.options).every(Boolean);
    setAllSelected(allChecked);
  };

  const renderMenu = (category, state, icon) => (
    <HStack width="100%" justifyContent="space-between">
      <Checkbox
        isChecked={state.main}
        onChange={(e) =>
          handleDataSourceChange(category, "main", e.target.checked)
        }
      >
        <HStack spacing={2}>
          {icon}
          <Text>{category.charAt(0).toUpperCase() + category.slice(1)}</Text>
        </HStack>
      </Checkbox>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDown />} size="sm" />
        <MenuList>
          {Object.entries(state.options).map(([option, isChecked]) => (
            <MenuItem key={option}>
              <Checkbox
                isChecked={isChecked}
                onChange={(e) =>
                  handleDataSourceChange(category, option, e.target.checked)
                }
              >
                {option}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );

  return (
    <Container maxWidth="container.lg" height="100vh" py={4} pt={20}>
      {isInitialView ? (
        <InitialView
          allSelected={allSelected}
          onSelectAll={handleSelectAll}
          deals={{
            main: deals.main,
            options: deals.options,
            onChange: (o, v) => handleDataSourceChange("deals", o, v),
          }}
          contacts={{
            main: contacts.main,
            options: contacts.options,
            onChange: (o, v) => handleDataSourceChange("contacts", o, v),
          }}
          conversations={{
            main: conversations.main,
            options: conversations.options,
            onChange: (o, v) => handleDataSourceChange("conversations", o, v),
          }}
          onSendMessage={handleSendMessage}
        />
      ) : (
        <VStack spacing={4} height="100%" justify="space-between">
          <Box overflowY="auto" w="100%" flex={1}>
            {messages.map((message, index) => (
              <HStack
                key={index}
                justify={message.sender === "user" ? "flex-end" : "flex-start"}
                my={4} // Changed from my={2} to my={4} for more vertical spacing
                w="100%"
              >
                {message.sender === "user" ? (
                  <Box
                    bg="gray.200"
                    p={2}
                    borderRadius="md"
                    borderColor="gray.500"
                    maxW="80%"
                  >
                    <Text>{message.text}</Text>
                  </Box>
                ) : (
                  <AIResponse markdownText={message.content} />
                )}
              </HStack>
            ))}
          </Box>
          <Divider />
          <Box
            as="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage(inputMessage);
              setInputMessage("");
            }}
            w="100%"
          >
            <HStack spacing={2}>
              <Input
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type your message here..."
                size="md"
                flex={1}
              />
              <Button type="submit" colorScheme="gray" size="sm">
                <Send size={18} />
              </Button>
              <Button onClick={onOpen} colorScheme="gray" size="sm">
                <Settings size={18} />
              </Button>
            </HStack>
          </Box>
        </VStack>
      )}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Data Controls</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Checkbox
                isChecked={allSelected}
                onChange={(e) => handleSelectAll(e.target.checked)}
              >
                Select All
              </Checkbox>
              {renderMenu("deals", deals, <FaBuilding />)}
              {renderMenu("contacts", contacts, <FaUser />)}
              {renderMenu("conversations", conversations, <MdEvent />)}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  );
};

export default ChatInterface;
