import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Button,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Ensure you have this line to import the styles

export function AnnotationModal({
  isOpen,
  onClose,
  user_ID,
  account_ID,
  flow_ID,
  thread_ID,
  onNoteSubmitted,
}) {
  const [noteType, setNoteType] = useState("memo");
  const [noteBody, setNoteBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");

  function getUTCDate() {
    const now = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = now.getUTCDate();
    const monthIndex = now.getUTCMonth();
    const year = now.getUTCFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }

  function getUTCTime() {
    const now = new Date();
    let hours = now.getUTCHours();
    let minutes = now.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  }

  const handleSubmission = () => {
    console.log(noteBody);
    if (noteBody.trim() !== "") {
      setIsLoading(true); // Set loading status when the button is clicked
      const env = process.env.REACT_APP_NODE_ENV;
      var target = "https://awning-ai.azurewebsites.net";
      if (env === "local") {
        target = "https://awning-ai-awning-ai-staging.azurewebsites.net";
      }
      const endpoint = `/api/accounts/${account_ID}/flows/${flow_ID}/users/${user_ID}/threads/${thread_ID}/notes/`;

      const requestBody = {
        note_type: noteType,
        note_body: noteBody,
        user_ID: user_ID,
        account_ID: account_ID,
        flow_ID: flow_ID,
      };

      console.log("Sending note content:", requestBody);

      fetch(target + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          setIsLoading(false); // Reset loading status when a response is received
          if (!response.ok) {
            throw new Error(
              "Network response was not ok: " + response.statusText
            );
          }
          onClose(); // Close the modal if the response is ok
          return response.json();
        })
        .then((data) => {
          // Construct the new message object based on the response data
          const newMessage = {
            note_type:
              data.note_type.charAt(0).toUpperCase() + data.note_type.slice(1),
            note_body: data.note_body,
            user_ID: user_ID,
            account_ID: account_ID,
            flow_ID: flow_ID,
            date_created: getUTCDate(), // Use formatted date
            time_created: getUTCTime(), // Use formatted time

            // name: data.note_type.charAt(0).toUpperCase() + data.note_type.slice(1),
            // message_content: data.note_body,
            // status: "sent",
            // identity: "customer",
          };
          // Call the onNoteSubmitted prop to update the sentMessages state
          onNoteSubmitted(thread_ID, newMessage);
        })
        .catch((error) => {
          console.error("Error sending the note:", error);
          setButtonText("Try Again"); // Set button text to "Try Again" on error
          setTimeout(() => setButtonText("Submit"), 3000); // Reset button text after 3 seconds
        });
    }
  };

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Annotate this conversation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            value={noteType}
            onChange={(e) => setNoteType(e.target.value)}
          >
            <option value="memo">Add a Note</option>
            <option value="called">Mark as Called</option>
            <option value="booked">Mark as Booked</option>
            {/* <option value="snooze">Mark as Snoozed</option> */}
          </Select>
          <ReactQuill
            value={noteBody}
            onChange={setNoteBody}
            modules={modules}
            sx={{
              ".ql-container": {
                borderRadius: "lg",
              },
            }}
            style={{
              flex: "1",
              height: "20vh",
              display: "flex",
              flexDirection: "column",
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              borderRadius: "md",
              borderColor: "red",
            }}
            editorStyle={{
              height: "76vh",
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSubmission}
            isLoading={isLoading}
            loadingText="Loading"
            colorScheme="teal"
            variant="action"
            spinnerPlacement="end"
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
