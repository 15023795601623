import { FaMapMarkerAlt, FaVideo } from "react-icons/fa";

const eventData = [
  {
    id: "E001",
    event_overview: { icon: FaVideo, text: "Online Meeting" },
    title: "30min call meeting Peer <> Leslie",
    description: "Discussion about project updates.",
    status: "Upcoming",
    more: "View Details",
    time: "09:00 - 09:30",
    date: new Date(2024, 7, 28), // JavaScript dates are zero-indexed on the month
    attendees: ["Peer Grusen", "Leslie Knope"],
  },
  {
    id: "E002",
    event_overview: { icon: FaMapMarkerAlt, text: "In-Person Meeting" },
    title: "Livn Product Demo",
    description: "Presentation of new Livn product features.",
    status: "Upcoming",
    more: "View Details",
    time: "15:20 - 16:20",
    date: new Date(2024, 7, 30),
    location: "Wework Paris",
    attendees: ["Olivia Santos", "Ethan Hunt"],
  },
  {
    id: "E003",
    event_overview: { icon: FaVideo, text: "Online Meeting" },
    title: "30min call meeting Olivia, Liam <> Alban",
    description: "Weekly team sync-up.",
    status: "Upcoming",
    more: "View Details",
    time: "11:15 - 11:45",
    date: new Date(2024, 7, 29),
    attendees: ["Olivia Pope", "Liam Neeson", "Alban Brooks"],
  },
  {
    id: "E004",
    event_overview: { icon: FaVideo, text: "Online Meeting" },
    title: "30min call meeting Yulia, Alvin <> Irina, Mae",
    description: "Project progress review.",
    status: "Requested Reschedule",
    more: "View Details",
    time: "11:15 - 11:45",
    date: new Date(2024, 8, 2), // Next month, since JavaScript month is zero-indexed
    attendees: ["Yulia Tymoshenko", "Alvin York", "Irina Shayk", "Mae Jemison"],
  },
  {
    id: "E005",
    event_overview: { icon: FaVideo, text: "Online Meeting" },
    title: "Product meeting review",
    description: "Quarterly product strategy review.",
    status: "Upcoming",
    more: "View Details",
    time: "17:30 - 18:00",
    date: new Date(2024, 8, 4),
    attendees: ["John Doe", "Jane Doe"],
  },
];

// const meetingAnalysisData2 = {
//   title: "Zabio.ai - Initial Discovery Call (Series B, CRM Inefficiency)",
//   meeting_url: "https://meet.google.com/ijq-cqdh-pfh",
//   context:
//     "After connecting on LinkedIn, Steven expressed interest in our product. We scheduled a fit call via LinkedIn and confirmed the meeting through his work email, steven@zabio.ai.",
//   audio_recording_url:
//     "https://lystobe.blob.core.windows.net/audiofiles/recording_clip_30000_75000.webm",
//   meeting_outcome:
//     "The meeting discussed the development and potential partnership for a high-resolution call recording product. They explored the market opportunity and identified the need for pilot projects to gather feedback and improve the product before raising a seed round.",
//   metrics:
//     "Deals moving faster through the pipeline with a 20% increase in implementation time.",
//   economic_buyer: "Kunal Puri (potential customer CEO)",
//   decision_process:
//     "Understanding the product through pilots and testing, evaluating its fit against needs before making a decision.",
//   decision_criteria:
//     "Ease of integration into existing processes, Ability to capture and analyze voice data effectively, Market competitiveness against existing solutions like Salesforce",
//   implied_pain:
//     "Current solutions like Salesforce are frustrating and lack the desired user experience. Need for a more effective CRM solution that can replace legacy systems.",
//   champion: "Steven Nass",
//   competitors: "Gong",
//   tools_and_technologies: "Asana, Gong",
// };

const meetingAnalysisData = {
  meeting_outcome:
    "The main conclusions of the meeting were to address the issues with recording sales meetings and how to handle personal and sensitive information in recordings. The sales tool should integrate well with Google Suite, Slack, and Jira, and security and data privacy are critical. Next steps include providing a list of tasks that take up the most time for automation, integrating the necessary tools, and securing InfoSec approval.\n",

  next_steps:
    "- Customer to brainstorm and provide a list of top five time-consuming tasks.\n- Sales team to share the current slides used in the presentation.\n- Initiate an InfoSec review to ensure security and privacy standards are met.\n- Integrate with Google Suite, Slack, and Jira as mentioned.\n- Schedule follow-up meeting after Labor Day to discuss progress.\n",

  customer_needs:
    "- Sales team spending a third of their time on administrative tasks.\n- Difficulty in maintaining CRM updates.\n- Concerns about recording personal information during sales calls.",
};

const slackUpdate = {
  meeting_title: "Initial Discovery Call (Series B, CRM Inefficiency)",
  date: "2024-08-13",
  summary:
    "Discussed potential partnership for high-resolution call recording product. Explored market opportunity and identified need for pilot projects.",
  action_items: `- Set up pilot projects
- Gather feedback for product improvement`,
};

const emailData = {
  title: "New inquiry about enterprise package",
  sender: "Acme Corporation",
  date: "2024-08-12",
  body: `Hi there,
  We're interested in your enterprise package. Could you provide more details on the features and pricing?`,
};

export default [eventData, meetingAnalysisData, slackUpdate, emailData];
