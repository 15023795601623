import React, { useState } from 'react';
import { 
  Box, 
  VStack, 
  HStack, 
  Text, 
  Icon, 
  IconButton, 
  Collapse, 
  Progress, 
  Avatar, 
  Badge 
} from '@chakra-ui/react';
import { 
  MdEmail, 
  MdInsertDriveFile, 
  MdMessage, 
  MdAssignment, 
  MdEvent, 
  MdPerson, 
  MdPlayArrow, 
  MdPause, 
  MdAttachment 
} from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlack } from '@fortawesome/free-brands-svg-icons';

const getIcon = (type) => {
  switch (type) {
    case 'meeting': return MdEvent;
    case 'email': return MdEmail;
    case 'message': return MdMessage;
    case 'task': return MdAssignment;
    case 'file': return MdInsertDriveFile;
    case 'contact': return MdPerson;
    case 'slack': {
      const SlackIcon = () => (
        <Icon as={FontAwesomeIcon} icon={faSlack} boxSize={5} color="blue.500" /> // Use Chakra's boxSize and color
      );
      SlackIcon.displayName = "SlackIcon";
      return SlackIcon;
    }
    default: return MdEvent;
  }
};

const getActivityDescription = (activity) => {
  switch (activity.type) {
    case 'meeting':
      return (
        <Text>
          <Text as="b">{activity.users[0]}</Text> {activity.action} <Text as="b">{activity.subject}</Text>
          {activity.details.location && ` at ${activity.details.location}`}
          {activity.details.duration && ` for ${activity.details.duration}`}
        </Text>
      );
    case 'email':
      return (
        <Text>
          <Text as="b">{activity.users[0]}</Text> {activity.action === 'sent' ? 'sent an email to' : 'received an email from'} <Text as="b">{activity.users[1]}</Text>
        </Text>
      );
    case 'message':
      return (
        <Text>
          <Text as="b">{activity.users[0]}</Text> sent a message to the <Text as="b">{activity.channel}</Text> channel on <Text as="b">{activity.platform}</Text>
        </Text>
      );
    case 'slack': // New case for Slack
      return (
        <Text>
          <Text as="b">{activity.users[0]}</Text> sent a message to the <Text as="b">{activity.channel}</Text> channel on Slack
        </Text>
      );
    case 'task':
      return (
        <Text>
          <Text as="b">{activity.users[0]}</Text> {activity.action} a task <Text as="b">{activity.subject}</Text>
        </Text>
      );
    case 'file':
      return (
        <Text>
          <Text as="b">{activity.users[0]}</Text> {activity.action} a file <Text as="b">{activity.filename}</Text>
        </Text>
      );
    default:
      return 'Unknown activity';
  }
};

const CalendarMiniCard = ({ activity }) => {
  const date = new Date(activity.datetime);
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <Box p={3} bg="white" borderRadius="md" boxShadow="sm" width="100%">
      <HStack spacing={4} alignItems="flex-start">
        <VStack alignItems="center" bg="blue.500" color="white" p={2} borderRadius="md" minWidth="60px">
          <Text fontSize="sm" fontWeight="bold">{date.toLocaleString('default', { month: 'short' })}</Text>
          <Text fontSize="2xl" fontWeight="bold">{date.getDate()}</Text>
        </VStack>
        <VStack alignItems="flex-start" spacing={1}>
          <Text fontWeight="bold" fontSize="md">{activity.subject}</Text>
          <Text fontSize="sm">{formattedTime} • {activity.details.duration}</Text>
          <Text fontSize="sm">{activity.details.location}</Text>
          {activity.details.notes && <Text fontSize="sm" color="gray.600">{activity.details.notes}</Text>}
        </VStack>
      </HStack>
    </Box>
  );
};

const AudioPlayback = ({ filename }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      // Simulate progress
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            setIsPlaying(false);
            return 0;
          }
          return prevProgress + 1;
        });
      }, 100);
    }
  };

  return (
    <Box p={3} bg="white" borderRadius="md" boxShadow="sm" width="100%">
      <HStack spacing={4} alignItems="center">
        <IconButton
          icon={isPlaying ? <MdPause /> : <MdPlayArrow />}
          onClick={togglePlay}
          aria-label={isPlaying ? "Pause" : "Play"}
          colorScheme="blue"
          size="sm"
        />
        <VStack alignItems="flex-start" flex={1} spacing={1}>
          <Text fontWeight="bold" fontSize="sm">{filename}</Text>
          <Progress value={progress} size="xs" width="100%" colorScheme="blue" />
        </VStack>
      </HStack>
    </Box>
  );
};

const EmailCard = ({ activity }) => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="md" maxWidth="100%" width="100%" border="1px solid" borderColor="gray.200">
    <VStack align="stretch" spacing={3}>
      <HStack justify="space-between">
        <Text fontSize="sm" color="gray.500">From: <Text as="span" fontWeight="bold" color="black">{activity.users[0]}</Text></Text>
        <Text fontSize="xs" color="gray.500">{new Date(activity.datetime).toLocaleString()}</Text>
      </HStack>
      <Text fontSize="sm" color="gray.500">To: <Text as="span" fontWeight="bold" color="black">{activity.users[1]}</Text></Text>
      <Text fontSize="md" fontWeight="bold">{activity.subject}</Text>
      <Box bg="gray.50" p={3} borderRadius="md">
        <Text fontSize="sm" noOfLines={3}>{activity.details.bodyPreview}</Text>
      </Box>
      {activity.details.attachments && (
        <HStack>
          <Icon as={MdAttachment} color="gray.500" />
          <Text fontSize="sm" color="gray.600">{activity.details.attachments.join(', ')}</Text>
        </HStack>
      )}
    </VStack>
  </Box>
);

const SlackMessageCard = ({ activity }) => (
  <Box p={4} bg="#F8F8F8" borderRadius="md" boxShadow="md" width="100%" border="1px solid" borderColor="gray.200">
    <HStack spacing={4} alignItems="flex-start">
      <Avatar bg="#4A154B" icon={<FontAwesomeIcon icon={faSlack} color="white" />} />
      <VStack alignItems="flex-start" spacing={1} flex={1}>
        <HStack justifyContent="space-between" width="100%">
          <Text fontWeight="bold" fontSize="md" color="#4A154B">{activity.channel}</Text>
          <Text fontSize="xs" color="gray.500">{new Date(activity.datetime).toLocaleTimeString()}</Text>
        </HStack>
        <Text fontSize="sm">{activity.details.messageText}</Text>
      </VStack>
    </HStack>
  </Box>
);

const TaskCard = ({ activity }) => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="md" width="100%" border="1px solid" borderColor="gray.200">
    <VStack align="stretch" spacing={3}>
      <HStack justifyContent="space-between">
        <Text fontSize="lg" fontWeight="bold">{activity.subject}</Text>
        <Badge colorScheme={activity.action === 'completed' ? 'green' : 'blue'}>
          {activity.action === 'completed' ? 'Completed' : 'In Progress'}
        </Badge>
      </HStack>
      {activity.details.priority && (
        <Badge colorScheme={activity.details.priority === 'High' ? 'red' : 'yellow'} alignSelf="flex-start">
          {activity.details.priority} Priority
        </Badge>
      )}
      {activity.details.deadline && (
        <Text fontSize="sm" color="gray.600">
          Deadline: {activity.details.deadline}
        </Text>
      )}
      {activity.details.completionDate && (
        <Text fontSize="sm" color="green.500">
          Completed: {activity.details.completionDate}
        </Text>
      )}
      {activity.action !== 'completed' && (
        <Progress value={activity.action === 'created' ? 0 : 50} size="sm" colorScheme="blue" />
      )}
      {activity.details.notes && (
        <Box bg="gray.50" p={2} borderRadius="md">
          <Text fontSize="sm">{activity.details.notes}</Text>
        </Box>
      )}
    </VStack>
  </Box>
);

const FileCard = ({ activity }) => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="md" width="100%" border="1px solid" borderColor="gray.200">
    <HStack spacing={4} alignItems="center">
      <Icon as={MdInsertDriveFile} boxSize={10} color="blue.500" />
      <VStack alignItems="flex-start" spacing={1} flex={1}>
        <Text fontWeight="bold" fontSize="md">{activity.filename}</Text>
        <Text fontSize="sm" color="gray.600">Size: {activity.details.fileSize}</Text>
        <Text fontSize="xs" color="gray.500">Uploaded by {activity.users[0]} on {new Date(activity.datetime).toLocaleDateString()}</Text>
      </VStack>
    </HStack>
  </Box>
);

const ActivityItem = ({ activity }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const renderExpandedContent = () => {
    switch (activity.type) {
      case 'meeting':
        return activity.action === 'uploaded' ? (
          <AudioPlayback filename={activity.filename} />
        ) : (
          <CalendarMiniCard activity={activity} />
        );
      case 'email':
        return <EmailCard activity={activity} />;
      case 'message':
        return <SlackMessageCard activity={activity} />;
      case 'slack':
        return <SlackMessageCard activity={activity} />;
      case 'task':
        return <TaskCard activity={activity} />;
      case 'file':
        return activity.filename.includes('Recording') ? (
          <AudioPlayback filename={activity.filename} />
        ) : (
          <FileCard activity={activity} />
        );
      default:
        return null;
    }
  };

  return (
    <Box width="100%">
      <HStack
        width="100%"
        spacing={4}
        p={2}
        bg="white"
        boxShadow="sm"
        justifyContent="space-between"
        alignItems="center"
        fontSize="sm"
        borderRadius="md"
      >
        <Icon as={getIcon(activity.type)} color="blue.500" boxSize={5} />
        <VStack align="start" flex="1">
          {getActivityDescription(activity)}
          <Text fontSize="xs" color="gray.500">{new Date(activity.datetime).toLocaleString()}</Text>
        </VStack>
        <IconButton
          icon={isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
          aria-label="Toggle details"
          variant="outline"
          borderRadius="full"
          size="sm"
          border="1px solid"
          borderColor="gray.300"
          onClick={toggleExpand}
        />
      </HStack>
      <Collapse in={isExpanded} animateOpacity>
        <Box mt={2}>
          {renderExpandedContent()}
        </Box>
      </Collapse>
    </Box>
  );
};

const ActivityTimeline = ({ activities }) => {
  return (
    <VStack spacing={4}>
      {activities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}
    </VStack>
  );
};

export default ActivityTimeline;