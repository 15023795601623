import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import ProfileComponent from "views/lysto/Components/Profile";
import { NavLink, useLocation } from "react-router-dom";
import { MdSettings } from "react-icons/md";

const filterVisibleRoutes = (routes) => {
  return routes.filter((route) => route.hidden === undefined || !route.hidden);
};

function Sidebar(props) {
  const { routes } = props;
  const location = useLocation();
  const textColor = useColorModeValue("#56595E", "white");
  const activeColor = useColorModeValue("blue.500", "blue.200");

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  // Filter routes for the scrollable area, excluding Settings
  const scrollableRoutes = filterVisibleRoutes(routes).filter(
    (route) => route.name !== "Settings"
  );

  // Find the Settings route
  const settingsRoute = routes.find((route) => route.name === "Settings");

  // Check if the current route is Settings
  const isSettingsActive =
    settingsRoute &&
    location.pathname.startsWith(settingsRoute.layout + settingsRoute.path);

  return (
    <Box
      display={{ sm: "none", xl: "block" }}
      w="100%"
      position="fixed"
      minH="100%"
    >
      <Flex
        bg={sidebarBg}
        transition={variantChange}
        w="200px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        boxShadow={shadow}
        flexDirection="column"
      >
        <Box flexGrow={1} overflowX="hidden" overflowY="auto">
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={renderView}
          >
            <Content routes={scrollableRoutes} />
          </Scrollbars>
        </Box>
        {settingsRoute && (
          <NavLink
            to={settingsRoute.layout + settingsRoute.path}
            style={{ textDecoration: "none" }}
          >
            <Flex
              align="center"
              p="12px"
              mx="12px"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              color={isSettingsActive ? activeColor : textColor}
            >
              <Box mr="20px">
                <MdSettings size={22} />
              </Box>
              <Box>{settingsRoute.name}</Box>
            </Flex>
          </NavLink>
        )}
        <ProfileComponent />
      </Flex>
    </Box>
  );
}

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
