import { Box, SimpleGrid, Icon, Text, Tag, Flex } from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa"; 
import ConversationTable from "views/admin/dataTables/components/ConversationTable";
import React, { useState, useCallback, useEffect } from "react";
import HoverBox from "views/lysto/Shared/HoverBox";
import { MdBusiness } from 'react-icons/md';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import dealData from "./dealData";

export default function Deals() {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [deals, setDeals] = useState([]);

  const formatStage = (stage) => {
    const stageMap = {
      "Lead": "Lead",
      "MQL": "MQL",
      "SQL": "SQL",
      "Contract": "Contract",
      "Closed": "Closed",
    };
    return stageMap[stage] || stage.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!accountID) return;
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";

        const dataResponse = await axios.get(
          `${target}/api/accounts/${accountID}/opportunities`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (dataResponse.data){
          setDeals(dataResponse.data);
        }
      } catch (err) {
        setDeals(dealData); // Fallback to local deal data if API call fails
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [accountID, getAccessTokenSilently]);

  const handleViewDetails = useCallback((deal) => {
    console.log("handleViewDetails called with deal:", deal);
    history.push(`/admin/deals/${deal.id}`);
  }, [history]);

  // Updated columnsDeal to show the requested fields
  const columnsDeal = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, row }) => (
        <HoverBox 
          icon={MdBusiness} 
          hoverIcon={faExpandAlt} 
          onClick={() => handleViewDetails(row.original)}
        >
          <Text fontWeight="bold">{value}</Text>
        </HoverBox>
      ),
    },
    {
      Header: "Account",
      accessor: "properties.crm_account_name",
      Cell: ({ value }) => <Text>{value}</Text>,
    },
    {
      Header: "Champion",
      accessor: "champion",
      Cell: ({ value }) => <Text>{value}</Text>,
    },
    {
      Header: "Last Activity",
      accessor: "properties.last_activity_date",
      Cell: ({ value }) => {
        const date = new Date(value);
        return <Text>{date.toLocaleDateString()}</Text>;
      },
    },
    {
      Header: "Stage",
      accessor: "properties.dealstage",
      Cell: ({ value }) => {
        const stageColors = {
          "Lead": "blue",
          "MQL": "purple",
          "SQL": "orange",
          "Contract": "green",
          "Closed": "teal",
        };
        return (
          <Tag size="lg" borderRadius="lg" mr={1} px={3} py={1} bg="white" borderWidth="px" borderColor="gray.300">
            <Flex alignItems="center">
              <Box height="10px" width="10px" borderRadius="full" bg={`${stageColors[value]}.500`} mr={2}></Box>
              <Text fontSize="sm" color="black">{formatStage(value)}</Text>
            </Flex>
          </Tag>
        );
      },
    },
    {
      Header: "Next Meeting",
      accessor: "next_meeting",
      Cell: ({ value }) => (
        <Box display="flex" alignItems="center">
          <Icon as={FaCalendarAlt} mr={2} color="gray.500" />
          <Text>{value}</Text>
        </Box>
      ),
    },
  ];

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <ConversationTable
          columnsData={columnsDeal}
          tableData={deals}
          title="Deals"
        />
      </SimpleGrid>
    </Box>
  );
}
