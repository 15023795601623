import React, { useState, useEffect } from "react";
import {
  VStack,
  Box,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { Search, Edit } from "lucide-react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const TranscriptTabContent = ({ accountID, meetingID }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [transcriptData, setTranscriptData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchTranscriptData = async () => {
      if (!accountID || !meetingID) return;
      try {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";
        const response = await axios.get(
          `${target}/api/accounts/${accountID}/events/${meetingID}/transcript`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setTranscriptData(response.data.eventTranscript);
      } catch (err) {
        console.error("Error fetching transcript data:", err);
        setError("Failed to fetch transcript data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTranscriptData();
  }, [accountID, meetingID, getAccessTokenSilently]);

  const filteredTranscript = transcriptData.filter(
    (entry) =>
      entry.transcript?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      entry.speaker?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack align="stretch" spacing={4}>
      <Flex>
        <InputGroup flex={1}>
          <InputLeftElement pointerEvents="none">
            <Search color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search transcript"
            bg="white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        <IconButton icon={<Edit />} aria-label="Edit transcript" ml={2} />
      </Flex>
      <Box>
        {transcriptData.length === 0 ? (
          <Text>No transcript data available.</Text>
        ) : (
          <VStack align="stretch" spacing={2}>
            {filteredTranscript.map((entry, index) => (
              <Box key={index} p={2} bg="white" borderRadius="md">
                <Text fontWeight="bold" color="blue.500">
                  {entry.speaker !== null && entry.speaker !== undefined
                    ? entry.speaker
                    : "Unknown Speaker"}
                </Text>
                <Text>{entry.transcript || "No transcript available"}</Text>
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    </VStack>
  );
};

export default TranscriptTabContent;
