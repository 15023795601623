// src/components/charts/BarChart.js

import React from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CHART_COLORS } from "../constants";

// Define RoundedBar Shape
const RoundedBar = (props) => {
  const { x, y, width, height, fill } = props;
  const radius = 4; // Adjust the radius as needed

  return (
    <path
      d={`
        M ${x},${y + height}
        L ${x},${y + radius}
        Q ${x},${y} ${x + radius},${y}
        L ${x + width - radius},${y}
        Q ${x + width},${y} ${x + width},${y + radius}
        L ${x + width},${y + height}
        Z
      `}
      fill={fill}
    />
  );
};

// Define RegularBar Shape
const RegularBar = (props) => {
  const { x, y, width, height, fill } = props;
  return <rect x={x} y={y} width={width} height={height} fill={fill} />;
};

/**
 * Custom Tooltip Component
 * Enhances the default tooltip to display all datasets' information.
 */
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#f7fafc",
          border: "1px solid #e2e8f0",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>{label}</p>
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "2px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                marginRight: "5px",
                borderRadius: "50%",
              }}
            ></div>
            <span style={{ color: "#2d3748", fontSize: "12px" }}>
              {entry.name}: {entry.value}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const BarChart = ({ barData, colors }) => {
  // Validate barData structure
  if (!barData || !barData.labels || !barData.datasets) {
    console.error("Invalid barData structure", barData);
    return <div>No data available</div>;
  }

  // Ensure colors array is valid and has enough colors
  // const defaultColors = [
  //   "#1A5BF6", // Emails
  //   "#867cf4", // Calls
  //   "#5d94f7", // Meetings
  //   "#5dc5c7", // Messages Sent
  //   "#8ae9e9", // Messages Received
  //   "#f6ad55", // Invites
  // ];
  const safeColors =
    Array.isArray(colors) && colors.length >= barData.datasets.length
      ? colors
      : CHART_COLORS;

  // Transform barData into Recharts-compatible data
  const chartData = barData.labels.map((label, index) => ({
    name: label,
    ...barData.datasets.reduce((acc, dataset) => {
      if (dataset && dataset.label && Array.isArray(dataset.data)) {
        acc[dataset.label] = dataset.data[index] || 0;
      }
      return acc;
    }, {}),
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsBarChart data={chartData} barGap={1}>
        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
        <XAxis
          dataKey="name"
          tick={{ fill: "#718096", fontSize: 12 }} // Set font size smaller here
        />
        <YAxis tick={{ fill: "#718096", fontSize: 12 }} />
        <Tooltip content={<CustomTooltip />} />
        {barData.datasets.map((dataset, index) =>
          dataset && dataset.label ? (
            <Bar
              key={dataset.label}
              dataKey={dataset.label}
              stackId="a"
              fill={safeColors[index % safeColors.length]}
              shape={
                index === barData.datasets.length - 1 ? (
                  <RoundedBar />
                ) : (
                  <RegularBar />
                )
              }
            />
          ) : null
        )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
