import React, { useState } from 'react';
import { Box, HStack, VStack, Text, Tag, Icon, Collapse, IconButton, Flex } from '@chakra-ui/react';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import StageDetails from './StageDetails';
import dealStageData from './dealStageData.js';

const DealStages = () => {
  const { stages, currentStageIndex } = dealStageData[0].deal;

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleTagClick = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const getStageStatusIcon = (index) => {
    if (index < currentStageIndex) {
      return <Icon as={FaCheckCircle} color="blue.500" />;
    } else if (index === currentStageIndex) {
      return <Icon as={FaCircle} color="blue.500" />;
    } else {
      return <Icon as={FaCircle} color="gray.500" />;
    }
  };

  return (
    <Box>
      <Flex justifyContent="space-between" mb={2}>
        <Text fontSize="md" fontWeight="semibold">Deal Stages</Text>
        <IconButton
          icon={expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
          aria-label="Toggle details"
          variant="outline"
          borderRadius="full"
          size="sm"
          border="1px solid"
          borderColor="gray.300"
          onClick={toggleExpand}
        />
      </Flex>

      <HStack spacing={4} wrap="wrap">
        {stages
          .filter(stage => stage.questions && stage.questions.length > 0)
          .map((stage, index) => (
            <Tag
              key={index}
              size="lg"
              borderRadius="lg"
              px={3}
              py={1}
              bg="white"
              borderWidth="1px"
              borderColor="gray.300"
              display="flex"
              alignItems="center"
              onClick={handleTagClick}
              cursor="pointer"
            >
              <Flex alignItems="center" mr={2}>
                {getStageStatusIcon(index)}
              </Flex>
              <Text fontSize="sm" color="black">
                {stage.name}
              </Text>
            </Tag>
          ))}
      </HStack>

      <Collapse in={expanded} animateOpacity>
        <VStack spacing={4} align="stretch" mt={4}>
          {stages
            .filter(stage => stage.questions && stage.questions.length > 0)
            .map((stage, index) => (
              <StageDetails
                key={index}
                stage={stage}
                stageIndex={index}
                currentStageIndex={currentStageIndex}
              />
            ))}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default DealStages;