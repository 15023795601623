import React from "react";
import { Box, Text, Icon, Flex, Tooltip } from "@chakra-ui/react";
import { MdBusiness } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";

// HoverBox Component
const HoverBox = ({ children, onClick, icon, iconProps }) => {
  const isSlackOrBuilding = icon === FontAwesomeIcon || icon === MdBusiness;

  return (
    <Tooltip label="Click to expand">
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        // Removed padding to eliminate border-like appearance
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        onClick={onClick}
        _hover={{
          "& > *": { color: "#1A5BF6" },
        }}
      >
        <Box
          as="span"
          mr={2}
          position="relative"
          fontSize="20px"
          width="20px"
          height="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            transition="opacity 0.2s"
            opacity={1}
            _groupHover={{ opacity: 0 }}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            pt={isSlackOrBuilding ? "0px" : "1px"}
          >
            {icon === FontAwesomeIcon ? (
              <FontAwesomeIcon
                icon={iconProps.icon}
                color="#748094"
                size="lg"
              />
            ) : (
              <Icon as={icon} color="#748094" boxSize="20px" />
            )}
          </Box>
          <Box
            as={FontAwesomeIcon}
            icon={faExpandAlt}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            fontSize="20px"
            width="20px"
            height="20px"
            opacity={0}
            transition="opacity 0.2s"
            _groupHover={{ opacity: 1, color: "#1A5BF6" }}
            color="#748094"
          />
        </Box>
        {children}
      </Box>
    </Tooltip>
  );
};

const formatDate = (dateString) => {
  const today = new Date();
  const date = new Date(dateString);
  const diffTime = date.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) return "Today";
  if (diffDays === 1) return "Tomorrow";
  if (diffDays > 1 && diffDays <= 7)
    return `Next ${date.toLocaleDateString("en-US", { weekday: "long" })}`;
  if (diffDays < 0 && diffDays >= -7)
    return `Last ${date.toLocaleDateString("en-US", { weekday: "long" })}`;
  return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
};

const columnsCases = (onOpen, setSelectedCase) => [
  {
    Header: "Task",
    accessor: "task_overview",
    Cell: ({ value, row }) => (
      <HoverBox
        onClick={() => {
          setSelectedCase(row.original);
          onOpen();
        }}
        icon={value.icon}
        iconProps={row.original.iconProps}
      >
        <Text fontSize="sm" fontWeight="700" _groupHover={{ color: "#1A5BF6" }}>
          {value.text}
        </Text>
      </HoverBox>
    ),
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ value }) => (
      <Text fontSize="sm" fontWeight="normal" color="black">
        {value}
      </Text>
    ),
  },
  {
    Header: "Deal",
    accessor: "deal",
    Cell: ({ value }) => (
      <HoverBox icon={MdBusiness}>
        <Text fontSize="sm" fontWeight="700" _groupHover={{ color: "#1A5BF6" }}>
          {value}
        </Text>
      </HoverBox>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      const colorScheme = {
        "In-Progress": { color: "#4CAF50" },
        New: { color: "#2196F3" },
        Blocked: { color: "#F44336" },
        "Waiting Customer": { color: "#FFC107" },
        default: { color: "#9E9E9E" },
      };
      const { color } = colorScheme[value] || colorScheme.default;
      return (
        <Flex alignItems="center">
          <Box
            display="inline-flex"
            alignItems="center"
            px={3}
            py={1}
            bg="white"
          >
            <Box
              height="10px"
              width="10px"
              borderRadius="full"
              bg={color}
              mr={2}
            ></Box>
            <Text fontSize="sm" fontWeight="normal" color="black">
              {value}
            </Text>
          </Box>
        </Flex>
      );
    },
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }) => {
      const formattedDate = formatDate(value);
      return (
        <Text fontSize="sm" fontWeight="normal" color="black">
          {formattedDate}
        </Text>
      );
    },
  },
];

export default columnsCases;
