// LiveActivityItem.js

import React, { useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Icon,
  IconButton,
  Collapse,
} from "@chakra-ui/react";
import { MdEmail, MdEvent, MdAssignment } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import GongTaskCard from "./RichCards/GongTaskCard";
import CalendlyTaskCard from "./RichCards/CalendlyTaskCard";
import EmailCard from "./RichCards/EmailCard";
import LinkedInActivityCard from "./RichCards/LinkedInActivityCard";

// Helper function to format the date
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);

  // Options for weekday and month
  const weekday = date.toLocaleDateString(undefined, { weekday: "long" });
  const month = date.toLocaleDateString(undefined, { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  // Ensure month abbreviation has a period
  const monthWithPeriod = month.endsWith(".") ? month : `${month}.`;

  return `${weekday} ${monthWithPeriod} ${day}, ${year}`;
};

// Function to determine activity category, prioritizing activity_purpose and description
const getActivityCategory = (activity) => {
  const activityType = activity.activity_type
    ? activity.activity_type.toLowerCase()
    : "";
  const activityPurpose = activity.classification?.activity_purpose
    ? activity.classification.activity_purpose.toLowerCase()
    : "";
  const description = activity.description
    ? activity.description.toLowerCase()
    : "";

  if (description.includes("app.gong.io")) {
    return "gong-recording";
  }

  if (activityType === "email" || activityPurpose === "email communication") {
    return "email";
  }

  if (
    activityType === "event" ||
    ["meeting/call", "demonstration", "meeting scheduling"].includes(
      activityPurpose
    )
  ) {
    return "meeting";
  }

  if (
    activityType.startsWith("linkedin") ||
    ["message", "linkedin activity"].includes(activityPurpose)
  ) {
    return "message";
  }

  if (activityType === "task" || activityPurpose === "task") {
    return "task";
  }

  // Default category
  return "other";
};

// Function to get the appropriate icon based on activity category
const getIcon = (activity) => {
  const category = getActivityCategory(activity);

  switch (category) {
    case "email":
      return MdEmail;
    case "meeting":
      return MdEvent;
    case "message":
      return FaLinkedin;
    case "task":
      return MdAssignment;
    case "gong-recording":
      return FaLinkedin; // Or another suitable icon
    default:
      return MdEvent;
  }
};

// Function to get user name based on user ID
const getUserName = (userId) => {
  const userNames = {
    // Add your user mappings here
    26: "Chris Baily",
    28: "Kaili Vance",
    17: "Liam",
    18: "Tyler Bobbitt",
    38: "Billy Parry", // Added based on your JSON
    // Add more mappings as needed
  };
  return userNames[userId] || "Unknown User";
};

// Mapping of email addresses to names
// const emailToName = {
//   "jane.doe@example.com": "Jane Doe",
//   "john.smith@example.com": "John Smith",
//   // Add more mappings as needed
// };

// Helper function to extract Gong link from description
const extractGongLink = (description) => {
  const gongLinkRegex = /https?:\/\/[\w.-]+\.app\.gong\.io\/call\?id=\d+/i;
  const match = description?.match(gongLinkRegex);
  return match ? match[0] : null;
};

// Helper function to extract Meeting Title from description
const extractMeetingTitle = (description) => {
  // Assuming the meeting title is provided in the description in a specific format.
  // For example: "Meeting Title: Google Meet: Canal <> Sysco"
  const titleRegex = /Meeting Title:\s*(.+)/i;
  const match = description?.match(titleRegex);
  return match ? match[1].trim() : null;
};

// Function to generate a user-friendly title for each activity
const generateTitle = (activity) => {
  const userName = getUserName(activity.user_id);
  const category = getActivityCategory(activity);
  let titleElements = [];

  // Trim "[Gong]" or any case-insensitive variation from the title
  const trimmedTitle = activity.title.replace(/\[gong\]/i, "").trim();

  // Helper function to check if a substring exists in the title
  const titleIncludes = (title, keyword) => {
    return title.toLowerCase().includes(keyword.toLowerCase());
  };

  // Check if the title includes '[Gong]'
  if (titleIncludes(activity.title, "[Gong]")) {
    const gongLink = extractGongLink(activity.description);
    const meetingTitle =
      extractMeetingTitle(activity.description) || trimmedTitle;

    if (gongLink) {
      // Activity has a gong.io link
      titleElements = [
        <strong key="user">{userName}</strong>,
        " recorded '",
        <strong key="meetingTitle">{meetingTitle}</strong>,
        "' with Gong",
      ];
    } else if (titleIncludes(activity.title, "cancel")) {
      // Activity is canceled
      // Extract the names before '- Meeting Canceled' or similar patterns
      const namePart = trimmedTitle.split("-")[0].trim();
      titleElements = [
        <strong key="user">{userName}</strong>,
        " canceled ",
        <strong key="name">{namePart}</strong>,
      ];
    } else {
      // Activity is scheduled
      const namePart = trimmedTitle; // e.g., "Shaylee and Brett Rounsaville"
      titleElements = [
        <strong key="user">{userName}</strong>,
        " scheduled ",
        <strong key="name">{namePart}</strong>,
      ];
    }
  } else {
    // Existing category-based title generation
    switch (category) {
      case "email": {
        const emailSubject =
          activity.classification?.email_subject || trimmedTitle || "an email";
        titleElements = [
          <strong key="user">{userName}</strong>,
          " sent an email: ",
          <strong key="subject">{emailSubject}</strong>,
        ];
        break;
      }
      case "meeting": {
        const communicationMedium =
          activity.classification?.communication_medium?.toLowerCase() || "";
        const duration = activity.classification?.duration || "";
        if (
          communicationMedium === "gong" ||
          communicationMedium === "google meet"
        ) {
          if (activity.classification?.meeting_link) {
            // If there's a recording link, display as previously defined
            titleElements = [
              <strong key="user">{userName}</strong>,
              " recorded '",
              <strong key="meetingTitle">{trimmedTitle}</strong>,
              `' for `,
              <strong key="duration">{duration}</strong>,
            ];
          } else if (
            activity.classification?.key_action?.toLowerCase() === "open"
          ) {
            // If key_action is "Open", display the scheduled title
            titleElements = [
              <strong key="user">{userName}</strong>,
              " scheduled ",
              <strong key="meetingTitle">{trimmedTitle}</strong>,
            ];
          } else {
            // Fallback for other cases
            titleElements = [
              <strong key="user">{userName}</strong>,
              " had a meeting: ",
              <strong key="meetingTitle">{trimmedTitle}</strong>,
            ];
          }
        } else {
          // For other communication mediums
          titleElements = [
            <strong key="user">{userName}</strong>,
            " had a meeting: ",
            <strong key="meetingTitle">{trimmedTitle}</strong>,
          ];
        }
        break;
      }
      case "message": {
        const counterpart =
          activity.classification?.entities_involved?.join(", ") || "someone";
        titleElements = [
          <strong key="user">{userName}</strong>,
          " sent a message to ",
          <strong key="counterpart">{counterpart}</strong>,
        ];
        break;
      }
      case "task": {
        const taskTitle = trimmedTitle || "a task";
        titleElements = [
          <strong key="user">{userName}</strong>,
          " created a task: ",
          <strong key="taskTitle">{taskTitle}</strong>,
        ];
        break;
      }
      case "gong-recording": {
        // const { recordingLink, callDate, duration, callBrief } =
        //   parseGongDescription(activity.description);
        titleElements = [
          <strong key="user">{userName}</strong>,
          " recorded a Gong meeting: ",
          <strong key="meetingTitle">{trimmedTitle}</strong>,
        ];
        break;
      }
      default:
        titleElements = [activity.title || "Activity"];
    }
  }

  return <Text>{titleElements}</Text>;
};

// Helper function to parse Gong description
const parseGongDescription = (description) => {
  const recordingLinkRegex = /https?:\/\/[\w.-]+\.app\.gong\.io\/call\?id=\d+/i;
  const recordingLinkMatch = description.match(recordingLinkRegex);
  const recordingLink = recordingLinkMatch ? recordingLinkMatch[0] : null;

  const callDateRegex = /Took place on:\s*([^,]+),\s*([^,]+),\s*([^,]+)/i;
  const callDateMatch = description.match(callDateRegex);
  const callDate = callDateMatch
    ? `${callDateMatch[1]}, ${callDateMatch[2]}, ${callDateMatch[3]}`
    : "N/A";

  const durationRegex = /Duration:\s*([\d]+)\s*minutes/i;
  const durationMatch = description.match(durationRegex);
  const duration = durationMatch ? durationMatch[1] + " minutes" : "N/A";

  const callBriefRegex = /Call brief:\s*([\s\S]+)/i;
  const callBriefMatch = description.match(callBriefRegex);
  const callBrief = callBriefMatch ? callBriefMatch[1].trim() : "N/A";

  return { recordingLink, callDate, duration, callBrief };
};

// Main component for displaying a single live activity item
const LiveActivityItem = ({ activity }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  // Function to generate expanded content based on activity category
  const renderExpandedContent = () => {
    const category = getActivityCategory(activity);

    switch (category) {
      case "email": {
        return <EmailCard activity={activity} />;
      }
      case "message": {
        return <LinkedInActivityCard activity={activity} />;
      }
      case "meeting": {
        const communicationMedium =
          activity.classification?.communication_medium?.toLowerCase() || "";
        const keyAction =
          activity.classification?.key_action?.toLowerCase() || "";
        const hasMeetingLink = !!activity.classification?.meeting_link;

        if (
          (communicationMedium === "gong" ||
            communicationMedium === "google meet") &&
          hasMeetingLink
        ) {
          // If there's a recording link, render GongTaskCard with detailed info
          return <GongTaskCard activity={activity} />;
        } else if (communicationMedium === "gong" && keyAction === "open") {
          // If key_action is "Open", render a simplified card with only deal_stage
          return (
            <Box
              p={4}
              bg="gray.100"
              borderRadius="md"
              boxShadow="sm"
              width="100%"
              border="1px solid"
              borderColor="gray.200"
            >
              <VStack align="stretch" spacing={3}>
                <Text fontWeight="bold">
                  {activity.title.replace(/\[gong\]/i, "").trim()}
                </Text>
                <Text>
                  <strong>Deal Stage:</strong>{" "}
                  {activity.classification?.deal_stage || "N/A"}
                </Text>
              </VStack>
            </Box>
          );
        } else if (communicationMedium === "calendly") {
          return <CalendlyTaskCard activity={activity} />;
        } else {
          return (
            <Box p={4} bg="gray.100" borderRadius="md">
              <Text fontWeight="bold">
                {activity.title.replace(/\[gong\]/i, "").trim()}
              </Text>
              <Text>{activity.description || "No description provided."}</Text>
            </Box>
          );
        }
      }
      case "task": {
        return (
          <Box p={4} bg="gray.100" borderRadius="md">
            <Text fontWeight="bold">
              {activity.title.replace(/\[gong\]/i, "").trim()}
            </Text>
            <Text>{activity.description || "No description provided."}</Text>
            {/* Add more fields as needed */}
          </Box>
        );
      }
      case "gong-recording": {
        // Parse Gong details from description
        const { recordingLink, callDate, duration, callBrief } =
          parseGongDescription(activity.description);

        return (
          <GongTaskCard
            recordingLink={recordingLink}
            callDate={callDate}
            duration={duration}
            callBrief={callBrief}
            title={activity.title.replace(/\[gong\]/i, "").trim()}
            userName={getUserName(activity.user_id)}
          />
        );
      }
      default:
        return <Text>Details not available.</Text>;
    }
  };

  return (
    <Box width="100%">
      <HStack
        width="100%"
        spacing={4}
        p={3}
        bg="white"
        boxShadow="sm"
        justifyContent="space-between"
        alignItems="center"
        fontSize="sm"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
      >
        {/* Activity Icon */}
        <Icon
          as={getIcon(activity)}
          color="blue.500" // Always blue
          boxSize={6}
        />

        {/* Activity Title and Timestamp */}
        <VStack align="start" flex="1" spacing={0}>
          {generateTitle(activity)}
          {/* Updated Timestamp to Use activity_date in "Friday Oct. 11, 2024" format */}
          <Text fontSize="xs" color="gray.500">
            {formatDate(activity.activity_date)}
          </Text>
        </VStack>

        {/* Expand/Collapse Button */}
        <IconButton
          icon={isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
          aria-label="Toggle details"
          variant="outline"
          borderRadius="full"
          size="sm"
          border="1px solid"
          borderColor="gray.300"
          onClick={toggleExpand}
        />
      </HStack>

      {/* Expanded Content */}
      <Collapse in={isExpanded} animateOpacity>
        <Box mt={2}>{renderExpandedContent()}</Box>
      </Collapse>
    </Box>
  );
};

export default LiveActivityItem;
