// src/components/StatCard.js

import React from "react";
import { Icon } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";

/**
 * StatCard Component
 * Displays a statistic with an icon, title, value, and growth percentage.
 *
 * @param {Object} props - The props for the component.
 * @param {React.Component} props.icon - The icon to display.
 * @param {string} props.title - The title of the statistic.
 * @param {number|string} props.value - The value of the statistic.
 * @param {number|string} props.growth - The growth percentage.
 * @param {string} props.primaryColor - The primary color for the icon background.
 * @returns {JSX.Element} The rendered StatCard component.
 */
const StatCard = ({
  icon,
  title,
  value,
  growth,
  timeRange,
  primaryColor, // Added primaryColor as a prop instead of using constant
}) => {
  const displayValue =
    value !== undefined && value !== "N/A" ? value : "N/A";
  
  // Remove the '%' here to prevent double percentage signs
  const displayGrowth =
    growth !== undefined && growth !== "N/A" ? growth : "N/A";

  const iconBox = (
    <IconBox
      w="56px"
      h="56px"
      bg={primaryColor}
      icon={<Icon as={icon} w="32px" h="32px" color="white" />}
    />
  );

  return (
    <MiniStatistics
      name={title}
      value={displayValue}
      growth={displayGrowth}
      timeRange={timeRange}
      startContent={iconBox}
    />
  );
};

// Add default export
export default StatCard;
