// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Heading,
  useToast,
} from "@chakra-ui/react";
import {
  MdEventAvailable,
  MdEmail,
  MdSend,
  MdInbox,
  MdPersonAdd,
  MdAllInclusive,
  MdCall, // Added for Calls icon
} from "react-icons/md";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import mockData from './mockData.json'; // Ensure correct path and JSON structure
import { useAuthData } from "auth-context";

// Constants and Utils
import { PRIMARY_COLOR, SECONDARY_COLOR, CHART_COLORS } from './constants';
import { transformDashboardData } from './transformers';

// Components
import StatCard from './components/StatCard';
import ChartGrid from './components/ChartGrid';
import DashboardControls from './components/DashboardControls';
import LiveActivity from './components/LiveActivity';

// DashboardHeader Component
const DashboardHeader = ({ textColor }) => {
  return (
    <Flex
      flexDirection="column"
      px="20px"
      mb="10px"
      align={{ base: "flex-start", md: "stretch" }}
    >
      <Heading size="lg" color={textColor} mb="10px">
        Dashboard
      </Heading>
    </Flex>
  );
};

/**
 * Dashboard Component
 * The main component that orchestrates the dashboard layout and data.
 */
const Dashboard = () => {
  // State
  const [timeRange, setTimeRange] = useState('week'); // Default to 'week'
  const [selectedRep, setSelectedRep] = useState({ name: 'team' }); // Default to 'team'
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [usersData, setUsersData] = useState(["Team"]); // User data

  // New State Variables for LinkedIn Data
  const [setLinkedinData] = useState(null); // Overall LinkedIn data
  const [setLinkedinDataByUsers] = useState([]); // LinkedIn data per user

  // Theme Colors
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const textColor = useColorModeValue("gray.700", "white");

  // Get the current user ID based on selectedRep
  const getCurrentUserId = () => {
    // If team is selected or no rep is selected, return null
    if (!selectedRep || selectedRep.name === 'team') {
      return null;
    }

    // Find the selected user in dashboardData.members
    if (dashboardData?.members) {
      const selectedUser = dashboardData.members.find(
        member => member.name.toLowerCase() === selectedRep.name.toLowerCase()
      );
      return selectedUser?.id || null;
    }

    return null;
  };

  // Initialize useToast
  const toast = useToast();

  // Authentication and Account Info
  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const accountID = userData?.account_ID;

  // Function to determine days based on timeRange
  const getDays = () => {
    if (timeRange === 'day') return 1;
    if (timeRange === 'week') return 7;
    return 30;
  };

  // Effect to fetch dashboard data whenever timeRange or selectedRep changes
  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!accountID) return;
      setLoading(true);

      try {
        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";
        
        // Determine days based on timeRange
        const days = getDays();

        // Define headers with authorization
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        // Base URL with account_id
        const baseUrl = `${target}/api/accounts/${accountID}`;
        // Define parameters
        let pipeline_external_id = "7fded5a8-151d-4cd5-8d86-b434c362f707"; // Currently Cana'ls HS external pipeline id.
        if (accountID === "4") {
          pipeline_external_id = "012JA0000004IMpYAM";
        }
        const params = {
          days,
          pipeline_external_id: pipeline_external_id,
          period: timeRange,
        };

        // If selectedRep is not 'team', add 'rep' parameter
        if (selectedRep.name !== 'team') {
          params.rep = selectedRep.name.toLowerCase();
        }

        // Make API calls in parallel
        const [
          leadSourceRes,
          activityCountByDaysRes,
          activityCountByStageRes,
          dealActivityCountByDaysAndUsersRes,
          linkedinDataRes,
          linkedinDataByUsersRes,
          activityPercentageChange,
          usersDataResponse,
        ] = await Promise.all([
          axios.get(`${baseUrl}/deal/deal-count-by-stage-and-source`, { headers, params }),
          axios.get(`${baseUrl}/hubspot/activity-count-by-days`, { headers, params }),
          axios.get(`${baseUrl}/hubspot/activity-count-by-stage`, { headers, params }),
          axios.get(`${baseUrl}/deal/activity-count-by-days-and-users`, { headers, params }),
          axios.get(`${baseUrl}/linkedin/activity-count-by-days`, { headers, params }),
          axios.get(`${baseUrl}/linkedin/activity-count-by-days-and-user`, { headers, params }),
          axios.get(`${baseUrl}/activity-percentage-change`, { headers, params }),
          axios.get(`${baseUrl}/get-users`, { headers }),
        ]);

        // Store the LinkedIn overall data
        setLinkedinData(linkedinDataRes.data || {});

        // Store the LinkedIn data per user
        const linkedinUsers = linkedinDataByUsersRes.data || [];
        setLinkedinDataByUsers(linkedinUsers);

        // Merge the member data from both APIs
        const mergeMembers = () => {
          const dealUsers = dealActivityCountByDaysAndUsersRes?.data || [];
          const linkedinUsers = linkedinDataByUsersRes?.data || [];

          // Merge dealUsers with linkedinUsers
          const mergedUsers = dealUsers.map(dealUser => {
            const linkedinUser = linkedinUsers.find(user => user.user_id === dealUser.user_id);
            return {
              id: dealUser.user_id,
              name: dealUser.name,
              email: dealUser.email,
              // Deal activities
              totalLeads: dealUser.totalLeads || 0, // Assuming totalLeads is available
              leadGrowth: dealUser.leadGrowth || 0,
              meetings: dealUser.meetings || 0,
              meetingGrowth: dealUser.meetingGrowth || 0,
              emails: dealUser.emails || 0,
              emailGrowth: dealUser.emailGrowth || 0,
              calls: dealUser.calls || 0,
              callGrowth: dealUser.callGrowth || 0,
              // LinkedIn activities
              linkedinMessagesSent: linkedinUser?.linkedin_messages_sent?.count || 0,
              linkedinMessagesSentGrowth: linkedinUser?.linkedin_messages_sent?.growth || 0,
              linkedinMessagesReceived: linkedinUser?.linkedin_messages_received?.count || 0,
              linkedinMessagesReceivedGrowth: linkedinUser?.linkedin_messages_received?.growth || 0,
              linkedinInvites: linkedinUser?.linkedin_invites?.count || 0,
              linkedinInvitesGrowth: linkedinUser?.linkedin_invites?.growth || 0,
              // Total Activities
              totalActivities: (dealUser.total_current || 0) + (linkedinUser?.total_current || 0),
              totalActivitiesGrowth: linkedinUser?.total_percentage_change || 0,
            };
          });

          // Compute team totals from LinkedIn data
          const teamLinkedinMessagesSent = linkedinDataRes.data.linkedin_messages_sent || 0;
          const teamLinkedinMessagesSentGrowth = activityPercentageChange.data.ActivityTypeChanges.linkedin_messages_sent_growth || 0;
          const teamLinkedinMessagesReceived = linkedinDataRes.data.linkedin_messages_received || 0;
          const teamLinkedinMessagesReceivedGrowth = activityPercentageChange.data.ActivityTypeChanges.linkedin_messages_received_growth || 0;
          const teamLinkedinInvites = linkedinDataRes.data.linkedin_invites || 0;
          const teamLinkedinInvitesGrowth = activityPercentageChange.data.ActivityTypeChanges.linkedin_invites_growth || 0;

          // Compute team total activities and growth
          const teamTotalActivities = mergedUsers.reduce((acc, user) => acc + (user.totalActivities || 0), 0);
          const teamTotalPrevious = mergedUsers.reduce((acc, user) => acc + (user.totalActivitiesGrowth || 0), 0);
          const teamTotalPercentageChange = teamTotalPrevious === 0 ? 0 : ((teamTotalActivities - teamTotalPrevious) / teamTotalPrevious) * 100;

          // Add the "team" entry
          return [
            ...mergedUsers,
            {
              name: "team",
              totalLeads: leadSourceRes.data.totalLeads || 0,
              leadGrowth: leadSourceRes.data.leadGrowth || 0,
              meetings: activityCountByDaysRes.data.totalMeetings || 0,
              meetingGrowth: activityPercentageChange.data.ActivityTypeChanges.meetingGrowth || 0,
              emails: activityCountByDaysRes.data.totalEmails || 0,
              emailGrowth: activityPercentageChange.data.ActivityTypeChanges.emailGrowth || 0,
              calls: activityCountByDaysRes.data.totalCalls || 0,
              callGrowth: activityPercentageChange.data.ActivityTypeChanges.callGrowth || 0,
              linkedinMessagesSent: teamLinkedinMessagesSent,
              linkedinMessagesSentGrowth: teamLinkedinMessagesSentGrowth,
              linkedinMessagesReceived: teamLinkedinMessagesReceived,
              linkedinMessagesReceivedGrowth: teamLinkedinMessagesReceivedGrowth,
              linkedinInvites: teamLinkedinInvites,
              linkedinInvitesGrowth: teamLinkedinInvitesGrowth,
              totalActivities: teamTotalActivities,
              totalActivitiesGrowth: teamTotalPercentageChange,
            }
          ];
        };

        const mergedMembers = mergeMembers();

        // Extract user names (or any other identifier you prefer)
        if (usersDataResponse) {
          // Map the users to an array of objects with a 'name' property
          const userObjects = usersDataResponse.data.users.map((user) => ({
            name: user.name,
            id: user.id
          }));

          // If you want to include "Team" as the first object in the array
          const updatedUsersData = [{ name: "Team" }, ...userObjects];

          // Update the state with the new array of user objects
          setUsersData(updatedUsersData);
        }

        // Combine the data into a single object
        const combinedData = {
          totalLeads: leadSourceRes.data.totalLeads || 0,
          leadGrowth: leadSourceRes.data.leadGrowth || 0,
          meetings: activityCountByDaysRes.data.totalMeetings || 0, // Map 'totalMeetings' to 'meetings'
          meetingGrowth: activityPercentageChange.data.ActivityTypeChanges.meetingGrowth || 0,
          emails: activityCountByDaysRes.data.totalEmails || 0, // Map 'totalEmails' to 'emails'
          emailGrowth: activityPercentageChange.data.ActivityTypeChanges.emailGrowth || 0,
          calls: activityCountByDaysRes.data.totalCalls || 0, // Map 'totalCalls' to 'calls'
          callGrowth: activityPercentageChange.data.ActivityTypeChanges.callGrowth || 0,
          linkedin_messages_sent: linkedinDataRes.data.linkedin_messages_sent || 0,
          linkedin_messages_received: linkedinDataRes.data.linkedin_messages_received || 0,
          linkedin_invites: linkedinDataRes.data.linkedin_invites || 0,
          new_leads: linkedinDataRes.data.new_leads || 0,
          viewed_profiles: linkedinDataRes.data.viewed_profiles || 0,
          // Include totalActivities and totalActivitiesGrowth from team
          totalActivities: mergedMembers.find(member => member.name === "team")?.totalActivities || 0,
          totalActivitiesGrowth: mergedMembers.find(member => member.name === "team")?.totalActivitiesGrowth || 0,
          leadSourceData: leadSourceRes.data.leadSources || {}, // Updated from pipelineData
          velocityData: leadSourceRes.data.velocityData || { trend: [], current: 0, teamAverage: 0 }, // Adjust if necessary
          stageDurationData: leadSourceRes.data.stageDurationData || [],
          activityData: activityCountByStageRes?.data?.activityData || [],
          conversionData: leadSourceRes.data.conversionData || [],
          members: mergedMembers,
        };

        // Log combinedData to inspect it
        console.log("Combined Data:", combinedData);

        setDashboardData(combinedData);
      } catch (err) {
        console.log("Error fetching data:", err); 
        // Fallback to mock data for the current time range
        const mockTimeRangeData = mockData.timeRanges.find(tr => tr.range === timeRange);
        if (mockTimeRangeData) {
          // Flatten the mock data to match the structure expected by transformDashboardData
          const flattenedMockData = {
            totalLeads: mockTimeRangeData.teamData.totalLeads || 0,
            leadGrowth: mockTimeRangeData.teamData.leadGrowth || 0,
            meetings: mockTimeRangeData.teamData.totalMeetings || 0, // Map 'totalMeetings' to 'meetings'
            meetingGrowth: mockTimeRangeData.teamData.meetingGrowth || 0,
            emails: mockTimeRangeData.teamData.totalEmails || 0, // Map 'totalEmails' to 'emails'
            emailGrowth: mockTimeRangeData.teamData.emailGrowth || 0,
            calls: mockTimeRangeData.teamData.totalCalls || 0, // Map 'totalCalls' to 'calls'
            callGrowth: mockTimeRangeData.teamData.callGrowth || 0,
            linkedin_messages_sent: mockTimeRangeData.teamData.linkedin_messages_sent || 0,
            linkedin_messages_received: mockTimeRangeData.teamData.linkedin_messages_received || 0,
            linkedin_invites: mockTimeRangeData.teamData.linkedin_invites || 0,
            new_leads: mockTimeRangeData.teamData.new_leads || 0,
            viewed_profiles: mockTimeRangeData.teamData.viewed_profiles || 0,
            leadSourceData: mockTimeRangeData.leadSources || {}, // Ensure this matches your mock data structure
            velocityData: mockTimeRangeData.velocityData || { trend: [], current: 0, teamAverage: 0 },
            stageDurationData: mockTimeRangeData.stageDurationData || [],
            activityData: mockTimeRangeData.activityData || [],
            conversionData: mockTimeRangeData.conversionData || [],
            members: mockTimeRangeData.members || [],
            totalActivities: mockTimeRangeData.teamData.totalActivities || 0,
            totalActivitiesGrowth: mockTimeRangeData.teamData.totalActivitiesGrowth || 0,
          };

          // Log flattenedMockData to inspect it
          console.log("Flattened Mock Data:", flattenedMockData);

          setDashboardData(flattenedMockData);

          const updatedUsersData = [{ name: "Team" }, { name: "Alice" }, { name: "Bob" }];
        
          // Update the state with the new array of user objects
          setUsersData(updatedUsersData);

          toast({
            title: "Data Unavailable",
            description: "Unable to fetch real-time data. Displaying mock data.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else {
          // Handle the case where the time range is not found in mockData
          setDashboardData(null);
          toast({
            title: "Data Unavailable",
            description: "No mock data available for the selected time range.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [timeRange, accountID, selectedRep, getAccessTokenSilently, toast]);

  // Handle loading state
  if (loading) {
    return <Box>Loading...</Box>;
  }

  // Ensure that dashboardData is available
  if (!dashboardData) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px="20px">
        <DashboardHeader textColor={textColor} />
        <Box>
          <Heading size="md" color={textColor} mb="10px">
            No Data Available
          </Heading>
          <Box>No data is available for the selected time range. Please try again later.</Box>
        </Box>
      </Box>
    );
  }

  // Transform dashboard data
  const transformedData = transformDashboardData(dashboardData, selectedRep);

  // Log transformedData to inspect it
  console.log("Transformed Data:", transformedData);

  const {
    statsData,
    // velocityData,
    // durationData,
    // activityData,
    // conversionData,
    // activityChartData,
    // leadSources, // Removed since we're now using leadSourceChartData
    // leadSourceOverviewData, // Removed since we're using leadSourceChartData
  } = transformedData;

  /**
   * Configuration for StatCards
   * Added additional StatCards for Total Activities and LinkedIn metrics.
   */
  const statCards = [
    {
      icon: MdAllInclusive, // Icon for Total Activities
      title: "Total Activities",
      value: dashboardData.totalActivities,
      growth: dashboardData.totalActivitiesGrowth,
    },
    {
      icon: MdEventAvailable,
      title: "Meetings",
      value: statsData.meetings,
      growth: statsData.meetingGrowth,
    },
    {
      icon: MdEmail,
      title: "Emails Sent",
      value: statsData.emails,
      growth: statsData.emailGrowth,
    },
    {
      icon: MdCall, // Icon for Calls
      title: "Calls",
      value: statsData.calls,
      growth: statsData.callGrowth,
    },
    {
      icon: MdSend, // Icon for LinkedIn Messages Sent
      title: "LinkedIn Messages Sent",
      value: statsData.linkedinMessagesSent,
      growth: statsData.linkedinMessagesSentGrowth,
    },
    {
      icon: MdInbox, // Icon for LinkedIn Messages Received
      title: "LinkedIn Messages Received",
      value: statsData.linkedinMessagesReceived,
      growth: statsData.linkedinMessagesReceivedGrowth,
    },
    {
      icon: MdPersonAdd, // Icon for LinkedIn Invites
      title: "LinkedIn Invites",
      value: statsData.linkedinInvites,
      growth: statsData.linkedinInvitesGrowth,
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Header */}
      <DashboardHeader textColor={textColor} />

      {/* Controls */}
      <DashboardControls
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        selectedRep={selectedRep}
        setSelectedRep={setSelectedRep}
        reps={usersData} // Ensure mockData includes reps
        timeRangesData={mockData.timeRanges} // Ensure mockData includes timeRanges
      />

      {/* Top Stats Row */}
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} gap="20px" mb="20px" px="20px">
        {statCards.map((stat, index) => (
          <StatCard
            key={index}
            icon={stat.icon}
            title={stat.title}
            value={stat.value}
            growth={stat.growth}
            timeRange={timeRange}
            primaryColor={PRIMARY_COLOR}
          />
        ))}
      </SimpleGrid>

      {/* Charts */}
      <ChartGrid
        boxBg={boxBg}
        transformedData={transformedData} // Pass transformedData
        primaryColor={PRIMARY_COLOR}
        secondaryColor={SECONDARY_COLOR}
        chartColors={CHART_COLORS} // Pass chart colors if needed
      />
      
      {/* Include the LiveActivity component */}
      <Box px="20px" mt={6}>
        {accountID && (getCurrentUserId() || selectedRep.name === 'team') ? (
          <LiveActivity 
            accountId={accountID} 
            userId={getCurrentUserId()} 
          />
        ) : (
          <Box 
            p={4} 
            bg="white" 
            borderRadius="md" 
            boxShadow="sm"
            textAlign="center"
            color="gray.500"
          >
            Please select a team member to view their activities.
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
