import React from "react";
import { Box, Text, VStack, UnorderedList, ListItem } from "@chakra-ui/react";

const MeetingPrepContent = () => {
  const sections = [
    {
      title: "Background:",
      content: [
        "The Bluth Company is a real estate development firm founded by George Bluth.",
        "Challenges include high administrative workload, inefficiencies with CRM updates, and concerns over recording sensitive client information during sales calls.",
        "Sales team spends 30% of their time on administrative tasks, impacting productivity.",
        "The goal is to streamline workflows through automation and ensure compliance with security and privacy standards.",
        "This meeting will focus on demonstrating the proposed solution and discussing the potential for a pilot.",
      ],
    },
    {
      title: "Key Topics for the Demo and Pilot Discussion:",
      content: [
        "How does the solution automate time-consuming tasks and improve CRM updates?",
        "Demonstrate the integration with Google Suite, Slack, and Jira—how seamless is the workflow?",
        "How does the platform ensure the security and privacy of sensitive data in recorded sales calls?",
        "What are the expected time savings and productivity gains?",
        "What is the process for starting the pilot, and how will success be measured during the trial?",
      ],
    },
    {
      title: "Value Proposition:",
      content: [
        "Automated workflows reduce administrative workload by 30%, allowing sales reps to focus on revenue-driving activities.",
        "Secure handling of sensitive client data, ensuring privacy compliance in sales calls.",
        "Integration with Google Suite, Slack, and Jira enhances collaboration and transparency across teams.",
        "Improved pipeline visibility and more efficient CRM updates.",
        "Tailored to the unique needs of The Bluth Company, helping them scale operations while maintaining data privacy and security.",
      ],
    },
  ];

  return (
    <Box bg="white" p={6} borderRadius="md" boxShadow="sm" mb={4}>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Meeting Prep
      </Text>
      <VStack align="stretch" spacing={6}>
        {sections.map((section, index) => (
          <Box key={index}>
            <Text fontSize="md" fontWeight="semibold" mb={2}>
              {section.title}
            </Text>
            <UnorderedList spacing={2} pl={4} fontSize="sm">
              {section.content.map((item, itemIndex) => (
                <ListItem key={itemIndex}>{item} </ListItem>
              ))}
            </UnorderedList>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default MeetingPrepContent;
