export default function customGlobalFilter(rows, columnIds, filterValue) {
  console.log("rows", rows);
  console.log("columnIds", columnIds);
  console.log("filterValue", filterValue);
  return rows.filter((row) => {
    // Check if any message in this row's conversation matches the filterValue
    const matchesConversation = row.values.conversation.some((message) => {
      
      return (
        message.user_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        message.message_content
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    });

    // Check if any note in this row's notes matches the filterValue
    const matchesNotes = row.original.notes.some((note) => {
      return (
        note.note_body.toLowerCase().includes(filterValue.toLowerCase()) ||
        note.note_type.toLowerCase().includes(filterValue.toLowerCase())
      );
    });

    return (
      matchesConversation ||
      matchesNotes ||
      columnIds.some((columnId) => {
        const cellValue = row.values[columnId];
        return String(cellValue)
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      })
    );
  });
}
