import React from "react";
import { Button, ButtonGroup, useColorModeValue } from "@chakra-ui/react";

const SegmentedControl = ({ options, selectedOption, onChange }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("white", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <ButtonGroup
      isAttached
      variant="outline"
      spacing="0"
      borderRadius="md"
      borderWidth="1px"
      borderColor={borderColor}
      bg={bgColor}
      p="2px"
    >
      {options.map((option) => (
        <Button
          key={option}
          onClick={() => onChange(option)}
          bg={selectedOption === option ? selectedBgColor : "transparent"}
          color={selectedOption === option ? "black" : "gray.500"}
          borderWidth={0}
          borderRadius="md"
          _hover={{ bg: selectedBgColor }}
          _active={{ bg: selectedBgColor }}
          fontWeight={selectedOption === option ? "semibold" : "normal"}
        >
          {option}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default SegmentedControl;
