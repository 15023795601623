// Chakra imports
import {
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { MdAdd } from "react-icons/md";

export default function NewCampaignButton({ onOpenModal }) {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, "2xl": 4}} gap='20px' mb='20px'>
      <Button
        width="100%"
        minHeight="50px"  // Set min height as requested
        boxShadow="sm"
        color="white"  // Set text color to white
        bg="b_red.200"  // Set background color to b_red.200
        leftIcon={<MdAdd size="22px" />}  // Increased icon size
        _hover={{ bg: "b_orange.200" }}  // Set hover background color to b_red.100
        onClick={onOpenModal}
      >
        Create New Campaign
      </Button>
    </SimpleGrid>
  );
}
