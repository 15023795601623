import React from "react";
import { Box, Flex, Icon, Text, Tag } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";

const MeetingInfo = ({ meetingData }) => {
  const contacts = [
    { name: "Manuel Diaz-Granados" },
    { name: "Kyle Archie" },
    { name: "Steven Nass" },
  ];

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mb={1}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Flex alignItems="center" mb={4}>
          <Icon
            as={MdEvent}
            width="20px"
            height="20px"
            color="gray.500"
            mr={2}
          />
          <Text fontSize="xl" fontWeight="bold">
            Meeting Title - {meetingData.title}
          </Text>
        </Flex>
        <Flex>
          <a href={meetingData.url} target="_blank" rel="noopener noreferrer">
            <Icon
              as={FaExternalLinkAlt}
              mx={2}
              cursor="pointer"
              color="black"
            />
          </a>
        </Flex>
      </Flex>
      <Text fontSize="md" fontWeight="semibold" mb={2}>
        Contacts
      </Text>
      <Flex alignItems="center" mb={6}>
        {contacts.map((contact, index) => (
          <Tag
            key={index}
            size="lg"
            borderRadius="lg"
            mr={1}
            px={3}
            py={1}
            bg="white"
            borderWidth="1px"
            borderColor="gray.300"
          >
            <Flex alignItems="center">
              <Icon as={FaUser} color="gray.500" mr={2} />
              <Text fontSize="sm" color="black">
                {contact.name}
              </Text>
            </Flex>
          </Tag>
        ))}
      </Flex>
    </Box>
  );
};

export default MeetingInfo;
