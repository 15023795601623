import { Box, SimpleGrid, Button, Icon, Flex, useDisclosure, Heading, useColorModeValue } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import React, { useEffect, useRef, useState } from "react";

// Custom components
import AutomationCard from "views/admin/automations/components/AutomationCard";
import NewWorkflowModal from "views/admin/automations/components/NewWorkflowModal";
import SegmentedControl from "views/lysto/Components/SegmentControl";

const campaignList = [
  {
    "Top-Level Card": {
      "Title": "HubSpot Update",
      "Integration Icons": ["hubspot"],
      "Status": {
        "Status Type": "Enabled",
        "Automation Type": "Review"
      }
    },
    "Campaign Stages": [
      {
        "Action 1": { "Title": "Customer emails admin@dawnnhills.com", "Handoff": "When" }
      },
      {
        "Action 2": { "Title": "Auto-reply with an introductory email", "Handoff": "Then" }
      },
      {
        "Action 3": { "Title": "No reply & 1 day passes", "Handoff": "When" }
      },
      {
        "Action 4": { "Title": "Send followup_1", "Handoff": "Then" }
      },
      {
        "Action 5": { "Title": "No reply & 3 days pass", "Handoff": "When" }
      },
      {
        "Action 6": { "Title": "Send followup_2", "Handoff": "Then" }
      },
      {
        "Action 7": { "Title": "No reply & 7 days pass", "Handoff": "When" }
      },
      {
        "Action 8": { "Title": "Send followup_3", "Handoff": "Then" }
      },
      {
        "Action 9": { "Title": "No reply & 30 days pass", "Handoff": "When" }
      },
      {
        "Action 10": { "Title": "Send followup_4", "Handoff": "Then" }
      }
    ]
  },
  {
    "Top-Level Card": {
      "Title": "Slack Update",
      "Integration Icons": ["slack"],
      "Status": {
        "Status Type": "Enabled",
        "Automation Type": "Review"
      }
    },
    "Campaign Stages": [
      {
        "Action 1": { "Title": "Scheduled meeting ends", "Handoff": "When" }
      },
      {
        "Action 2": { "Title": "Send promotion_email to dawnn@dawnnhills.com", "Handoff": "Then" }
      },
      {
        "Action 3": { "Title": "Dawnn emails confirmation to admin@dawnnhills.com", "Handoff": "When" }
      },
      {
        "Action 4": { "Title": "Start 'Insurance Education Session' Automation", "Handoff": "Then" }
      }
    ]
  },
  {
    "Top-Level Card": {
      "Title": "Weekly Email Account Update",
      "Integration Icons": ["gmail"],
      "Status": {
        "Status Type": "Enabled",
        "Automation Type": "Review"
      }
    },
    "Campaign Stages": [
      {
        "Action 1": { "Title": "Customer has an intro meeting", "Handoff": "When" }
      },
      {
        "Action 2": { "Title": "Send a follow-up email with overview videos", "Handoff": "Then" }
      },
      {
        "Action 3": { "Title": "No reply & 1 day passes", "Handoff": "When" }
      },
      {
        "Action 4": { "Title": "Send followup_1", "Handoff": "Then" }
      },
      {
        "Action 5": { "Title": "No reply & 3 days pass", "Handoff": "When" }
      },
      {
        "Action 6": { "Title": "Send followup_2", "Handoff": "Then" }
      },
      {
        "Action 7": { "Title": "No reply & 7 days pass", "Handoff": "When" }
      },
      {
        "Action 8": { "Title": "Send followup_3", "Handoff": "Then" }
      },
      {
        "Action 9": { "Title": "No reply & 30 days pass", "Handoff": "When" }
      },
      {
        "Action 10": { "Title": "Send followup_4", "Handoff": "Then" }
      }
    ]
  },
  {
    "Top-Level Card": {
      "Title": "Quarterly Update",
      "Integration Icons": ["gmail"],
      "Status": {
        "Status Type": "Enabled",
        "Automation Type": "Review"
      }
    },
    "Campaign Stages": [
      {
        "Action 1": { "Title": "Customer schedules a meeting using google calendar", "Handoff": "When" }
      },
      {
        "Action 2": { "Title": "Send them a confirmation email", "Handoff": "Then" }
      },
      {
        "Action 3": { "Title": "Email dawnn@dawnnhills.com to notify her", "Handoff": "and" }
      }
    ]
  },
  {
    "Top-Level Card": {
      "Title": "Report bug to Engineering",
      "Integration Icons": ["jira"],
      "Status": {
        "Status Type": "Enabled",
        "Automation Type": "Automatic"
      }
    },
    "Campaign Stages": [
      {
        "Action 1": { "Title": "Customer schedules a meeting using google calendar", "Handoff": "When" }
      },
      {
        "Action 2": { "Title": "Send them a confirmation email", "Handoff": "Then" }
      },
      {
        "Action 3": { "Title": "Email dawnn@dawnnhills.com to notify her", "Handoff": "and" }
      }
    ]
  },
];


export default function Overview() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const gridRef = useRef(null);
  const textColor = useColorModeValue("#56595E", "white");
  const [selectedView, setSelectedView] = useState("All");

  useEffect(() => {
    const updateNumCols = () => {
      if (gridRef.current) {
        // You can safely remove setNumCols if it's not used
      }
    };

    // Initial set
    updateNumCols();

    // Listen for window resize
    window.addEventListener('resize', updateNumCols);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateNumCols);
    };
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading as="h2" size="md" mb="20px" color={textColor} pt={5}>
        Workflows
      </Heading>

      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <SegmentedControl
          options={["All", "Review", "Auto"]}
          selectedOption={selectedView}
          onChange={setSelectedView}
        />
        <Button
          leftIcon={<Icon as={MdAdd} w={6} h={6} />}
          color="white"
          backgroundColor="#1A5BF6"
          variant="solid"
          borderRadius="md"
          _hover={{ backgroundColor: "#CAE1FC" }}
          px={6}
          onClick={onOpen}
        >
          New Workflow
        </Button>
      </Flex>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap='20px'
        mb='20px'
      >
        {campaignList.map((automationData, index) => (
          <AutomationCard
            key={index}
            title={automationData["Top-Level Card"].Title}
            integrationIcons={automationData["Top-Level Card"]["Integration Icons"]}
            status={automationData["Top-Level Card"].Status}
            automationStages={automationData["Campaign Stages"]}
          />
        ))}
      </SimpleGrid>
      <NewWorkflowModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}