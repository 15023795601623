import React, { useState, useEffect } from "react";
import { Flex, Box, Text, VStack } from "@chakra-ui/react";
import DOMPurify from "dompurify";
import parse, { domToReact, attributesToProps } from "html-react-parser";

// Function to determine the color of the bubble based on the message type
function getBubbleColor(item) {
  let name;
  if (item.type === "note") {
    name = item.note_type;
  } else {
    name = item.user_name;
  }
  if (item.type === "note") {
    switch (name.toLowerCase()) {
      case "memo":
        return "yellow.100";
      case "booked":
        return "b_red.100";
      case "called":
        return "b_orange.100";
      case "snooze":
        return "b_purple.100";
      default:
        return "b_blue.100";
    }
  } else {
    switch (item.identity.toLowerCase()) {
      case "customer":
        return "gray.200";
      default:
        return "b_blue.100";
    }
  }
}

// Function to apply custom styles to links
const options = {
  replace: (domNode) => {
    if (domNode.name && domNode.name === "a") {
      const props = attributesToProps(domNode.attribs);
      return (
        <a
          {...props}
          style={{ textDecoration: "underline", fontWeight: "bold" }}
        >
          {domToReact(domNode.children, options)}
        </a>
      );
    }
  },
};

// Function to format date and time to local timezone, assuming the input is in UTC
const formatDateToLocal = (dateString, timeString) => {
  if (!dateString || !timeString) {
    return "Invalid date or time"; // Handle falsy inputs
  }
  // Parse the date string
  const dateParts = dateString.match(/(\w+) (\d+), (\d+)/);
  const timeParts = timeString.match(/(\d+):(\d+) ([AP]M)/);
  if (!dateParts || !timeParts) {
    return "Invalid date or time"; // Handle invalid inputs
  }

  // Extract the parts of the date and time
  const monthName = dateParts[1];
  const day = parseInt(dateParts[2], 10);
  const year = parseInt(dateParts[3], 10);
  let hours = parseInt(timeParts[1], 10);
  const minutes = parseInt(timeParts[2], 10);
  const ampm = timeParts[3];

  // Convert 12-hour time to 24-hour time
  if (ampm === "PM" && hours < 12) {
    hours += 12;
  } else if (ampm === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a Date object representing the time in UTC
  const utcDate = new Date(
    Date.UTC(
      year,
      new Date(`1 ${monthName} 1999`).getMonth(),
      day,
      hours,
      minutes
    )
  );

  // Locale options for toLocaleString
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Format the UTC date to a readable format, adapted to local timezone
  const formattedDate = utcDate.toLocaleString("en-US", options);

  const splitDate = formattedDate.split(", ");
  return splitDate.length > 2
    ? `${splitDate[0]}, ${splitDate[1]} at ${splitDate[2]}`
    : formattedDate;
};

export default function ChatMessage({
  selectedThreadId,
  currentConversation,
  notes,
  sentMessages,
}) {
  if (!currentConversation) {
    return null;
  }
  const [formattedConversation, setCurrentConversation] = useState([]);

  useEffect(() => {
    const matchedConversation = currentConversation;
    if (matchedConversation) {
      // Filter out draft messages from the conversation
      const filteredMessages = matchedConversation.filter(
        (msg) => msg.status !== "draft"
      );

      // Combine filtered messages, notes, and sent messages
      const combinedData = [
        ...filteredMessages.map((msg) => ({
          ...msg,
          type: msg.type || "message", // Use existing type if available, otherwise default to "message"
        })),
        // Remove the notes mapping as it's not present in the provided JSON
        // ...notes.map((note) => ({ ...note, type: "note" })),
        ...(sentMessages[selectedThreadId] || []).map((msg) => ({
          ...msg,
          type: msg.type || "message", // Use existing type if available, otherwise default to "message"
        })),
      ].sort((a, b) => {
        const dateA = new Date(a.date_sent || a.date_created);
        const dateB = new Date(b.date_sent || b.date_created);
        return dateA - dateB;
      });

      setCurrentConversation(combinedData);
    }
  }, [selectedThreadId, currentConversation, notes, sentMessages]);

  return (
    <VStack align="stretch" width="100%">
      <style>{`
        .chakra-text p { padding-bottom: 5px; }
        .chakra-text ul {
          padding-left: 20px;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .chakra-text li {
          margin-left: 20px;
        }
      `}</style>
      {formattedConversation.map((item, index) => (
        <Flex
          width="100%"
          justifyContent={
            item.identity === "agent" || item.type === "note"
              ? "flex-end"
              : "flex-start"
          }
          key={index}
        >
          <Box
            p={2}
            maxWidth="80%"
            bg={getBubbleColor(item)}
            borderRadius="md"
            mt={1}
            textAlign="left"
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "14px",
              overflow: "hidden",
            }}
          >
            <Text>
              <strong>
                {item.identity === "agent"
                  ? "Manuel"
                  : item.type === "note"
                  ? item.note_type.charAt(0).toUpperCase() +
                    item.note_type.slice(1)
                  : item.user_name || "Unknown Sender"}
              </strong>
            </Text>
            <Text ml={1}>
              {parse(
                DOMPurify.sanitize(
                  item.type === "note" ? item.note_body : item.message_content
                ),
                options
              )}
            </Text>
            <Box ml="auto" mt="5px" textAlign="right" style={{ width: "100%" }}>
              <Text fontSize="0.8em">
                {item.type === "note"
                  ? `${formatDateToLocal(item.date_created, item.time_created)}`
                  : `${formatDateToLocal(item.date_sent, item.time_sent)}`}
              </Text>
            </Box>
          </Box>
        </Flex>
      ))}
    </VStack>
  );
}
