import { isToday, isTomorrow } from "date-fns";

const GetCurrentOrNextMeetingIndex = (events) => {
  const now = new Date();
  return events.findIndex((event) => new Date(event.endTime) >= now);
};

const FormatDate = (date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isTomorrow(date)) {
    return "Tomorrow";
  } else {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};

const GetUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export { GetCurrentOrNextMeetingIndex, FormatDate, GetUserTimezone };
