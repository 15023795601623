import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  Box,
  Text,
  Flex,
  Switch,
  Input,
  FormControl,
  FormLabel,
  Icon,
} from "@chakra-ui/react";

const NewWorkflowModal = ({ isOpen, onClose }) => {
  const [isHumanInLoop, setIsHumanInLoop] = useState(true);
  const [fields, setFields] = useState([
    "Meeting Outcome",
    "Customer Needs",
    "Next Steps",
  ]);
  const [newField, setNewField] = useState("");

  const handleAddField = () => {
    if (newField.trim() !== "") {
      setFields([...fields, newField.trim()]);
      setNewField("");
    }
  };

  const LightPlusIcon = (props) => (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 5V19M5 12H19"
        stroke="currentColor"
        strokeWidth="2.0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>HubSpot Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="bold" mb={1}>
              Fields
            </Text>
            <Text fontSize="sm" color="gray.600" mb={2}>
              Data to be generated by this workflow
            </Text>
            <Stack spacing={3}>
              {fields.map((field, index) => (
                <Box key={index} borderWidth="1px" borderRadius="md" p={4}>
                  <Flex alignItems="center">
                    <Text>{field}</Text>
                  </Flex>
                </Box>
              ))}
              <FormControl>
                <FormLabel
                  color="gray.600"
                  htmlFor="new-field"
                  fontSize="sm"
                  mb={1}
                >
                  Add a new field
                </FormLabel>
                <Flex alignItems="center">
                  <Input
                    id="new-field"
                    placeholder="New field name"
                    value={newField}
                    onChange={(e) => setNewField(e.target.value)}
                    size="lg"
                    height="55px"
                    fontSize="md"
                    mr={2}
                  />
                  <Button
                    leftIcon={<LightPlusIcon />}
                    onClick={handleAddField}
                    size="md"
                    height="55px"
                    variant="outline"
                    borderRadius="md"
                    fontWeight="normal"
                  >
                    Add
                  </Button>
                </Flex>
              </FormControl>
            </Stack>
          </Box>
          <Box mb={4}>
            <Text fontWeight="bold" mb={1} pt={4}>
              Manual Review
            </Text>
            <Box borderWidth="1px" borderRadius="md" p={4}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text>
                  Review the output of the process and submit manually
                </Text>
                <Switch
                  isChecked={isHumanInLoop}
                  onChange={(e) => setIsHumanInLoop(e.target.checked)}
                />
              </Flex>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" ml={3} borderRadius="md">
            Edit workflow
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewWorkflowModal;
