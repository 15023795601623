import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

// Custom components
import NewCampaignButton from "views/admin/campaigns/components/NewCampaignButton"; 
import CampaignCard from "views/admin/campaigns/components/CampaignCard";
import CampaignModal from "views/admin/campaigns/components/CampaignModal";
import ReviewTemplateModal from "views/admin/campaigns/components/ReviewTemplateModal";

const campaignList = [
  {
    "Top-Level Card": {
      "Title": "Halloween Marketing Campaign",
      "Prompt": "A spooky invitation to buy Halloween decorations for 25% off",
      "Customizations": ["name", "industry", "size", "location"],
      "Status": {
        "send_status": "Draft",
        "send_quantity": "0 of 5"
      }
    }
  },
  {
    "Top-Level Card": {
      "Title": "Thanksgiving Sale Campaign",
      "Prompt": "A hearty invitation to our Thanksgiving sale, enjoy discounts up to 30%!",
      "Customizations": ["name", "industry", "size", "location"],
      "Status": {
        "send_status": "Live",
        "send_quantity": "4 of 5"
      }
    }
  },
  {
    "Top-Level Card": {
      "Title": "Winter Holiday Campaign",
      "Prompt": "Warm up to our Winter Holiday deals with up to 40% off on select items!",
      "Customizations": ["name", "industry", "size", "location"],
      "Status": {
        "send_status": "Completed",
        "send_quantity": "5 of 5"
      }
    }
  },
  {
    "Top-Level Card": {
      "Title": "New Year's Resolution Campaign",
      "Prompt": "Kickstart your New Year's resolutions with our exclusive offers!",
      "Customizations": ["name", "industry", "size", "location"],
      "Status": {
        "send_status": "Live",
        "send_quantity": "8 of 10"
      }
    }
  },
  {
    "Top-Level Card": {
      "Title": "Valentine's Day Campaign",
      "Prompt": "Celebrate love with our special Valentine's Day discounts!",
      "Customizations": ["name", "industry", "size", "location"],
      "Status": {
        "send_status": "Completed",
        "send_quantity": "20 of 20"
      }
    }
  }
];


export default function Overview() {

  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => { setModalOpen(true); };
  const handleCloseModal = () => { setModalOpen(false); };
  const gridRef = useRef(null);
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const handleOpenReviewModal = () => { setReviewModalOpen(true); };
  const handleCloseReviewModal = () => { setReviewModalOpen(false); };
  const [campaignTitle, setCampaignTitle] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");

  useEffect(() => {
    const updateNumCols = () => {
      if (gridRef.current) {
        // You can safely remove setNumCols if it's not used
      }
    };

    // Initial set
    updateNumCols();

    // Listen for window resize
    window.addEventListener('resize', updateNumCols);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateNumCols);
    };
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <NewCampaignButton onOpenModal={handleOpenModal}/>
      <CampaignModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onOpenReviewModal={handleOpenReviewModal}
        setCampaignTitle={setCampaignTitle}
        setCampaignDescription={setCampaignDescription}
      />

      <ReviewTemplateModal
        isOpen={isReviewModalOpen}
        onClose={handleCloseReviewModal}
        campaignTitle={campaignTitle}
        campaignDescription={campaignDescription}
      />
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
        gap='20px'
        mb='20px'
      >
        {campaignList.map((campaignData, index) => (
        <CampaignCard
          key={index}
          title={campaignData["Top-Level Card"].Title}
          prompt={campaignData["Top-Level Card"].Prompt}
          customizations={campaignData["Top-Level Card"].Customizations}
          sendStatus={campaignData["Top-Level Card"].Status.send_status}
          sendQuantity={campaignData["Top-Level Card"].Status.send_quantity}
        />
      ))}
      </SimpleGrid>
    </Box>
  );
}