import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ chartData, chartOptions }) => {
  const options = {
    ...chartOptions,
    colors: chartOptions.colors || chartOptions.fill?.colors,
    labels: chartOptions.labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={chartData}
      type="pie"
      width="100%"
      height="300px"
    />
  );
};

export default PieChart;
