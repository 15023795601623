import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Button } from '@chakra-ui/react';
import { MdEmail, MdInsertDriveFile, MdMessage, MdAssignment, MdEvent } from 'react-icons/md';
import { PiPulse } from 'react-icons/pi';
import ActivityTimeline from './ActivityTimeline';
import { activities } from './activityData';

// Helper component for each tab's icon and label
const ActivityItem = ({ icon, label, count, isSelected }) => (
  <Flex alignItems="center" color={isSelected ? '#1A5BF6' : 'inherit'}>
    {icon && <icon.type boxSize={5} mr={2} />}
    <span>{label}</span>
    {count > 0 && <span> ({count})</span>}
  </Flex>
);

ActivityItem.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  isSelected: PropTypes.bool.isRequired,
};

const ITEMS_PER_PAGE = 5; // Number of items per page for pagination

const DealActivity = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => setCurrentPage(page);

  // Filter activities by type
  const filterActivities = (type) => {
    if (type === 'all') {
      return activities;
    }
    return activities.filter(activity => activity.type === type);
  };

  // Calculate the number of activities for each type
  const activityCounts = {
    all: activities.length,
    meeting: activities.filter(activity => activity.type === 'meeting').length,
    email: activities.filter(activity => activity.type === 'email').length,
    message: activities.filter(activity => activity.type === 'message').length,
    task: activities.filter(activity => activity.type === 'task').length,
    file: activities.filter(activity => activity.type === 'file').length,
  };

  const tabItems = [
    { icon: PiPulse, label: "All", type: "all" },
    { icon: MdEvent, label: "Meetings", type: "meeting" },
    { icon: MdEmail, label: "Emails", type: "email" },
    { icon: MdMessage, label: "Messages", type: "message" },
    { icon: MdAssignment, label: "Tasks", type: "task" },
    { icon: MdInsertDriveFile, label: "Files", type: "file" },
  ];

  const paginatedActivities = (activities) => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return activities.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const totalPages = Math.ceil(filterActivities(tabItems[selectedTab].type).length / ITEMS_PER_PAGE);

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mt={1}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <span style={{ fontSize: 'lg', fontWeight: 'bold' }}>Activity</span>
      </Flex>

      <Tabs
        variant="line"
        colorScheme="blue"
        onChange={(index) => {
          setSelectedTab(index);
          setCurrentPage(1); // Reset to first page when tab changes
        }}
      >
        <TabList>
          {tabItems.map((item, index) => (
            <Tab
              key={item.label}
              _selected={{ color: '#1A5BF6', fontWeight: 'bold', borderBottomColor: '#1A5BF6' }}
              _focus={{ boxShadow: 'none', outline: 'none' }}
            >
              <ActivityItem
                icon={<item.icon />}
                label={item.label}
                count={activityCounts[item.type]}
                isSelected={selectedTab === index}
              />
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabItems.map((item) => {
            const filteredActivities = filterActivities(item.type);
            const paginated = paginatedActivities(filteredActivities);

            return (
              <TabPanel key={item.type}>
                {/* Pass paginated activities to ActivityTimeline */}
                <ActivityTimeline activities={paginated} /> {/* Correct prop passing */}

                {/* Pagination Controls */}
                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </Flex>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DealActivity;
