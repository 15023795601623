const accountData = [
  {
    id: "A001",
    name: "PeopleFirst",
    location: "Atlanta, GA",
    linkedin: "View Company",
    champion: "Linda Martinez",
    last_activity_date: "2024-10-01",
    enrichment: {
      "Company name": "DYNE Technologies Inc.",
      "Currently using a CRM": null,
      "Does the company primarily serve businesses (B2B), consumers (B2C), or it's a marketplace of business and consumers (B2B2C)":
        null,
      "How many people handle GTM operations": null,
      Industry: "Human Resources",
    },
  },
  {
    id: "A002",
    name: "FinanceCorp",
    industry: "Finance",
    location: "New York, NY",
    linkedin: "View Company",
    champion: "David Wilson",
    last_activity_date: "2024-09-15",
    enrichment: {
      "Company name": "FinanceCorp",
      "Currently using a CRM": null,
      "Does the company primarily serve businesses (B2B), consumers (B2C), or it's a marketplace of business and consumers (B2B2C)":
        null,
      "How many people handle GTM operations": null,
      Industry: "Finance",
    },
  },
  {
    id: "A003",
    name: "Data Insights",
    location: "San Francisco, CA",
    linkedin: "View Company",
    champion: "Jessica White",
    last_activity_date: "2024-09-30",
    enrichment: {
      "Company name": "Data Insights",
      "Currently using a CRM": null,
      "Does the company primarily serve businesses (B2B), consumers (B2C), or it's a marketplace of business and consumers (B2B2C)":
        null,
      "How many people handle GTM operations": null,
      Industry: "Data Analytics",
    },
  },
  {
    id: "A004",
    name: "MarketWise",
    industry: "Marketing",
    location: "Chicago, IL",
    linkedin: "View Company",
    champion: "Michael Green",
    last_activity_date: "2024-10-05",
    enrichment: {
      "Company name": "MarketWise",
      "Currently using a CRM": null,
      "Does the company primarily serve businesses (B2B), consumers (B2C), or it's a marketplace of business and consumers (B2B2C)":
        null,
      "How many people handle GTM operations": null,
      Industry: "Marketing",
    },
  },
  {
    id: "A005",
    name: "Innovatech",
    industry: "Technology",
    location: "Boston, MA",
    linkedin: "View Company",
    champion: "Alice Johnson",
    last_activity_date: "2024-09-20",
    enrichment: {
      "Company name": "Innovatech",
      "Currently using a CRM": null,
      "Does the company primarily serve businesses (B2B), consumers (B2C), or it's a marketplace of business and consumers (B2B2C)":
        null,
      "How many people handle GTM operations": null,
      Industry: "Technology",
    },
  },
  {
    id: "A006",
    name: "Tech Solutions Inc.",
    industry: "Software Development",
    location: "Seattle, WA",
    linkedin: "View Company",
    champion: "John Doe",
    last_activity_date: "2024-09-10",
    enrichment: {
      "Company name": "Tech Solutions Inc.",
      "Currently using a CRM": null,
      "Does the company primarily serve businesses (B2B), consumers (B2C), or it's a marketplace of business and consumers (B2B2C)":
        null,
      "How many people handle GTM operations": null,
      Industry: "Software Development",
    },
  },
];

export default accountData;
