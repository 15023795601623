// src/components/DashboardControls.js

import React from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

/**
 * DashboardControls Component
 * Renders the control dropdowns for selecting time ranges and representatives.
 *
 * @param {string} timeRange - Currently selected time range.
 * @param {Function} setTimeRange - Function to update the time range.
 * @param {string} selectedRep - Currently selected representative.
 * @param {Function} setSelectedRep - Function to update the representative.
 * @param {Array} timeRangesData - Array of time range objects from mockData.
 */
const DashboardControls = ({
  timeRange,
  setTimeRange,
  selectedRep,
  setSelectedRep,
  reps,
  timeRangesData,
}) => {
  // Theme Colors
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("white", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const buttonColor = useColorModeValue("gray.500", "gray.300");

  // Extract available time ranges from the provided data
  const timeRanges = timeRangesData.map((tr) => tr.range);
  const members = reps || [];

  if (!members || members.length === 1) {
    console.error("No members found for the selected time range", timeRange);
    return;
  }
  return (
    <Flex px="20px" mb="20px" gap="4" flexWrap="wrap">
      {/* Time Range Dropdown */}
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          bg={bgColor}
          borderWidth="1px"
          borderColor={borderColor}
          color={buttonColor}
          _hover={{ bg: selectedBgColor }}
          _active={{ bg: selectedBgColor }}
          borderRadius="md"
          minW="120px"
        >
          {capitalizeFirstLetter(timeRange)}
        </MenuButton>
        <MenuList>
          {timeRanges.map((range) => (
            <MenuItem
              key={range}
              onClick={() => setTimeRange(range)}
              bg={timeRange === range ? selectedBgColor : "transparent"}
              _hover={{ bg: selectedBgColor }}
              fontWeight={timeRange === range ? "semibold" : "normal"}
            >
              {capitalizeFirstLetter(range)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {/* Representative Dropdown */}
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          bg={bgColor}
          borderWidth="1px"
          borderColor={borderColor}
          color={buttonColor}
          _hover={{ bg: selectedBgColor }}
          _active={{ bg: selectedBgColor }}
          borderRadius="md"
          minW="120px"
        >
          {selectedRep.name === "team"
            ? "Team"
            : capitalizeFirstLetter(selectedRep.name)}
        </MenuButton>
        <MenuList>
          {/* Team Option */}
          <MenuItem
            key="team"
            onClick={() => setSelectedRep({ name: "team" })}
            bg={selectedRep.name === "team" ? selectedBgColor : "transparent"}
            _hover={{ bg: selectedBgColor }}
            fontWeight={selectedRep.name === "team" ? "semibold" : "normal"}
          >
            Team
          </MenuItem>
          {/* Divider */}
          {members.length > 0 && <hr style={{ margin: "8px 0" }} />}
          {/* Member Options */}
          {members.map((member) => (
            <MenuItem
              key={member.id}
              onClick={(e) => {
                e.preventDefault(); // Prevent default behavior
                setSelectedRep(member);
              }}
              bg={
                selectedRep.name === member.name.toLowerCase()
                  ? selectedBgColor
                  : "transparent"
              }
              _hover={{ bg: selectedBgColor }}
              fontWeight={
                selectedRep.name === member.name.toLowerCase()
                  ? "semibold"
                  : "normal"
              }
            >
              {capitalizeFirstLetter(member.name)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

/**
 * Utility function to capitalize the first letter of a string
 * @param {string} string - The string to capitalize
 * @returns {string} - Capitalized string
 */
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default DashboardControls;
