// ContactData.js

const contactData = [
  {
    id: "C001",
    name: "Linda Martinez",
    title: "Human Resources Manager",
    crm_account_name: "PeopleFirst",
    email: "linda.martinez@peoplefirst.com",
    phone: "(678) 901-2345",
    linkedin: "View Profile",
  },
  {
    id: "C002",
    name: "David Wilson",
    title: "Chief Financial Officer",
    crm_account_name: "FinanceCorp",
    email: "david.wilson@financecorp.com",
    phone: "(567) 890-1234",
    linkedin: "View Profile",
  },
  {
    id: "C003",
    name: "Jessica White",
    title: "Data Scientist",
    crm_account_name: "Data Insights",
    email: "jessica.white@datainsights.com",
    phone: "(456) 789-0123",
    linkedin: "View Profile",
  },
  {
    id: "C004",
    name: "Michael Green",
    title: "Marketing Director",
    crm_account_name: "MarketWise",
    email: "michael.green@marketwise.com",
    phone: "(345) 678-9012",
    linkedin: "View Profile",
  },
  {
    id: "C005",
    name: "Alice Johnson",
    title: "Product Manager",
    crm_account_name: "Innovatech",
    email: "alice.johnson@innovatech.com",
    phone: "(234) 567-8901",
    linkedin: "View Profile",
  },
  {
    id: "C006",
    name: "John Doe",
    title: "Software Engineer",
    crm_account_name: "Tech Solutions Inc.",
    email: "john.doe@techsolutions.com",
    phone: "(123) 456-7890",
    linkedin: "View Profile",
  },
];

export default contactData;
