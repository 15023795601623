// CallRecapData.js

const callRecapData = [
  {
    "meetingTitle": "Quest Demo w/ Brent from Flair",
    "date": "2023-03-25T09:00:00",
    "duration": "01:02:12",
    "attendees": [
      {
        "name": "Kimberly Carlisle",
        "email": "kimberly@quest.io",
        "title": "Sales Representative",
        "company": "Quest",
        "talkTimePercentage": 62
      },
      {
        "name": "Brent Kimmel",
        "email": "brent@flair.com",
        "title": "Potential Client",
        "company": "Flair",
        "talkTimePercentage": 38
      }
    ],
    "meetingOutcome": "Brent from Flair feels that Quest is a good solution for his needs. Brent will trial Quest with his sales reps.",
    "nextSteps": [
      {
        "action": "Kimberly to schedule a meeting with Brent for Monday April 4th at 2 PM PT to check in on the Quest trial",
        "timestamp": "51:04"
      },
      {
        "action": "Kimberly to send Brent a video walkthrough on getting started with Quest",
        "timestamp": "52:32"
      },
      {
        "action": "Brent to trial the Quest product and invite members of his team",
        "timestamp": "57:41"
      }
    ],
    "customerNeeds": [
      {
        "need": "Brent and his team find it challenging to capture information from customer calls and share it with his marketing team",
        "timestamp": "21:24"
      },
      {
        "need": "Brent wants to understand how his reps are doing to provide coaching/feedback/training",
        "timestamp": "22:32"
      }
    ],
    "topics": [
      "Small talk",
      "Differentiation",
      "Objections",
      "Integrations",
      "3 Pillars",
      "Discovery",
      "Customer Success"
    ]
  },
  {
    "meetingTitle": "Revolutionizing Work with Lysto",
    "date": "2024-08-21T10:00:00",
    "duration": "00:30:00",
    "attendees": [
      {
        "name": "Zoe Quantum",
        "email": "zoe@lysto.io",
        "title": "Chief Innovation Officer",
        "company": "Lysto",
        "talkTimePercentage": 55
      },
      {
        "name": "Max Stellar",
        "email": "max@techfuture.com",
        "title": "Visionary Product Architect",
        "company": "TechFuture",
        "talkTimePercentage": 45
      }
    ],
    "meetingOutcome": "Max is intrigued by Lysto's capabilities and sees potential for revolutionizing their product development process.",
    "nextSteps": [
      {
        "action": "Zoe to send Max a personalized Lysto demo tailored for innovation sprints",
        "timestamp": "25:30"
      },
      {
        "action": "Max to schedule a Lysto trial run with his product team next week",
        "timestamp": "27:15"
      },
      {
        "action": "Follow-up meeting scheduled for next month to discuss integration possibilities",
        "timestamp": "29:00"
      }
    ],
    "customerNeeds": [
      {
        "need": "Max needs a tool to capture and synthesize insights from innovation sprints and customer feedback sessions",
        "timestamp": "10:45"
      },
      {
        "need": "TechFuture is looking for ways to streamline communication between product, sales, and customer success teams",
        "timestamp": "15:20"
      }
    ],
    "topics": [
      "Future of work",
      "AI-driven insights",
      "Team collaboration",
      "Innovation processes",
      "Intergalactic customer success",
      "Quantum computing applications"
    ],
  }
];

export default callRecapData;