// auth-context.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const getUserAndOrgMetadata = async () => {
      if (isAuthenticated) {
        const { org_id, account_ID, flow_ID } = await getIdTokenClaims();
        setUserData({
          ...user,
          org_id,
          account_ID,
          flow_ID,
        });
      }
    };

    getUserAndOrgMetadata();
  }, [isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims]);

  return (
    <AuthContext.Provider value={userData}>{children}</AuthContext.Provider>
  );
};

export const useAuthData = () => useContext(AuthContext);
