import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import meetingAnalysisData from "views/lysto/MyTasks/meetingData";
import EmailDraftModal from "views/lysto/MyTasks/EmailDraftModal";
import EmailModal from "views/lysto/MyTasks/EmailModal";
import currentConversationData from "./currentConversationData";

const TaskModal = ({ isOpen, onClose, selectedCase, accountID, onDelete }) => {
  const [meetingDataText, setMeetingDataText] = useState({});
  const toast = useToast();

  useEffect(() => {
    let initialData = {};
    if (selectedCase?.type === "HubSpot") {
      initialData = meetingAnalysisData[1];
    } else if (selectedCase?.type === "Slack") {
      initialData = meetingAnalysisData[2];
    } else if (selectedCase?.type === "Email") {
      initialData = meetingAnalysisData[3];
    }
    setMeetingDataText(initialData);
  }, [selectedCase]);

  const formatKey = (key) => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleContentChange = (key, value) => {
    setMeetingDataText((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const AutoResizingTextarea = ({ value, onChange, ...props }) => {
    const textareaRef = React.useRef(null);

    const resizeTextarea = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height =
          textareaRef.current.scrollHeight + "px";
      }
    };

    useEffect(() => {
      resizeTextarea();
    }, [value]);

    const handleChange = (e) => {
      onChange(e);
      resizeTextarea();
    };

    return (
      <Textarea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        {...props}
      />
    );
  };

  const handleDelete = useCallback(() => {
    onDelete(selectedCase.id);
    onClose();
  }, [selectedCase, onDelete, onClose]);

  const renderEditableContent = () => {
    return Object.entries(meetingDataText).map(([key, value]) => (
      <Box key={key} mb={4}>
        <Text fontWeight="bold" mb={2}>
          {formatKey(key)}:
        </Text>
        <AutoResizingTextarea
          value={
            typeof value === "object" ? JSON.stringify(value, null, 2) : value
          }
          onChange={(e) => handleContentChange(key, e.target.value)}
          minHeight="100px"
          fontSize="md"
        />
      </Box>
    ));
  };

  const handlePushUpdate = async () => {
    try {
      const updatedMeetingData = Object.fromEntries(
        Object.entries(meetingDataText).map(([key, value]) => {
          try {
            return [key, JSON.parse(value)];
          } catch {
            return [key, value];
          }
        })
      );
      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "http://localhost:8080"
          : "https://lysto-be-tf.azurewebsites.net";
      const response = await axios.post(
        `${target}/api/accounts/${accountID}/users/${selectedCase.user_id}/hubspot/update`,
        updatedMeetingData
      );

      if (response.status === 200) {
        toast({
          title: "Update successful",
          description: "The data has been successfully pushed to Hubspot.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // TODO Hanlde Remove (It is what handleDelete does for now)
        onClose();
      } else {
        throw new Error("API call failed");
      }
    } catch (error) {
      console.error("Error pushing update:", error);
      // TODO: Remove
      handleDelete();
      console.log("Need to handle error");
      // TODO ADD:
      // toast({
      //   title: "Update failed",
      //   description:
      //     "There was an error pushing the data to Hubspot. Please ensure the text is valid JSON.",
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
    }
  };

  if (!selectedCase) {
    return;
  }

  if (selectedCase.type === "EmailDraft") {
    return (
      <EmailDraftModal
        isOpen={isOpen}
        onClose={onClose}
        selectedCase={selectedCase}
        accountID={accountID}
        onDelete={onDelete}
      />
    );
  }

  if (selectedCase.type === "Email") {
    return (
      <EmailModal
        currentConversation={currentConversationData}
        sentMessages={currentConversationData}
        selectedThreadData={"{status: 'sent'}"}
        selectedThreadId={selectedCase.id}
        isConversationOpen={isOpen}
        isOpen={isOpen}
        onClose={onClose}
        selectedCase={selectedCase}
        accountID={accountID}
        onDelete={onDelete}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      closeOnOverlayClick={true}
      returnFocusOnClose={true}
    >
      <ModalOverlay />
      <ModalContent maxWidth="60vw" maxHeight="90vh">
        <ModalHeader>{selectedCase?.type} Update</ModalHeader>
        <Flex position="absolute" right="8px" top="8px">
          <ModalCloseButton onClick={onClose} position="static" />
        </Flex>
        <ModalBody>
          <Box mb={4}>
            <Box
              height="60vh"
              overflow="auto"
              fontSize="md"
              whiteSpace="pre-wrap"
            >
              {renderEditableContent()}
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            borderRadius="md"
            variant="ghost"
            onClick={onClose}
            border="1px solid gray" // Adding a green border
          >
            Save
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            borderRadius="md"
            onClick={handlePushUpdate}
          >
            Push Update to {selectedCase.type}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TaskModal;
