import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Avatar,
  Text,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";

const UserProfileModal = ({ isOpen, onClose }) => {
  const userData = useAuthData();
  const { logout } = useAuth0();
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="start">
            <Avatar
              size="xl"
              name={userData?.name}
              bg="#1A5BF6"
              color="white"
            />
            <Text fontSize="lg" fontWeight="bold" color={textColor}>
              {userData?.name}
            </Text>
            <Text fontSize="md" color={textColor}>
              {userData?.email}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={handleLogout}>
            Log out
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserProfileModal;
