// Chakra imports
import {
  //Box,
  Text,
  VStack,
  HStack,
  Tag,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";

export default function CampaignCard({
  title,
  prompt,
  customizations,
  sendStatus,
  //sendQuantity,
}) {
  const textColor = useColorModeValue("black", "white"); // Updated to make title text black
  const textColorSecondary = "b_gray.700"; // Made text darker as per previous request
  const tagColors = ["blue", "green", "pink", "orange", "purple"]; // define more colors if needed

  return (
    <Card p={4}>
      <VStack align="start" spacing={4} flex="1">
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          {title}
        </Text>
        <HStack spacing={2}>
          {customizations.map((customization, index) => (
            <Tag key={index} colorScheme={tagColors[index % tagColors.length]}>
              {customization}
            </Tag>
          ))}
        </HStack>
        <Text color={textColorSecondary} fontStyle="italic">
          &ldquo;{prompt}&rdquo;
        </Text>
        <Flex w="100%" justify="space-between" align="center">
          <HStack spacing={4}>
            <Text color={textColorSecondary} fontWeight="bold">
              {" "}
              {/* Updated to make send status bold */}
              {sendStatus}
            </Text>
          </HStack>
          <Button size="sm" variant="action">
            {" "}
            {/* Updated to match provided button styling */}
            See More
          </Button>
        </Flex>
      </VStack>
    </Card>
  );
}
