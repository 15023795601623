import React, { useState } from 'react';
import { Box, HStack, VStack, Text, Tag, IconButton, Input } from '@chakra-ui/react';
import { IoMdCreate } from 'react-icons/io';
import { FaCheckCircle } from 'react-icons/fa';

const StageDetails = ({ stage, stageIndex, currentStageIndex }) => {
  const [editMode, setEditMode] = useState(false);
  const [answers, setAnswers] = useState({});

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleAnswerChange = (questionIndex, value) => {
    const newAnswers = { ...answers };
    newAnswers[`${stageIndex}-${questionIndex}`] = value;
    setAnswers(newAnswers);
  };

  const isQuestionCompleted = (questionIndex) => {
    const answer = answers[`${stageIndex}-${questionIndex}`] || stage.questions[questionIndex].answer;
    return answer && answer.trim() !== '';
  };

  return (
    <Box borderWidth="1px" borderRadius="md" p={4} bg="white">
      <HStack justifyContent="space-between">
        <Text fontSize="md" fontWeight="semibold">
          {stage.name} {stageIndex === currentStageIndex && '(Current)'}
        </Text>
        <IconButton
          icon={<IoMdCreate />}
          aria-label="Edit"
          size="sm"
          onClick={handleEditClick}
          variant="outline"
          borderRadius="full"
        />
      </HStack>

      {stage.qualification && (
        <VStack align="start" spacing={2} mt={4}>
          {stage.questions.map((question, qIndex) => (
            <Box
              key={qIndex}
              borderWidth="1px"
              borderRadius="md"
              p={3}
              bg="white"
              w="100%"
            >
              <HStack justifyContent="space-between">
                <Text>{question.text}</Text>
                <Tag 
                  size="sm" 
                  colorScheme={isQuestionCompleted(qIndex) ? 'green' : question.required ? 'red' : 'blue'}
                  display="flex"
                  alignItems="center"
                >
                  {isQuestionCompleted(qIndex) ? (
                    <>
                      <FaCheckCircle style={{ marginRight: '4px' }} />
                      Completed
                    </>
                  ) : (
                    question.required ? 'Required' : 'Optional'
                  )}
                </Tag>
              </HStack>

              <Box mt={2}>
                {editMode ? (
                  <Input
                    value={answers[`${stageIndex}-${qIndex}`] || question.answer || ''}
                    onChange={(e) => handleAnswerChange(qIndex, e.target.value)}
                    placeholder="Enter answer"
                  />
                ) : (
                  <Text color="gray.700" fontSize="sm">
                    Answer: {answers[`${stageIndex}-${qIndex}`] || question.answer || 'Not answered'}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default StageDetails;