import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, Text, Button, 
  Flex, useTheme, VStack
} from "@chakra-ui/react";
import React from 'react';
import Quill from 'react-quill';  // Importing Quill editor
import 'react-quill/dist/quill.snow.css';  // Importing Quill editor styles

function ReviewTemplateModal({ isOpen, onClose, campaignTitle, campaignDescription }) {
  const theme = useTheme();
  const borderColorEditor = theme.colors.gray[200];
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="white">
      <style>
          {`
                      .ql-container, .ql-toolbar {
                          border-color: ${borderColorEditor} !important;
                      }
                  `}
        </style>
        <ModalHeader textAlign="center" pb={4}>
          {campaignTitle}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8} align="stretch">  {/* Updated spacing property */}
            <Text fontSize="sm" textAlign="center">
              {campaignDescription}
            </Text>
            <Quill 
              theme="snow"  // Set Quill editor theme
              style={{ height: '100%', overflowY: 'scroll', maxHeight: "50vh" }}
            />
            </VStack>
            <Flex mt={4} mb={2} justifyContent="center">
              <Button
                variant="solid" 
                bg="b_red.200" 
                color="white" 
                justifyContent="center"
                _hover={{ bg: "b_orange.200" }}
              >
                Customize Template
              </Button>
            </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ReviewTemplateModal;
