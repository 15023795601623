import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { ArrowLeft } from "lucide-react";
import NotesTabContent from "./NotesTabContent";
import TranscriptTabContent from "./TranscriptTabContent";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import AudioPlayer from "./AudioPlayer";
import { GetUserTimezone } from "./utils";
import { DateTime } from "luxon";

const MeetingAnalysis = () => {
  const history = useHistory();
  const { meetingID } = useParams();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const [meetingAnalysis, setMeetingAnalysis] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchMeetingData = async () => {
      if (!accountID || !meetingID) return;
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";
        const response = await axios.get(
          `${target}/api/accounts/${accountID}/events/${meetingID}/analysis`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setMeetingAnalysis(response.data.eventAnalysis);
      } catch (err) {
        console.error("Error fetching meeting data:", err);
        // setError("Failed to fetch meeting data. Please try again later.");
      } finally {
        // setIsLoading(false);
      }
    };

    fetchMeetingData();
  }, [accountID, meetingID, getAccessTokenSilently]);

  const handleBack = () => {
    history.push("/admin/meetings");
  };

  if (!meetingAnalysis) {
    return <div>Loading...</div>;
  }

  // Parse the meeting date directly in the event timezone
  const eventMeetingDate = DateTime.fromISO(meetingAnalysis.date, {
    zone: meetingAnalysis.timezone,
  });

  // Only convert if user timezone is different
  const displayDate = eventMeetingDate.setZone(GetUserTimezone());

  // Format the date string
  const formattedDate = displayDate.toFormat("MMM dd, yyyy h:mm a");

  return (
    <VStack align="stretch" spacing={8} pt={20}>
      <Flex align="center">
        <IconButton
          icon={<ArrowLeft />}
          aria-label="Go back"
          onClick={handleBack}
          variant="ghost"
          size="lg"
          mr={4}
        />
        <VStack align="start" spacing={2}>
          <Text fontSize="lg" fontWeight="bold" color="#57595E">
            {meetingAnalysis.meetingTitle}
          </Text>
          <Text color="#57595E">
            {formattedDate} • {meetingAnalysis.attendees.length} attendees
          </Text>
        </VStack>
      </Flex>

      <Flex>
        <Box flex={1} mr={8}>
          <Tabs variant="unstyled">
            <TabList>
              {["Overview", "Transcript", "Smart Tags", "Comments"].map(
                (tab, index) => (
                  <Tab
                    key={index}
                    _selected={{
                      color: "#1A5BF6",
                      fontWeight: "bold",
                      position: "relative",
                      _after: {
                        content: '""',
                        position: "absolute",
                        bottom: "-2px",
                        left: 0,
                        right: 0,
                        height: "2px",
                        backgroundColor: "#1A5BF6",
                        borderRadius: "2px",
                        width: "100%", // Ensures the underline spans the full width of the tab text
                      },
                    }}
                    px={4} // Add horizontal padding to the tabs
                    py={2} // Add vertical padding to the tabs
                  >
                    {tab}
                  </Tab>
                )
              )}
            </TabList>

            <TabPanels>
              <TabPanel px={0}>
                {" "}
                {/* Remove horizontal padding from TabPanel */}
                <NotesTabContent
                  meetingOutcome={meetingAnalysis.meetingOutcome}
                  nextSteps={meetingAnalysis.nextSteps}
                  customerNeeds={meetingAnalysis.analysis.meddic.impliedPain}
                />
              </TabPanel>
              <TabPanel px={0}>
                <TranscriptTabContent
                  accountID={accountID}
                  meetingID={meetingID}
                />
              </TabPanel>
              <TabPanel px={0}>
                <Text>Smart Tags content goes here</Text>
              </TabPanel>
              <TabPanel px={0}>
                <Text>Comments goes here</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Box flex={1}>
          <Box
            bg="white"
            borderRadius="lg"
            p={4}
            mt={14}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AudioPlayer accountID={accountID} meetingID={meetingID} />
          </Box>
        </Box>
      </Flex>
    </VStack>
  );
};

export default MeetingAnalysis;
