import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const CallbackComponent = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push("/admin");
      } else if (error) {
        // Handle or display the error, or redirect to an error page.
        console.error("Authentication error:", error);
        history.push("auth/logout");
      }
    }
  }, [isLoading, isAuthenticated, error, history]);

  return <div></div>;
};

export default CallbackComponent;
