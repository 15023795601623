// LiveActivityTimeline.js

import React from 'react';
import { VStack } from '@chakra-ui/react';
import LiveActivityItem from './LiveActivityItem';

const LiveActivityTimeline = ({ activities }) => {
  return (
    <VStack spacing={4} align="stretch">
      {activities.map((activity) => (
        <LiveActivityItem key={activity.id} activity={activity} />
      ))}
    </VStack>
  );
};

export default LiveActivityTimeline;