// GongTaskCard.js

import React from "react";
import { Box, VStack, Text, Link, Icon } from "@chakra-ui/react";
import { FaLink } from "react-icons/fa";

const GongTaskCard = ({ recordingLink, callDate, duration, callBrief, title, userName }) => {
  return (
    <Box p={4} bg="gray.100" borderRadius="md" boxShadow="sm">
      <VStack align="start" spacing={2}>
        <Text fontWeight="bold" fontSize="lg">{title}</Text>
        <Text>
          <strong>User:</strong> {userName}
        </Text>
        {recordingLink && (
          <Text>
            <strong>Recording:</strong> <Link href={recordingLink} color="blue.500" isExternal>
              <Icon as={FaLink} mr={1} />
              View Recording
            </Link>
          </Text>
        )}
        <Text>
          <strong>Call Date:</strong> {callDate}
        </Text>
        <Text>
          <strong>Duration:</strong> {duration}
        </Text>
        <Text>
          <strong>Call Brief:</strong> {callBrief}
        </Text>
      </VStack>
    </Box>
  );
};

export default GongTaskCard;
