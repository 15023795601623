// CalendlyTaskCard.js

import React from 'react';
import { Box, VStack, Text, Link } from '@chakra-ui/react';

const CalendlyTaskCard = ({ activity }) => {
  const classification = activity.classification || {};
  const { meeting_link, meeting_form } = classification;
  const {
    customer_name,
    email,
    requested_time,
    additional_notes,
  } = meeting_form || {};

  return (
    <Box
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      width="100%"
      border="1px solid"
      borderColor="gray.200"
    >
      <VStack align="stretch" spacing={3}>
        {meeting_link && (
          <Text>
            <strong>Meeting Link:</strong>{' '}
            <Link href={meeting_link} color="blue.500" isExternal>
              {meeting_link}
            </Link>
          </Text>
        )}
        {customer_name && (
          <Text>
            <strong>Customer Name:</strong> {customer_name}
          </Text>
        )}
        {email && (
          <Text>
            <strong>Email:</strong> {email}
          </Text>
        )}
        {requested_time && (
          <Text>
            <strong>Requested Time:</strong>{' '}
            {new Date(requested_time).toLocaleString()}
          </Text>
        )}
        {additional_notes && (
          <Text>
            <strong>Additional Notes:</strong> {additional_notes}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default CalendlyTaskCard;
