import React from "react";
import { Flex, IconButton, Heading, useColorModeValue } from "@chakra-ui/react";
import { ArrowLeft } from "lucide-react";

const GoBackHeader = ({ onGoBack, title }) => {
  const textColor = useColorModeValue("#56595E", "white");

  return (
    <Flex align="center" mb={4} mt={16}>
      <IconButton
        icon={<ArrowLeft />}
        aria-label="Go back"
        onClick={onGoBack}
        mr={4}
        variant="ghost"
        color={textColor}
      />
      <Heading as="h1" size="md" color={textColor}>
        {title}
      </Heading>
    </Flex>
  );
};

export default GoBackHeader;
