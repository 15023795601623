import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthData } from "auth-context";
import { useHistory } from "react-router-dom";
import ConversationTable from "views/admin/dataTables/components/ConversationTable";
import contactColumns from "./ContactColumns";
import contactData from "./ContactData";

export default function Contacts() {
  const [contacts, setContacts] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const history = useHistory();

  const handleContactClick = (contact) => {
    history.push(`/admin/contacts/${contact.id}`);
  };

  const updatedContactColumns = contactColumns.map((column) => {
    if (column.Header === "Name") {
      return {
        ...column,
        Cell: ({ row }) => (
          <Box
            onClick={() => handleContactClick(row.original)}
            cursor="pointer"
          >
            {column.Cell({ value: row.original.name })}
          </Box>
        ),
      };
    }
    if (column.Header === "Email") {
      return {
        ...column,
        Cell: ({ value }) => (
          <Box cursor="pointer" color="blue.500">
            {value}
          </Box>
        ),
      };
    }
    return column;
  });

  useEffect(() => {
    const fetchContacts = async () => {
      if (!accountID) return;
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";
        const response = await axios.get(
          `${target}/api/accounts/${accountID}/contacts`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              contact_type: "Lead",
              page_number: 1,
              items_per_page: 10, 
            },
          }
        );
        if (response.data){
          setContacts(response.data);
        }
      } catch (err) {
        setContacts(contactData);
      }
    };

    fetchContacts();
  }, [accountID, getAccessTokenSilently]);

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }}>
      <Box borderRadius="lg" bg="white" boxShadow="sm">
        <ConversationTable
          columnsData={updatedContactColumns}
          tableData={contacts}
          title="All Contacts"
        />
      </Box>
    </Box>
  );
}