import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Box,
  Text,
  Flex
} from "@chakra-ui/react";
import React, { useState } from "react";

function NewWorkflowModal({ isOpen, onClose }) {
  const [selectedValue, setSelectedValue] = useState("1");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Workflow</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>Create a workflow from scratch or start with one of our templates.</Text>
          <Input placeholder="Workflow name" mb={4} />
          
          <Box mb={4}>
            <Text fontWeight="bold">New</Text>
            <RadioGroup onChange={setSelectedValue} value={selectedValue}>
              <Stack spacing={3}>
                <Box borderWidth="1px" borderRadius="md" p={4}>
                  <Radio value="1">
                    <Flex alignItems="center">
                      <Text fontWeight="bold" mr={2}>New workflow</Text>
                      <Text>Build your workflow from zero.</Text>
                    </Flex>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Box>
            <Text fontWeight="bold">Templates</Text>
            <RadioGroup onChange={setSelectedValue} value={selectedValue}>
              <Stack spacing={3}>
                <Box borderWidth="1px" borderRadius="md" p={4}>
                  <Radio value="2">
                    <Flex alignItems="center">
                      <Text fontWeight="bold" mr={2}>New power users current customer</Text>
                      <Text>Placeholder text explaining the workflow process</Text>
                    </Flex>
                  </Radio>
                </Box>
                <Box borderWidth="1px" borderRadius="md" p={4}>
                  <Radio value="3">
                    <Flex alignItems="center">
                      <Text fontWeight="bold" mr={2}>Risk to open opportunity</Text>
                      <Text>Placeholder text explaining the workflow process</Text>
                    </Flex>
                  </Radio>
                </Box>
                <Box borderWidth="1px" borderRadius="md" p={4}>
                  <Radio value="4">
                    <Flex alignItems="center">
                      <Text fontWeight="bold" mr={2}>High intent lead</Text>
                      <Text>Placeholder text explaining the workflow process</Text>
                    </Flex>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" ml={3} borderRadius="md"> {/* Less rounded */}
            Create workflow
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewWorkflowModal;
