import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
const AutoLogin = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      // If authenticated, redirect to /#/admin
      window.location.hash = "admin";
    } else {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <div>Redirecting to login...</div>;
};

export default AutoLogin;
