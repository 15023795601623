import React from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Select,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import featureRequest from "./featureRequest";

const NotesTabContent = ({ meetingOutcome, nextSteps, customerNeeds }) => {
  return (
    <VStack align="stretch" spacing={4}>
      <Box bg="white" borderRadius="lg" p={4}>
        <HStack>
          <Text>Template:</Text>
          <Select placeholder="Select template" size="sm" borderRadius="md">
            <option>Discovery Call</option>
            <option>Demo Call</option>
          </Select>
        </HStack>
      </Box>

      <Box bg="white" borderRadius="lg" p={4}>
        <Text fontWeight="bold" mb={2}>
          Meeting Outcome
        </Text>
        <Text fontSize="sm" pb={5}>
          {meetingOutcome}
        </Text>

        <Text fontWeight="bold" mb={2}>
          Next Steps
        </Text>
        <UnorderedList fontSize="sm" pb={5}>
          {nextSteps.map((step, index) => (
            <ListItem key={index}>{step}</ListItem>
          ))}
        </UnorderedList>
        <Text fontWeight="bold" mb={2}>
          Customer Needs
        </Text>
        <UnorderedList fontSize="sm" pb={5}>
          {customerNeeds.map((need, index) => (
            <ListItem key={index}>{need}</ListItem>
          ))}
        </UnorderedList>
        <Text fontWeight="bold" mb={2}>
          Features Requested
        </Text>
        <UnorderedList fontSize="sm" pb={5}>
          {featureRequest.map((need, index) => (
            <ListItem key={index}>{need}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    </VStack>
  );
};

export default NotesTabContent;
