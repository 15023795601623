import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  Switch,
  Button,
  Input,
  Checkbox,
  IconButton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, AddIcon, CloseIcon } from '@chakra-ui/icons';

const initialStages = [
  { name: 'MQL', qualification: false, questions: [], expanded: false },
  { name: 'SQL', qualification: false, questions: [], expanded: false },
  { name: 'Contract', qualification: false, questions: [], expanded: false },
  { name: 'Onboarding', qualification: false, questions: [], expanded: false },
  { name: 'Retention', qualification: false, questions: [], expanded: false },
];

const CRMSection = () => {
  const [stages, setStages] = useState(initialStages);

  const toggleQualification = (index) => {
    const newStages = [...stages];
    newStages[index].qualification = !newStages[index].qualification;
    setStages(newStages);
  };

  const toggleExpand = (index) => {
    const newStages = [...stages];
    newStages[index].expanded = !newStages[index].expanded;
    setStages(newStages);
  };

  const addQuestion = (stageIndex) => {
    const newStages = [...stages];
    newStages[stageIndex].questions.push({
      text: '',
      required: false,
      requirement: '',
    });
    setStages(newStages);
  };

  const updateQuestion = (stageIndex, questionIndex, field, value) => {
    const newStages = [...stages];
    newStages[stageIndex].questions[questionIndex][field] = value;
    setStages(newStages);
  };

  const removeQuestion = (stageIndex, questionIndex) => {
    const newStages = [...stages];
    newStages[stageIndex].questions.splice(questionIndex, 1);
    setStages(newStages);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="md">
      <Heading as="h3" size="md" mb={2}>
        CRM
      </Heading>
      <Text mb={6}>Configure AI-assisted qualification forms for each stage of your sales process.</Text>
      <VStack spacing={4} align="stretch">
        {stages.map((stage, stageIndex) => (
          <Box key={stage.name} borderWidth="1px" borderRadius="md" p={4}>
            <HStack justifyContent="space-between">
              <Text fontWeight="bold">{stage.name}</Text>
              <HStack>
                <Text>Qualification</Text>
                <Switch
                  isChecked={stage.qualification}
                  onChange={() => toggleQualification(stageIndex)}
                />
                <IconButton
                  icon={stage.expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => toggleExpand(stageIndex)}
                  variant="action"
                  size="sm"
                />
              </HStack>
            </HStack>
            {stage.expanded && stage.qualification && (
              <Box mt={4}>
                <VStack spacing={4} align="stretch">
                  {stage.questions.map((question, questionIndex) => (
                    <Box key={questionIndex} borderWidth="1px" borderRadius="md" p={4}>
                      <HStack justifyContent="space-between" mb={2}>
                        <Input
                          value={question.text}
                          onChange={(e) =>
                            updateQuestion(stageIndex, questionIndex, 'text', e.target.value)
                          }
                          placeholder="Enter question"
                        />
                        <IconButton
                          icon={<CloseIcon />}
                          onClick={() => removeQuestion(stageIndex, questionIndex)}
                          variant="action"
                          size="sm"
                        />
                      </HStack>
                      <HStack>
                        <Checkbox
                          isChecked={question.required}
                          onChange={(e) =>
                            updateQuestion(stageIndex, questionIndex, 'required', e.target.checked)
                          }
                        >
                          Required
                        </Checkbox>
                        <Input
                          value={question.requirement}
                          onChange={(e) =>
                            updateQuestion(stageIndex, questionIndex, 'requirement', e.target.value)
                          }
                          placeholder="Enter requirement (optional)"
                          isDisabled={!question.required}
                        />
                      </HStack>
                    </Box>
                  ))}
                  <Button onClick={() => addQuestion(stageIndex)} leftIcon={<AddIcon />} variant="action">
                    Add Question
                  </Button>
                </VStack>
              </Box>
            )}
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default CRMSection;