import React from "react";
import { Box, Text, Icon, Tooltip } from "@chakra-ui/react";
import { MdPerson, MdBusiness, MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt, faLink } from "@fortawesome/free-solid-svg-icons";

const HoverBox = ({ children, icon, hoverIcon }) => (
  <Tooltip
    label={
      hoverIcon === faExpandAlt ? "Click to view details" : "Click to open"
    }
  >
    <Box
      display="inline-flex"
      alignItems="center"
      borderRadius="lg"
      cursor="pointer"
      position="relative"
      transition="all 0.2s"
      role="group"
      _hover={{
        "& > *": { color: "#1A5BF6" },
      }}
    >
      <Box
        as="span"
        mr={2}
        position="relative"
        fontSize="20px"
        width="20px"
        height="20px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          transition="opacity 0.2s"
          opacity={1}
          _groupHover={{ opacity: 0 }}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          {icon === FontAwesomeIcon ? (
            <FontAwesomeIcon icon={icon} color="#748094" size="lg" />
          ) : (
            <Icon as={icon} color="#748094" boxSize="20px" />
          )}
        </Box>
        <Box
          as={FontAwesomeIcon}
          icon={hoverIcon}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          fontSize="20px"
          width="20px"
          height="20px"
          opacity={0}
          transition="opacity 0.2s"
          _groupHover={{ opacity: 1, color: "#1A5BF6" }}
          color="#748094"
        />
      </Box>
      {children}
    </Box>
  </Tooltip>
);

const contactColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => (
      <HoverBox icon={MdPerson} hoverIcon={faExpandAlt}>
        <Text fontSize="sm" fontWeight="700" _groupHover={{ color: "#1A5BF6" }}>
          {value}
        </Text>
      </HoverBox>
    ),
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value }) => (
      <Text fontSize="sm" fontWeight="normal" color="black">
        {value}
      </Text>
    ),
  },
  {
    Header: "Account",
    accessor: "crm_account_name",
    Cell: ({ value }) => (
      <HoverBox icon={MdBusiness} hoverIcon={faExpandAlt}>
        <Text fontSize="sm" fontWeight="700" _groupHover={{ color: "#1A5BF6" }}>
          {value}
        </Text>
      </HoverBox>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }) => (
      <HoverBox icon={MdEmail} hoverIcon={faLink}>
        <Text fontSize="sm" fontWeight="700" _groupHover={{ color: "#1A5BF6" }}>
          {value}
        </Text>
      </HoverBox>
    ),
  },
  {
    Header: "Phone",
    accessor: "phone",
    Cell: ({ value }) => (
      <Text fontSize="sm" fontWeight="normal" color="black">
        {value}
      </Text>
    ),
  },
  {
    Header: "LinkedIn",
    accessor: "linkedin",
    Cell: ({ value }) => (
      <HoverBox icon={FaLinkedin} hoverIcon={faLink}>
        <Text fontSize="sm" fontWeight="700" _groupHover={{ color: "#1A5BF6" }}>
          {value}
        </Text>
      </HoverBox>
    ),
  },
];

export default contactColumns;
