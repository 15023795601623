const dealStageData = [
  {
    "deal": {
      "stages": [
        {
          "name": "MQL",
          "qualification": true,
          "questions": [
            {
              "text": "Does the lead have a defined budget?",
              "required": true,
              "answered": true,
              "answer": "Yes, the budget is defined.",
              "requirement": "Must confirm the budget size"
            },
            {
              "text": "Is the lead's industry relevant?",
              "required": false,
              "answered": false,
              "answer": "",
              "requirement": ""
            }
          ]
        },
        {
          "name": "SQL",
          "qualification": true,
          "questions": [
            {
              "text": "Has the lead scheduled a meeting?",
              "required": true,
              "answered": false,
              "answer": "",
              "requirement": "Meeting must be confirmed within 2 weeks"
            }
          ]
        },
        {
          "name": "Contract",
          "qualification": false,
          "questions": []
        },
        {
          "name": "Onboarding",
          "qualification": true,
          "questions": [
            {
              "text": "Has the onboarding call been completed?",
              "required": true,
              "answered": false,
              "answer": "",
              "requirement": ""
            },
            {
              "text": "Is the first milestone reached?",
              "required": false,
              "answered": false,
              "answer": "",
              "requirement": ""
            }
          ]
        },
        {
          "name": "Retention",
          "qualification": false,
          "questions": []
        }
      ],
      "currentStageIndex": 1
    }
  }
];

export default dealStageData;
