const dealData = [
  {
    id: 239,
    account_id: 1,
    crm_account_id: 27,
    stage_id: 0,
    contacts: null,
    users: null,
    name: "DYNE Technologies Inc. Deal",
    amount: 0,
    created_at: "2024-10-11T15:18:04.035349Z",
    updated_at: "2024-10-11T15:18:04.035349Z",
    properties: {
      crm_account_name: "DYNE Technologies Inc.",
      website: "https://dyneapp.ca",
      linkedIn: "",
      city: "",
      state: "",
      country: "",
      address: "",
      size: 0,
      type: "",
      dealstage: "Lead",
      last_activity_date: "2024-10-11T16:19:11.449719Z",
    },
  },
  {
    id: 238,
    account_id: 1,
    crm_account_id: 2,
    stage_id: 1,
    contacts: null,
    users: null,
    name: "New Business Opportunity",
    amount: 500000,
    created_at: "2024-10-09T17:46:02.161394Z",
    updated_at: "2024-10-09T17:46:02.161394Z",
    properties: {
      crm_account_name: "Example CRM Account",
      website: "www.example.com",
      linkedIn: "www.linkedin.com/company/example",
      city: "New York",
      state: "NY",
      country: "USA",
      address: "123 Main St",
      size: 500,
      type: "Technology",
      dealstage: "MQL",
      last_activity_date: "2024-10-10T12:29:11.449719Z",
    },
  },
];

export default dealData;
