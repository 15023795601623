import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./auth-context";
import AutoLogin from "./views/auth/signIn/login";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PrivateRoute from "components/PrivateRoutes";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import CallbackComponent from "components/CallbackComponent";

// Stored in Github Secrets
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const target =
  process.env.REACT_APP_AUTH_CALLBACK_URL ||
  "https://localhost:3000/#/callback";

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={auth0_domain}
    clientId={auth0_client_id}
    authorizationParams={{
      audience: "https://lysto-be-tf.azurewebsites.net",
      redirect_uri: target,
      scope:
        "openid profile email read:current_user read:user_idp_tokens read:calendar",
    }}
  >
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <ThemeEditorProvider>
            <HashRouter>
              <Switch>
                <Route exact path="/" component={AutoLogin} />
                <Route path="/callback" component={CallbackComponent} />
                <Route path={`/auth`} component={AuthLayout} />
                <PrivateRoute path={`/admin`}>
                  <AdminLayout />
                </PrivateRoute>
                <Redirect from="/" to="/admin" />
              </Switch>
            </HashRouter>
          </ThemeEditorProvider>
        </React.StrictMode>
      </ChakraProvider>
    </AuthProvider>
  </Auth0Provider>
);
