import React from "react";
import { Box, Tooltip, Icon } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";

const HoverBox = ({ children, icon, hoverIcon, onClick }) => {
  const hasIcon = icon || hoverIcon;

  return (
    <Tooltip
      label={hoverIcon === faExpandAlt ? "Click to expand" : "Click to open"}
    >
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        _hover={{
          "& > *": { color: "#1A5BF6" },
        }}
        onClick={onClick} // Add this line
      >
        {hasIcon && (
          <Box
            as="span"
            mr={2}
            position="relative"
            fontSize="20px"
            width="20px"
            height="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {icon && (
              <Box
                transition="opacity 0.2s"
                opacity={1}
                _groupHover={{ opacity: 0 }}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                {icon === FontAwesomeIcon ? (
                  <FontAwesomeIcon icon={icon} color="#748094" size="lg" />
                ) : (
                  <Icon as={icon} color="#748094" boxSize="20px" />
                )}
              </Box>
            )}
            {hoverIcon && (
              <Box
                as={FontAwesomeIcon}
                icon={hoverIcon}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                fontSize="20px"
                width="20px"
                height="20px"
                opacity={0}
                transition="opacity 0.2s"
                _groupHover={{ opacity: 1, color: "#1A5BF6" }}
                color="#748094"
              />
            )}
          </Box>
        )}
        <Box
          as="span"
          display="inline-block"
          textAlign={hasIcon ? "left" : "left"}
          width={hasIcon ? "auto" : "100%"}
        >
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default HoverBox;
