import { useAuth0 } from "@auth0/auth0-react";
import { Route } from "react-router-dom";

const isLocalhost = window.location.hostname === "localhost";

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div></div>;
  }

  // If not authenticated and not on localhost, redirect to external site
  if (!isAuthenticated) {
    console.log("Redirecting to external site");
    window.location.href = isLocalhost
      ? "http://localhost:3000/#/auth/sign-in/"
      : "https://app.lysto.ai";
    return null;
  }

  return <Route {...rest} render={() => children} />;
};

export default PrivateRoute;
