// src/components/RichCards/LinkedInActivityCard.js

import React from 'react';
import { Box, VStack, Text, List, ListItem } from '@chakra-ui/react';

const LinkedInActivityCard = ({ activity }) => {
  const { activity_type, metadata, title, timestamp } = activity;
  const { details } = metadata || {};

  const renderContent = () => {
    switch (activity_type) {
      // Message-Related Activities
      case 'linkedin_new_message':
      case 'linkedin_incoming_messages':
      case 'linkedin_message_sent':
      case 'linkedin_messages_sent':
      case 'linkedin_got_reply':
      case 'linkedin_new_message_received': {
        const messageContent = details?.message_content || 'N/A';
        const sender = details?.sender || 'N/A';
        const receiver = details?.receiver || 'N/A';
        const activityTimestamp = details?.timestamp ? new Date(details.timestamp).toLocaleString() : 'N/A';

        return (
          <>
            <Text>
              <strong>Message Content:</strong> {messageContent}
            </Text>
            <Text>
              <strong>Sender:</strong> {sender}
            </Text>
            <Text>
              <strong>Receiver:</strong> {receiver}
            </Text>
            <Text>
              <strong>{activity_type === 'linkedin_new_message_received' ? 'Received Time' : 'Sent Time'}:</strong> {activityTimestamp}
            </Text>
          </>
        );
      }

      // Invite-Related Activities
      case 'linkedin_invite_sent':
      case 'linkedin_request_sent':
      case 'linkedin_invite_accepted':
      case 'linkedin_new_accepted_invites':
      case 'linkedin_invite_received': {
        const inviter = details?.inviter || 'N/A';
        const invitee = details?.invitee || 'N/A';
        const message = details?.message || 'N/A';
        const acceptanceTimestamp = details?.acceptance_timestamp ? new Date(details.acceptance_timestamp).toLocaleString() : 'N/A';

        return (
          <>
            <Text>
              <strong>Invitee:</strong> {invitee}
            </Text>
            <Text>
              <strong>Inviter:</strong> {inviter}
            </Text>
            <Text>
              <strong>Message:</strong> {message}
            </Text>
            {(activity_type === 'linkedin_invite_accepted' ||
              activity_type === 'linkedin_new_accepted_invites' ||
              activity_type === 'linkedin_invite_received') &&
              details?.acceptance_timestamp && (
                <Text>
                  <strong>Acceptance Time:</strong> {acceptanceTimestamp}
                </Text>
              )}
          </>
        );
      }

      // Profile Viewing Activities
      case 'linkedin_viewed_profiles': {
        const viewer = details?.viewer || 'Unknown User';
        const profilesViewed = details?.profiles_viewed || [];
        const viewedTimestamp = details?.timestamp ? new Date(details.timestamp).toLocaleString() : 'N/A';

        return (
          <>
            <Text>
              <strong>Viewer:</strong> {viewer}
            </Text>
            <Text>
              <strong>Profiles Viewed:</strong>
            </Text>
            <List styleType="disc" pl={4}>
              {Array.isArray(profilesViewed) && profilesViewed.length > 0 ? (
                profilesViewed.map((profile, index) => (
                  <ListItem key={index}>{profile}</ListItem>
                ))
              ) : (
                <ListItem>No profiles viewed.</ListItem>
              )}
            </List>
            <Text>
              <strong>Viewed Time:</strong> {viewedTimestamp}
            </Text>
          </>
        );
      }

      // Profile Reply Activities
      case 'linkedin_new_profiles_replied': {
        const profileName = details?.profile_name || 'N/A';
        const replyContent = details?.reply_content || 'N/A';
        const replier = details?.replier || 'N/A';
        const replyTimestamp = details?.reply_timestamp ? new Date(details.reply_timestamp).toLocaleString() : 'N/A';

        return (
          <>
            <Text>
              <strong>Profile Name:</strong> {profileName}
            </Text>
            <Text>
              <strong>Reply Content:</strong> {replyContent}
            </Text>
            <Text>
              <strong>Replier:</strong> {replier}
            </Text>
            <Text>
              <strong>Reply Time:</strong> {replyTimestamp}
            </Text>
          </>
        );
      }

      // InMail Activities
      case 'linkedin_inmails_sent': {
        const inmailSubject = details?.inmail_subject || 'N/A';
        const messageContent = details?.message_content || 'N/A';
        const recipients = details?.recipients || [];
        const sentTimestamp = details?.sent_timestamp ? new Date(details.sent_timestamp).toLocaleString() : 'N/A';

        return (
          <>
            <Text>
              <strong>InMail Subject:</strong> {inmailSubject}
            </Text>
            <Text>
              <strong>Message Content:</strong> {messageContent}
            </Text>
            <Text>
              <strong>Recipients:</strong>{' '}
              {Array.isArray(recipients) && recipients.length > 0
                ? recipients.join(', ')
                : 'N/A'}
            </Text>
            <Text>
              <strong>Sent Time:</strong> {sentTimestamp}
            </Text>
          </>
        );
      }

      default:
        return <Text>Details not available.</Text>;
    }
  };

  return (
    <Box
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      width="100%"
      border="1px solid"
      borderColor="gray.200"
    >
      <VStack align="stretch" spacing={3}>
        <Text>
          <strong>Title:</strong> {title || 'N/A'}
        </Text>
        <Text>
          <strong>Timestamp:</strong> {timestamp ? new Date(timestamp).toLocaleString() : 'N/A'}
        </Text>
        <Box>{renderContent()}</Box>
      </VStack>
    </Box>
  );
};

export default LinkedInActivityCard;
