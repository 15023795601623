import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { Play, Pause } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const AudioPlayer = ({ accountID, meetingID }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioMetadata, setAudioMetadata] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      if (!accountID || !meetingID) return;
      try {
        setIsLoading(true);
        setError(null);
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";

        // Fetch audio URL
        const audioUrlResponse = await axios.get(
          `${target}/api/accounts/${accountID}/events/${meetingID}/sas-url`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setAudioUrl(audioUrlResponse.data.url);

        // Fetch audio metadata
        const metadataResponse = await axios.get(
          `${target}/api/accounts/${accountID}/events/${meetingID}/audio/metadata`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setAudioMetadata(metadataResponse.data.audio_metadata);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch audio data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accountID, meetingID, getAccessTokenSilently]);

  useEffect(() => {
    if (audioMetadata) {
      if (audioMetadata.duration && audioMetadata.duration !== "") {
        console.log("Duration from metadata:", audioMetadata.duration);
        setDuration(parseFloat(audioMetadata.duration));
      } else {
        estimateDuration();
      }
    }
  }, [audioMetadata]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && audioUrl) {
      const handleLoadedMetadata = () => {
        if (isFinite(audio.duration) && audio.duration > 0) {
          setDuration(audio.duration);
        }
      };

      const handleTimeUpdate = () => {
        if (!isDragging) {
          setCurrentTime(audio.currentTime);
        }
      };

      const handleError = (e) => {
        console.error("Audio error:", e);
        setError("Failed to load audio");
        setIsLoading(false);
      };

      const handleProgress = () => {
        if (audio.buffered.length > 0) {
          const loadedPercentage =
            (audio.buffered.end(audio.buffered.length - 1) / (duration || 1)) *
            100;
          setLoadProgress(loadedPercentage);
          if (loadedPercentage >= 100) {
            setIsLoading(false);
          }
        }
      };

      const handleCanPlay = () => {
        setCanPlay(true);
        setIsLoading(false);
      };

      audio.src = audioUrl;
      audio.preload = "auto";
      audio.load();

      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("error", handleError);
      audio.addEventListener("progress", handleProgress);
      audio.addEventListener("canplay", handleCanPlay);

      return () => {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("error", handleError);
        audio.removeEventListener("progress", handleProgress);
        audio.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, [audioUrl, isDragging, duration, audioMetadata]);

  const estimateDuration = () => {
    console.log("Estimating duration...");
    if (audioMetadata && (audioMetadata.file_size || audioMetadata.duration)) {
      if (audioMetadata.duration && audioMetadata.duration !== "") {
        console.log("Duration from metadata:", audioMetadata.duration);
        setDuration(parseInt(audioMetadata.duration));
        return;
      }
      console.log("Estimating duration from file size...");
      const fileSize = parseInt(audioMetadata.file_size);
      console.log("File size:", fileSize);
      // Estimate bitrate (adjust this value based on your typical WebM audio bitrate)
      const estimatedBitrate = 128000; // 128 kbps

      const estimatedDuration = (fileSize * 8) / estimatedBitrate;
      setDuration(estimatedDuration);
    } else {
      console.error("Unable to estimate duration: missing file size");
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current && canPlay) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((e) => {
          console.error("Playback failed:", e);
          setError("Playback failed");
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    if (!isFinite(time) || time < 0) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSliderChange = (value) => {
    setCurrentTime(value);
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
  };

  const handleSliderDragStart = () => {
    setIsDragging(true);
  };

  const handleSliderDragEnd = () => {
    setIsDragging(false);
  };

  const SkipForward15 = ({
    size = 32,
    fill = "#808080",
    opacity = 1,
    onClick,
    cursor = "pointer",
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ cursor, opacity }}
      onClick={onClick}
    >
      {/* Smaller arrow */}
      <path d="M20 12a8 8 0 11-8-8c1.8 0 3.6.7 4.9 2L20 8.5" />
      <path d="M20 4.5v4h-4" />

      {/* Plain and outlined "15" */}
      <text
        x="12"
        y="16"
        fontSize="8"
        fontWeight="normal"
        stroke={fill}
        strokeWidth="0.5"
        fill={fill}
        textAnchor="middle"
      >
        15
      </text>
    </svg>
  );

  const SkipBackward15 = ({
    size = 32,
    fill = "#808080",
    opacity = 1,
    onClick,
    cursor = "pointer",
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ cursor, opacity }}
      onClick={onClick}
    >
      {/* Counterclockwise arrow for skip backward */}
      <path d="M4 12a8 8 0 108-8c-1.8 0-3.6.7-4.9 2L4.5 8.5" />
      {/* Adjusted the arrowhead to align perfectly */}
      <path d="M4 4.5v4h4" />s{/* Plain and outlined "15" */}
      <text
        x="12"
        y="16"
        fontSize="8"
        fontWeight="normal"
        stroke={fill}
        strokeWidth="0.5"
        fill={fill}
        textAnchor="middle"
      >
        15
      </text>
    </svg>
  );
  return (
    <Box p={4} bg="white" borderRadius="md" width="100%" maxWidth="600px">
      <audio ref={audioRef} />
      <VStack spacing={4}>
        <Box width="100%" position="relative">
          <Slider
            aria-label="audio-progress"
            value={currentTime}
            min={0}
            max={duration || 100}
            onChange={handleSliderChange}
            onMouseDown={handleSliderDragStart}
            onMouseUp={handleSliderDragEnd}
            onTouchStart={handleSliderDragStart}
            onTouchEnd={handleSliderDragEnd}
            isDisabled={!canPlay || duration === 0}
          >
            <SliderTrack bg="gray.200">
              <SliderFilledTrack bg="blue.500" />
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                height="100%"
                bg="gray.400"
                style={{
                  width: `${loadProgress}%`,
                  transition: "width 0.3s ease-in-out",
                }}
              />
            </SliderTrack>
            <SliderThumb boxSize={4} />
          </Slider>
        </Box>
        <HStack spacing={4} justify="space-between" width="100%">
          <Text fontSize="sm">{formatTime(currentTime)}</Text>
          <HStack spacing={4}>
            <SkipBackward15
              size={32}
              fill="#000000"
              onClick={() => {
                if (audioRef.current) audioRef.current.currentTime -= 15;
              }}
              cursor={canPlay ? "pointer" : "not-allowed"}
              opacity={canPlay ? 1 : 0.5}
            />
            <div
              onClick={togglePlayPause}
              style={{ cursor: canPlay ? "pointer" : "not-allowed" }}
            >
              {isPlaying ? (
                <Pause
                  size={32}
                  fill="#000000"
                  color="#000000"
                  opacity={canPlay ? 1 : 0.5}
                />
              ) : (
                <Play
                  size={32}
                  fill="#000000"
                  color="#000000"
                  opacity={canPlay ? 1 : 0.5}
                />
              )}
            </div>
            <SkipForward15
              size={32}
              fill="#000000"
              onClick={() => {
                if (audioRef.current) audioRef.current.currentTime += 15;
              }}
              cursor={canPlay ? "pointer" : "not-allowed"}
              opacity={canPlay ? 1 : 0.5}
            />
          </HStack>
          <Text fontSize="sm">{formatTime(duration)}</Text>
        </HStack>
      </VStack>
      {isLoading && (
        <Text textAlign="center">Loading... {loadProgress.toFixed(0)}%</Text>
      )}
      {error && (
        <Text color="red.500" textAlign="center">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default AudioPlayer;
