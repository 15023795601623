// src/components/ChartGrid.js

import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import BarCard from "./BarCard";
import PieCard from "./PieCard";

/**
 * Utility function to capitalize the first letter of a string
 * @param {string} string - The string to capitalize
 * @returns {string} - Capitalized string
 */
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * ChartGrid Component
 * Renders a grid of various charts based on the provided data.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.boxBg - Background color for the chart boxes.
 * @param {Object} props.transformedData - Transformed data from the dashboard.
 * @param {string} props.primaryColor - Primary color for the charts.
 * @param {string} props.secondaryColor - Secondary color for the charts.
 */
export const ChartGrid = ({ boxBg, transformedData, primaryColor }) => {
  // Destructure the necessary data from transformedData
  const {
    leadSourceChartData, // Added leadSourceChartData
    activityData,
    activityChartData,
  } = transformedData;

  // Theme Colors
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("white", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const buttonColor = useColorModeValue("gray.500", "gray.300");

  // Extract available lead sources from the transformed data
  const leadSources = Object.keys(leadSourceChartData);

  // State for selected lead source
  const [selectedLeadSource, setSelectedLeadSource] =
    useState("All Lead Sources"); // Default selection

  // Handle dropdown selection
  const handleLeadSourceSelect = (leadSource) => {
    setSelectedLeadSource(leadSource);
  };

  // Prepare the data based on selected lead source
  const getFilteredLeadSourceData = () => {
    return leadSourceChartData[selectedLeadSource];
  };

  const pipelineChartData = getFilteredLeadSourceData();

  return (
    <>
      {/* Top Row: Lead Source Overview */}
      <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px" mb="20px" px="20px">
        <Box bg={boxBg} p="20px" borderRadius="lg" boxShadow="sm">
          {/* Header with Title and Dropdown */}
          <Flex
            justify="space-between"
            align="center"
            mb="20px"
            flexWrap="wrap"
          >
            <Text fontSize="lg" fontWeight="bold">
              Pipeline
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg={bgColor}
                borderWidth="1px"
                borderColor={borderColor}
                color={buttonColor}
                _hover={{ bg: selectedBgColor }}
                _active={{ bg: selectedBgColor }}
                borderRadius="md"
                minW="160px"
              >
                {capitalizeFirstLetter(selectedLeadSource)}
              </MenuButton>
              <MenuList>
                {leadSources.map((leadSource) => (
                  <MenuItem
                    key={leadSource}
                    onClick={() => handleLeadSourceSelect(leadSource)}
                    bg={
                      selectedLeadSource === leadSource
                        ? selectedBgColor
                        : "transparent"
                    }
                    _hover={{ bg: selectedBgColor }}
                    fontWeight={
                      selectedLeadSource === leadSource ? "semibold" : "normal"
                    }
                  >
                    {capitalizeFirstLetter(leadSource)}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>

          {/* BarCard displaying data based on selected lead source */}
          {pipelineChartData ? (
            <BarCard
              barData={pipelineChartData}
              chartHeight={300}
              showLegend={false} // No need for legend since there's only one dataset
              primaryColor={primaryColor}
            />
          ) : (
            <Box>No Data Available for Selected Lead Source</Box>
          )}
        </Box>
      </SimpleGrid>

      {/* Middle Row: Activity Chart & Activity by Stage */}
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap="20px" px="20px" mb="20px">
        {/* Activity Chart (by Rep or Type) */}
        <Box bg={boxBg} p="20px" borderRadius="lg" boxShadow="sm">
          {activityChartData ? (
            <PieCard
              pieData={activityChartData}
              primaryColor={primaryColor}
              // Optionally, pass additional props if needed
            />
          ) : (
            <Box>No Activity Data Available</Box>
          )}
        </Box>

        {/* Activity by Stage */}
        <Box bg={boxBg} p="20px" borderRadius="lg" boxShadow="sm">
          <BarCard
            barData={activityData}
            chartHeight={300}
            showLegend={true} // Show legend for multiple datasets
            primaryColor={primaryColor}
          />
        </Box>
      </SimpleGrid>
    </>
  );
};

export default ChartGrid;
