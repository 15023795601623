// PieCard.js
import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { CHART_COLORS } from "../constants";

/**
 * PieCard Component
 * Renders a pie chart along with its legend.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.pieData - The data for the pie chart.
 * @param {Object} rest - Additional props.
 */
export default function PieCard({ pieData, ...rest }) {
  const textColor = useColorModeValue("b_gray.300", "white");
  const lightTextColor = useColorModeValue("b_gray.300", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  /**
   * Calculate total for percentage calculation
   */
  const total = pieData.datasets[0].data.reduce((acc, val) => acc + val, 0);

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
          {pieData.title}
        </Text>
      </Flex>

      {/* Chart container */}
      <Box w="75%" mx="auto">
        <PieChart
          chartData={pieData.datasets[0].data}
          chartOptions={{
            labels: pieData.labels,
            colors: CHART_COLORS.slice(0, pieData.labels.length),
            chart: {
              width: "100%",
              height: "300px",
            },
            states: {
              hover: {
                filter: {
                  type: "none",
                },
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: true, // Enable data labels
              formatter: (val) => `${val.toFixed(1)}%`, // Show only percentage with one decimal
              style: {
                colors: ["white"],
              },
            },
            hover: { mode: null },
            plotOptions: {
              pie: {
                expandOnClick: false,
              },
            },
            fill: {
              colors: CHART_COLORS.slice(0, pieData.labels.length),
            },
            tooltip: {
              enabled: true,
              theme: "dark",
              y: {
                formatter: (val) =>
                  `${val} (${((val / total) * 100).toFixed(1)}%)`,
              },
            },
          }}
        />
      </Box>

      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        <Flex flexWrap="wrap" justifyContent="space-around" w="100%">
          {pieData.labels.map((label, index) => (
            <Flex
              key={label}
              direction="column"
              alignItems="center"
              minW="80px"
              mb="10px"
            >
              <Flex alignItems="center" mb="5px">
                <Box
                  h="8px"
                  w="8px"
                  bg={CHART_COLORS[index % CHART_COLORS.length]}
                  borderRadius="50%"
                  mr="4px"
                />
                <Text fontSize="xs" color={lightTextColor} fontWeight="700">
                  {label}
                </Text>
              </Flex>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {pieData.datasets[0].data[index]}
                {/* Optionally, display percentage
                ({((pieData.datasets[0].data[index] / total) * 100).toFixed(1)}%) */}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Card>
    </Card>
  );
}
