import React from 'react';
import { Box, Flex, Icon, Text, Tag, VStack, Image } from '@chakra-ui/react';
import { FaBuilding, FaIndustry, FaMapMarkerAlt, FaUser } from 'react-icons/fa';
import { MdTrackChanges } from 'react-icons/md';
import LystoLogo from 'assets/img/lysto-logo-simple.svg';

const AccountDetail = ({ account }) => {
  const { business_name, industry, location, contacts = [], deals = [], summary } = account;

  return (
    <Box bg="white" p={6} pt={4} borderRadius="md" boxShadow="sm" mb={1}>
      <VStack spacing={4} align="stretch">

        {/* Summary Section */}
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>Summary</Text>
          <Flex 
            alignItems="center" 
            borderWidth="1px" 
            borderColor="gray.300" 
            borderRadius="lg" 
            p={3}
          >
            <Image 
              src={LystoLogo} 
              alt="Lysto Logo" 
              width="24px" 
              height="24px" 
              mr={3}
            />
            <Text fontSize="sm" color="gray.600">
              {summary || "This is a summary of the account and its key activities. The summary can provide an overview of the relationship with the account."}
            </Text>
          </Flex>
        </Box>

        {/* Overview Section */}
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>Overview</Text>
          <Flex alignItems="center" flexWrap="wrap">
            <Tag size="lg" borderRadius="lg" mr={2} mb={2} px={3} py={1} bg="white" borderWidth="1px" borderColor="gray.300">
              <Flex alignItems="center">
                <Icon as={FaBuilding} color="gray.500" mr={2} />
                <Text fontSize="sm" color="black">{business_name}</Text>
              </Flex>
            </Tag>
            <Tag size="lg" borderRadius="lg" mr={2} mb={2} px={3} py={1} bg="white" borderWidth="1px" borderColor="gray.300">
              <Flex alignItems="center">
                <Icon as={FaIndustry} color="gray.500" mr={2} />
                <Text fontSize="sm" color="black">{industry}</Text>
              </Flex>
            </Tag>
            <Tag size="lg" borderRadius="lg" mr={2} mb={2} px={3} py={1} bg="white" borderWidth="1px" borderColor="gray.300">
              <Flex alignItems="center">
                <Icon as={FaMapMarkerAlt} color="gray.500" mr={2} />
                <Text fontSize="sm" color="black">{location}</Text>
              </Flex>
            </Tag>
          </Flex>
        </Box>

        {/* Contacts Section */}
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>Contacts</Text>
          <Flex alignItems="center" flexWrap="wrap">
            {contacts.map((contact, index) => (
              <Tag key={index} size="lg" borderRadius="lg" mr={2} mb={2} px={3} py={1} bg="white" borderWidth="1px" borderColor="gray.300">
                <Flex alignItems="center">
                  <Icon as={FaUser} color="gray.500" mr={2} />
                  <Text fontSize="sm" color="black">{contact}</Text>
                </Flex>
              </Tag>
            ))}
          </Flex>
        </Box>

        {/* Deals Section */}
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={2}>Deals</Text>
          <Flex alignItems="center" flexWrap="wrap">
            {deals.map((deal, index) => (
              <Tag key={index} size="lg" borderRadius="lg" mr={2} mb={2} px={3} py={1} bg="white" borderWidth="1px" borderColor="gray.300">
                <Flex alignItems="center">
                  <Icon as={MdTrackChanges} color="gray.500" mr={2} />
                  <Text fontSize="sm" color="black">{deal}</Text>
                </Flex>
              </Tag>
            ))}
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default AccountDetail;
