import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  VStack,
  Flex,
  useToast,
} from "@chakra-ui/react";

const EmailDraftModal = ({ isOpen, onClose, onDelete, selectedCase }) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const textareaRef = useRef(null);

  const handleDelete = useCallback(() => {
    onDelete(selectedCase.id);
    onClose();
  }, [selectedCase, onDelete, onClose]);

  useEffect(() => {
    const fetchDraftEmail = async () => {
      setIsLoading(true);
      try {
        // Simulating an API call with setTimeout
        await new Promise((resolve) => setTimeout(resolve, 10));

        // Dummy data based on the conversation
        const dummyData = {
          to: "michael@bluth.com",
          subject: "Follow-up and Demo Scheduling - Lysto AI",
          body: `Hi Michael,

I hope this email finds you well. I wanted to follow up on our recent discussion and see about scheduling that demo call for Lysto.

To briefly recap, we covered:

 1. Automating time-consuming tasks
 2. Streamlining CRM updates
 3. Addressing recording issues in sales calls

Before we proceed with the demo, it would be helpful if you could:

 1. Provide a list of your top five most time-consuming tasks for potential automation
 2. Share the slides you're currently using for sales presentations

Also, our InfoSec team is reviewing everything to ensure we meet all your security and privacy requirements.

When would be a good time for you to schedule the demo call after Labor Day? I'm flexible and can work around your schedule.

Best regards,
Kyle`,
        };

        setTo(dummyData.to);
        setSubject(dummyData.subject);
        setBody(dummyData.body);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching draft email:", error);
        setIsLoading(false);
        toast({
          title: "Error",
          description: "Failed to load the email draft. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    if (isOpen) {
      fetchDraftEmail();
    }
  }, [isOpen, toast]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [body]);

  const handleBodyChange = (e) => {
    setBody(e.target.value);
  };

  const handleSave = () => {
    // Implement save functionality here
    console.log("Saving draft:", { to, subject, body });
    toast({
      title: "Draft saved",
      description: "The email draft has been saved to your inbox for review.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onClose();
  };

  const handleSend = () => {
    // Implement send functionality here
    console.log("Sending email:", { to, subject, body });
    toast({
      title: "Email sent",
      description: "The email has been sent successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    handleDelete();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      closeOnOverlayClick={true}
      returnFocusOnClose={true}
    >
      <ModalOverlay />
      <ModalContent className="max-w-[60vw] max-h-[90vh]">
        <ModalHeader>Email Draft</ModalHeader>
        <Flex className="absolute right-2 top-2">
          <ModalCloseButton onClick={onClose} className="static" />
        </Flex>
        <ModalBody>
          <VStack spacing={4} className="h-[60vh] overflow-auto text-md">
            <Input
              placeholder="To: (Recipient email addresses)"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              isDisabled={isLoading}
            />
            <Input
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              isDisabled={isLoading}
            />
            <Textarea
              ref={textareaRef}
              placeholder="Email body"
              value={body}
              onChange={handleBodyChange}
              className="w-full min-h-[100px] resize-none overflow-hidden"
              isDisabled={isLoading}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            className="rounded-md ml-3"
            variant="outline"
            onClick={handleSave}
            mr={3}
            isLoading={isLoading}
          >
            Save Draft
          </Button>
          <Button
            className="rounded-md ml-3"
            colorScheme="blue"
            onClick={handleSend}
            isLoading={isLoading}
          >
            Send Email
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmailDraftModal;
