// src/components/BarCard.js

import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import BarChart from "./BarChart";
import { CHART_COLORS } from "../constants";

/**
 * BarCard Component
 * Renders a bar chart along with its corresponding labels and totals.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.barData - The data for the bar chart.
 * @param {Object} rest - Additional props to pass to the Card component.
 * @returns {JSX.Element} The rendered BarCard component.
 */
export default function BarCard({ barData, ...rest }) {
  // Theme Colors
  const textColor = useColorModeValue("gray.700", "white");
  const lightTextColor = useColorModeValue("gray.500", "gray.300");
  const cardColor = useColorModeValue("white", "gray.800");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  // Validate barData Structure
  if (!barData || !barData.datasets || !Array.isArray(barData.datasets)) {
    console.error("Invalid barData structure", barData);
    return (
      <Card p="20px">
        <Text>No data available</Text>
      </Card>
    );
  }

  // Filter datasets that have a label
  const validDatasets = barData.datasets.filter(
    (dataset) => dataset && dataset.label
  );

  // Determine whether to show the label card
  const showLabelCard = validDatasets.length > 1;

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex flexDirection="column" h="100%">
        {/* Chart Title */}
        <Text color={textColor} fontSize="xl" fontWeight="600" mb="15px">
          {barData.title || "Activities by Stage"}
        </Text>

        {/* Bar Chart */}
        <Box flex="1" mb="15px" h="250px">
          <BarChart barData={barData} colors={CHART_COLORS} />
        </Box>

        {/* Label Card: Conditionally Rendered */}
        {showLabelCard && (
          <Card
            bg={cardColor}
            boxShadow={cardShadow}
            w="100%"
            p="15px"
            px="20px"
          >
            <Flex flexWrap="wrap" justifyContent="space-around" w="100%">
              {validDatasets.map((dataset, index) => (
                <Flex
                  key={dataset.label}
                  direction="column"
                  alignItems="center"
                  minW="100px"
                  mb="10px"
                >
                  {/* Label with Color Indicator */}
                  <Flex alignItems="center" mb="5px">
                    <Box
                      h="8px"
                      w="8px"
                      bg={CHART_COLORS[index % CHART_COLORS.length]}
                      borderRadius="50%"
                      mr="4px"
                    />
                    <Text fontSize="xs" color={lightTextColor} fontWeight="700">
                      {dataset.label}
                    </Text>
                  </Flex>

                  {/* Total Value */}
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    {Array.isArray(dataset.data)
                      ? dataset.data.reduce((a, b) => a + b, 0)
                      : "N/A"}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Card>
        )}
      </Flex>
    </Card>
  );
}
