import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

const MeetingContext = ({ meetingData }) => {
  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mb={4}>
      <Flex
        justifyContent="space-between"
        alignItems="left"
        mb={6}
        flexDirection="column"
      >
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Meeting Context
        </Text>
        <Text fontSize="md" fontWeight="normal">
          {/* NEED TO FETCH REAL DATA */}
          {meetingData.context}
        </Text>
      </Flex>
    </Box>
  );
};

export default MeetingContext;
