// activityData.js

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
const lastWeek = new Date(today);
lastWeek.setDate(lastWeek.getDate() - 7);
const lastMonth = new Date(today);
lastMonth.setMonth(lastMonth.getMonth() - 1);

export const activities = [
  {
    id: 1,
    type: "meeting",
    action: "scheduled",
    users: ["Manuel ", "Alex Chen"],
    subject: "Project Kickoff",
    datetime: today.toISOString(),
    details: {
      location: "Zoom",
      duration: "1 hour",
      notes: "Discuss initial project requirements and timelines.",
    },
    description: "Manuel scheduled a meeting 'Project Kickoff'", // New field
  },
  {
    id: 2,
    type: "slack",
    action: "messaged",
    users: ["Alex Chen"],
    channel: "Lysto - Bluth",
    datetime: new Date(Date.now() - 86400000).toISOString(),
    details: {
      messageText:
        "Hey team, I have completed the task and pushed the changes.",
    },
    description: "Alex Chen sent a message to the Lysto - Bluth channel", // New field
  },
  {
    id: 3,
    type: "meeting",
    action: "canceled",
    users: ["Manuel "],
    subject: "Monthly Review",
    datetime: lastWeek.toISOString(),
    details: {
      reason: "Scheduling conflict",
      reschedule: "Next month",
    },
    description: "Manuel canceled the meeting 'Monthly Review'", // New field
  },
  {
    id: 5,
    type: "email",
    action: "sent",
    users: ["Manuel ", "Alex Chen"],
    subject: "Design Feedback",
    datetime: today.toISOString(),
    details: {
      bodyPreview: "Here is the feedback on the latest design...",
      attachments: ["design-feedback.pdf"],
    },
    description: "Manuel sent an email to Alex Chen with subject 'Design Feedback'", // New field
  },
  {
    id: 6,
    type: "email",
    action: "received",
    users: ["Alex Chen", "Manuel"],
    subject: "Invoice for Services",
    datetime: yesterday.toISOString(),
    details: {
      bodyPreview: "Please find attached the invoice for the last month...",
      attachments: ["invoice-august.pdf"],
    },
    description: "Alex Chen received an email from Manuel with subject 'Invoice for Services'", // New field
  },
  {
    id: 7,
    type: "slack",
    action: "sent",
    users: ["Manuel "],
    channel: "Project Updates",
    datetime: lastMonth.toISOString(),
    details: {
      messageText: "The latest updates have been deployed successfully.",
    },
    description: "Manuel sent a message to the Project Updates channel", // New field
  },
  {
    id: 8,
    type: "task",
    action: "created",
    users: ["Manuel "],
    subject: "Prepare Q3 Report",
    datetime: lastWeek.toISOString(),
    details: {
      deadline: "End of the month",
      priority: "High",
    },
    description: "Manuel created a task 'Prepare Q3 Report'", // New field
  },
  {
    id: 9,
    type: "task",
    action: "completed",
    users: ["Alex Chen"],
    subject: "Update Client Portfolio",
    datetime: lastMonth.toISOString(),
    details: {
      completionDate: "Two days early",
      notes: "Portfolio updated with latest project images.",
    },
    description: "Alex Chen completed the task 'Update Client Portfolio'", // New field
  },
  {
    id: 10,
    type: "file",
    action: "added",
    users: ["Alex Chen"],
    filename: "ProjectRequirements.pdf",
    datetime: lastWeek.toISOString(),
    details: {
      fileSize: "500KB",
      uploadedBy: "Alex Chen",
    },
    description: "Alex Chen added a file 'ProjectRequirements.pdf'", // New field
  },
  {
    id: 11,
    type: "meeting",
    action: "scheduled",
    users: ["Manuel ", "Micha"],
    subject: "Final Review Meeting",
    datetime: lastMonth.toISOString(),
    details: {
      location: "Microsoft Teams",
      duration: "45 minutes",
      notes: "Finalize all project deliverables.",
    },
    description: "Manuel scheduled a meeting 'Final Review Meeting'", // New field
  },
  {
    id: 12,
    type: "meeting",
    action: "updated",
    users: ["Manuel "],
    subject: "Client Catch-up",
    datetime: lastMonth.toISOString(),
    details: {
      location: "Office",
      duration: "1 hour",
      notes: "Client added two more agenda items.",
    },
    description: "Manuel updated the meeting 'Client Catch-up'", // New field
  },
  {
    id: 13,
    type: "meeting",
    action: "canceled",
    users: ["Alex Chen"],
    subject: "Weekly Team Meeting",
    datetime: lastMonth.toISOString(),
    details: {
      reason: "Team is on leave",
      reschedule: "To be confirmed",
    },
    description: "Alex Chen canceled the meeting 'Weekly Team Meeting'", // New field
  },
];
