import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Flex,
  VStack,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ChatMessage from "views/lysto/Components/ChatMessage.js";
import { useTheme } from "@chakra-ui/react";
import { AnnotationModal } from "views/lysto/Components/AnnotationModal.js";
import { MdArchive, MdNoteAdd, MdSend } from "react-icons/md";

export function EmailModal({
  isConversationOpen,
  selectedThreadData,
  currentConversation,
  selectedThreadId,
  tableData,
  onMessageSent,
  sentMessages,
  account_ID,
  flow_ID,
  user_ID,
  onClose,
  onConversationUpdate,
}) {
  if (!currentConversation) {
    return null;
  }
  const [textAreaValue, setTextAreaValue] = useState("");
  const [originalContent, setOriginalContent] = useState(""); // New state to hold the original content
  const [last_user_message_ID, setLastUserMessageID] = useState("");
  const [customerEmail, setCustomerEmail] = useState(""); // New state to hold the customer email
  const [lastUserMessageWasForwarded, setLastUserMessageWasForwarded] =
    useState(false);
  const [draft_message_ID, setDraftMessageID] = useState("");
  const [lastUserExternalMessageID, setLastUserExternalMessageID] =
    useState("");
  const theme = useTheme();
  const borderColorEditor = theme.colors.gray[200];
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const toggleAnnotationModal = () => {
    setIsAnnotationModalOpen((prev) => !prev);
  };

  const target =
    process.env.REACT_APP_NODE_ENV === "local"
      ? "https://awning-ai-awning-ai-staging.azurewebsites.net"
      : "https://awning-ai.azurewebsites.net";

  const signature = {
    signature: "Best regards,\nAgent",
  };

  useEffect(() => {
    const matchedConversation = currentConversation;
    if (matchedConversation) {
      const draftMessage = matchedConversation.find(
        (msg) => msg.status === "draft" && msg.identity === "agent"
      );
      const lastMessage = matchedConversation
        .slice()
        .reverse()
        .find((msg) => msg.identity === "customer");
      if (lastMessage) {
        setLastUserExternalMessageID(
          lastMessage.external_message_id.toString()
        );
        setLastUserMessageWasForwarded(lastMessage.is_forwarded);
        setLastUserMessageID(lastMessage.message_id);
      }
      if (draftMessage) {
        let messageContent = draftMessage.message_content;
        if (messageContent.endsWith(signature.signature)) {
          messageContent = messageContent
            .replace(signature.signature, "")
            .trim();
        }
        setTextAreaValue(draftMessage.message_content);
        setOriginalContent(draftMessage.message_content); // Store the original content
        setDraftMessageID(draftMessage.message_id);
        setCustomerEmail(draftMessage.email);
      }
    }
    if (!isConversationOpen) {
      setTextAreaValue("");
    }
  }, [selectedThreadId, isConversationOpen]);

  const updateDraftContent = () => {
    // New function to update draft content
    if (originalContent !== textAreaValue && textAreaValue.trim() !== "") {
      const message_ID = draft_message_ID;
      if (!message_ID) {
        // There is no draft message
        return;
      }
      const requestBody = {
        message_content: textAreaValue,
      };
      fetch(
        target +
          `/api/accounts/${account_ID}/flows/${flow_ID}/users/${user_ID}/messages/${message_ID}/update-draft/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(requestBody),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok: " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log("Draft updated:", data);
        })
        .catch((error) => {
          console.error("Error updating the draft:", error);
        });
    }
  };

  // Helper function to remove HTML tags from a string
  function stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function getUTCDate() {
    const now = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = now.getUTCDate();
    const monthIndex = now.getUTCMonth();
    const year = now.getUTCFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }

  function getUTCTime() {
    const now = new Date();
    let hours = now.getUTCHours();
    let minutes = now.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  }

  const handleArchiveConversation = () => {
    // console.log("hello");
    const archiveEndpoint = `${target}/api/accounts/${account_ID}/flows/${flow_ID}/users/${user_ID}/threads/${selectedThreadId}/toggle-archive/`;

    fetch(archiveEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Conversation archived:", data);
        onConversationUpdate();
        onClose(); // Close the modal or refresh the view as needed
      })
      .catch((error) => {
        console.error("Error archiving the conversation:", error);
      });
  };

  const handleSend = () => {
    // Check if the message is already being sent or if the text area is empty
    if (isSending || !stripHtml(textAreaValue).trim()) {
      return;
    }

    // Set isSending to true to disable the send button
    setIsSending(true);
    const message_ID = draft_message_ID || last_user_message_ID;
    const messageWithSignature = `${textAreaValue}\n\n${signature.signature}`;
    const requestBody = {
      message_content: messageWithSignature,
      reply_to_external_message_id: lastUserExternalMessageID,
      customer_email: customerEmail,
      last_user_message_ID: last_user_message_ID,
      previous_message_was_forwarded: lastUserMessageWasForwarded,
    };
    fetch(
      target +
        `/api/accounts/${account_ID}/flows/${flow_ID}/users/${user_ID}/messages/${message_ID}/send-draft/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Network response was not ok: " + response.statusText
          );
        }
        return response.json();
      })
      .then(() => {
        setTextAreaValue("");
        setDraftMessageID("");
        onMessageSent(selectedThreadId, {
          user_name: "Agent",
          message_content: messageWithSignature,
          status: "sent",
          identity: "agent",
          type: "message",
          date_sent: getUTCDate(), // Dynamic UTC date
          time_sent: getUTCTime(), // Dynamic UTC time
        });
        onConversationUpdate();
        console.log("update function in modal");
        onClose();
      })
      .catch((error) => {
        console.error("Error sending the draft message:", error);
      })
      .finally(() => {
        // Re-enable the send button by setting isSending to false
        setIsSending(false);
      });
  };

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <Modal
      isOpen={isConversationOpen}
      onClose={() => {
        updateDraftContent(); // Call the new function when the modal is closed
        onClose();
      }}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <style>
          {`
            .ql-container, .ql-toolbar {
              border-color: ${borderColorEditor} !important;
            }
            .ql-editor p {
              padding-bottom: 12px;
            }
            .ql-editor ul {
              padding-bottom: 12px;
            }
          `}
        </style>
        <ModalHeader>Conversation</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Flex mt={2}>
            <VStack
              align="start"
              overflowY="auto"
              height="80vh"
              borderWidth="1px"
              borderRadius="sm"
              p={2}
              flex="1"
              mr="4"
            >
              <ChatMessage
                selectedThreadId={selectedThreadId}
                currentConversation={currentConversation}
                notes={
                  Array.isArray(tableData)
                    ? tableData.find(
                        (item) => item.thread_id === selectedThreadId
                      )?.notes || []
                    : []
                }
                sentMessages={sentMessages}
              />
            </VStack>
            <ReactQuill
              value={textAreaValue}
              onChange={setTextAreaValue}
              modules={modules}
              sx={{
                ".ql-container": {
                  borderColor: "blue",
                  borderRadius: "lg",
                },
              }}
              style={{
                flex: "1",
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
                borderRadius: "md",
                borderColor: "red",
              }}
              editorStyle={{
                height: "76vh",
              }}
            />
          </Flex>
          <Flex justifyContent="space-between" mt={2}>
            {/* Left-aligned buttons */}
            <Flex>
              <Button
                variant="action"
                onClick={handleArchiveConversation}
                mr={2}
                leftIcon={<MdArchive />}
              >
                {selectedThreadData.status == "Archived"
                  ? "Unarchive Conversation"
                  : "Archive Conversation"}
              </Button>

              <Button
                variant="action"
                onClick={toggleAnnotationModal}
                mr={2}
                leftIcon={<MdNoteAdd />}
              >
                Add Annotation
              </Button>
            </Flex>
            <Button
              variant="action"
              onClick={handleSend}
              disabled={isSending}
              bg="blue.500" // Changed to a blue background
              color="white" // Changed to white text
              _hover={{ bg: "blue.600" }} // Darker blue on hover
              leftIcon={<MdSend />}
            >
              Sen
            </Button>
          </Flex>
          <AnnotationModal
            isOpen={isAnnotationModalOpen}
            onNoteSubmitted={onMessageSent}
            onClose={toggleAnnotationModal}
            user_ID={user_ID}
            account_ID={account_ID}
            flow_ID={flow_ID}
            thread_ID={selectedThreadId}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EmailModal;
