// LiveActivity.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Button,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { MdEmail, MdEvent, MdAssignment } from "react-icons/md";
import { PiPulse } from "react-icons/pi";
import { FaRegFileAudio } from "react-icons/fa"; // Imported FaAudio for Recordings tab
import LiveActivityTimeline from "./LiveActivityTimeline";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const ITEMS_PER_PAGE = 5;

const LiveActivity = ({ accountId, userId }) => {
  // State Management
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hooks
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  // Determine the API base URL based on the environment
  const getApiBaseUrl = () => {
    // const environment = process.env.REACT_APP_NODE_ENV;
    return "https://lysto-be-tf.azurewebsites.net";
  };

  // Helper function to extract Gong link from description (if needed in future)
  // const extractGongLink = (description) => {
  //   const gongLinkRegex = /https?:\/\/[\w.-]+\.app\.gong\.io\/call\?id=\d+/i;
  //   const match = description?.match(gongLinkRegex);
  //   return match ? match[0] : null;
  // };

  // Helper function to check if title includes a specific keyword
  const titleIncludes = (title, keyword) => {
    return title.toLowerCase().includes(keyword.toLowerCase());
  };

  // Fetch activities from the backend API
  const fetchActivities = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Define the date range: today and 30 days ago
      const endDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
      const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0]; // 30 days ago

      // Retrieve the access token with appropriate scope
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:activities",
        },
      });

      // Define headers with authorization
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      // Construct the full API URL
      const apiBaseUrl = getApiBaseUrl();
      const apiUrl = `${apiBaseUrl}/api/accounts/${accountId}/deal/activity-detail-by-days-and-users`;

      // Prepare query parameters
      const params = {
        start_date: startDate,
        end_date: endDate,
      };

      // Include user_id only if it's a valid positive number
      if (userId && userId > 0) {
        params.user_id = userId;
      }

      // Make the GET request using axios
      const response = await axios.get(apiUrl, { headers, params });

      // Validate and set the activities
      if (response.data && Array.isArray(response.data.activities)) {
        console.log("Fetched Activities:", response.data.activities); // Debugging

        const uniqueActivities = [];
        const titleSet = new Set();

        // Filter out duplicate titles
        for (const activity of response.data.activities) {
          const title = activity.title || "";
          if (!titleSet.has(title)) {
            titleSet.add(title);
            uniqueActivities.push(activity);
          }
        }

        // Sort activities in reverse chronological order based on activity_date
        uniqueActivities.sort(
          (a, b) => new Date(b.activity_date) - new Date(a.activity_date)
        );
        setActivities(uniqueActivities);
      } else {
        setActivities([]);
        toast({
          title: "No Activities Found",
          description: "No activities were found for the selected criteria.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error("Error fetching activities:", err);

      // Display error in a toast and state
      setError("An error occurred while fetching activities.");
      toast({
        title: "Error",
        description: "An error occurred while fetching activities.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch activities whenever accountId or userId changes
  useEffect(() => {
    if (accountId) {
      fetchActivities();
    }
  }, [accountId, userId]); // Dependencies: accountId and userId

  // Function to determine the activity category based on new rules
  const getActivityCategory = (activity) => {
    const title = activity.title || "";
    const description = activity.description || "";

    if (titleIncludes(title, "[Gong]")) {
      if (description.trim() !== "") {
        return "recording";
      } else {
        return "meeting";
      }
    } else if (titleIncludes(title, "Email")) {
      return "email";
    } else {
      return "task";
    }
  };

  // Filter activities based on activity category
  const filterActivities = (type) => {
    if (!Array.isArray(activities)) return [];

    return activities.filter((activity) => {
      const category = getActivityCategory(activity);

      if (type === "all") {
        return ["meeting", "recording", "email", "task"].includes(category);
      }

      return category === type;
    });
  };

  // Calculate activity counts based on activity category
  const activityCounts = {
    all: filterActivities("all").length,
    meeting: filterActivities("meeting").length,
    recording: filterActivities("recording").length,
    email: filterActivities("email").length,
    task: filterActivities("task").length,
  };

  // Define tab items with icons and labels
  const tabItems = [
    { icon: PiPulse, label: `All (${activityCounts.all})`, type: "all" },
    {
      icon: MdEvent,
      label: `Meetings (${activityCounts.meeting})`,
      type: "meeting",
    },
    {
      icon: FaRegFileAudio,
      label: `Recordings (${activityCounts.recording})`,
      type: "recording",
    }, // Audio icon for Recordings
    { icon: MdEmail, label: `Emails (${activityCounts.email})`, type: "email" },
    {
      icon: MdAssignment,
      label: `Tasks (${activityCounts.task})`,
      type: "task",
    },
  ];

  // Paginate activities for current page
  const paginatedActivities = (activities) => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return activities.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  // Handle tab change event
  const handleTabChange = (index) => {
    setSelectedTab(index);
    setCurrentPage(1); // Reset to first page when tab changes
  };

  // Pagination controls
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    const filtered = filterActivities(tabItems[selectedTab].type);
    const totalPages = Math.ceil(filtered.length / ITEMS_PER_PAGE);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Determine total pages for the current tab
  const currentFilteredActivities = filterActivities(
    tabItems[selectedTab].type
  );
  const totalPages = Math.ceil(
    currentFilteredActivities.length / ITEMS_PER_PAGE
  );

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mt={1}>
      {/* Tabs for Activity Categories */}
      <Tabs
        variant="line"
        colorScheme="blue"
        index={selectedTab}
        onChange={handleTabChange}
      >
        <TabList>
          {tabItems.map((item, index) => (
            <Tab
              key={index}
              _selected={{
                color: "#1A5BF6",
                fontWeight: "bold",
                borderBottomColor: "#1A5BF6",
              }}
              _focus={{ boxShadow: "none", outline: "none" }}
            >
              <Flex alignItems="center">
                <item.icon style={{ marginRight: "4px" }} />
                <span>{item.label}</span>
              </Flex>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabItems.map((item, index) => {
            const filteredActivities = filterActivities(item.type);
            const paginated = paginatedActivities(filteredActivities);

            return (
              <TabPanel key={index}>
                {isLoading ? (
                  <Spinner size="lg" />
                ) : error ? (
                  <Alert status="error" mt={4}>
                    <AlertIcon />
                    {error}
                  </Alert>
                ) : paginated.length > 0 ? (
                  <LiveActivityTimeline activities={paginated} />
                ) : (
                  <Text textAlign="center" py={4} color="gray.500">
                    No {item.type === "all" ? "activities" : item.type + "s"}{" "}
                    found.
                  </Text>
                )}

                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                  >
                    <Button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Text>
                      Page {currentPage} of {totalPages}
                    </Text>
                    <Button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </Flex>
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default LiveActivity;
