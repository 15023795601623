import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, VStack } from "@chakra-ui/react";
import DealDetail from "./DealDetail";
import Activity from "../Activities/Activity";
import GoBackHeader from "../Components/GoBackHeader";


const DealDetailsPage = () => {
  const [deal, setDeal] = useState(null);
  const [error, setError] = useState(null);
  const { dealId } = useParams();
  const history = useHistory();

  // Simulated API call
  const fetchDealById = (dealId) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (Math.random() > 0.8) {
          reject("Failed to fetch deal");
        } else {
          resolve({
            properties: {
              id: dealId,
              dealname: "Gramercy Tavern",
              summary: "A deal with Gramercy Tavern for catering services.",
              account: "Gramercy Tavern",
              contacts: ["William Carrol", "Alex Chen"],
              tags: [
                { name: "First stage", color: "purple.500" },
                { name: "Pilot", color: "green.500" },
              ],
              stages: [
                {
                  name: "MQL",
                  qualification: true,
                  questions: [
                    {
                      text: "Does the lead have a defined budget?",
                      required: true,
                      answered: true,
                    },
                    {
                      text: "Is the lead's industry relevant?",
                      required: false,
                      answered: false,
                    },
                  ],
                },
                {
                  name: "SQL",
                  qualification: true,
                  questions: [
                    {
                      text: "Has the lead scheduled a meeting?",
                      required: true,
                      answered: false,
                    },
                  ],
                },
                { name: "Contract", qualification: false, questions: [] },
                {
                  name: "Onboarding",
                  qualification: true,
                  questions: [
                    {
                      text: "Has the onboarding call been completed?",
                      required: true,
                      answered: false,
                    },
                    {
                      text: "Is the first milestone reached?",
                      required: false,
                      answered: false,
                    },
                  ],
                },
                { name: "Retention", qualification: false, questions: [] },
              ],
              currentStageIndex: 1, // Assuming the deal is at the SQL stage
            },
          });
        }
      }, 500);
    });
  };

  useEffect(() => {
    const loadDeal = async () => {
      try {
        const dealData = await fetchDealById(dealId);
        setDeal(dealData);
        setError(null); // Reset error if successful
      } catch (error) {
        setError("Error fetching deal information. Please try again later.");
      }
    };

    loadDeal();
  }, [dealId]);

  const handleGoBack = () => {
    history.goBack();
  };

  if (error) {
    return (
      <Box color="red.500" p={4}>
        {error}
      </Box>
    );
  }

  if (!deal) {
    return <Box p={4}>Loading...</Box>;
  }

  return (
    <Box bg="#F6F6F8" p={1} width="100%">
      <Box maxWidth="1800px" marginX="auto">
        <GoBackHeader onGoBack={handleGoBack} title="Deal Information" />
        <VStack spacing={4} align="stretch">
          <DealDetail deal={deal} />
          <Activity />
        </VStack>
      </Box>
    </Box>
  );
};

export default DealDetailsPage;
