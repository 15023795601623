// constants.js
export const PRIMARY_COLOR = "#1A5BF6";
export const SECONDARY_COLOR = "#48BB78";
export const CHART_COLORS = [
  PRIMARY_COLOR,
  "#1A5BF6",
  "#4C7AF7",
  "#7E9DF9",
  "#B0BFFA",
  "#D2DBFC",
  "#061A40",
  "#C5FFFD",
];
