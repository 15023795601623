import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdSettings,
  MdEvent,
  MdEqualizer,
  MdAccountTree,
  MdHail,
  MdTrackChanges,
  MdInbox,
  MdCampaign,
  MdBusiness,
} from "react-icons/md";
import { RiAiGenerate } from "react-icons/ri";

// Admin Imports
import Reports from "views/lysto/Dashboard";
import Campaigns from "views/admin/campaigns";
import Automations from "views/admin/automations";
import Settings from "views/lysto/Settings";
import Leo from "views/lysto/Leo";

// Lysto Views
import MyTasks from "views/lysto/MyTasks";
import Meetings from "views/lysto/Meetings";
import Deals from "views/lysto/Deals";
import DealDetailsPage from "views/lysto/Deals/DealDetailsPage";
import Contacts from "views/lysto/Contacts";
import ContactDetailsPage from "views/lysto/Accounts/AccountDetailsPage"; 
import Accounts from "views/lysto/Accounts";
import AccountDetailsPage from "views/lysto/Accounts/AccountDetailsPage"; 

import MeetingPrepPage from "views/lysto/Meetings/MeetingPrepPage";
import MeetingAnalysis from "views/lysto/Meetings/MeetingAnalysis";

const routes_json = {
  routes: [
    { name: "Inbox" },
    { name: "Meetings" },
    { name: "MeetingDetails" },
    { name: "MeetingAnalysis" },
    { name: "Deals" },
    { name: "DealDetails" },
    { name: "Contacts" },
    { name: "ContactDetails" },  // Route for Contact Details
    { name: "Accounts" },
    { name: "AccountDetails" },  // Route for Account Details
    { name: "Workflows" },
    { name: "Settings" },
    { name: "Leo" },
    { name: "Reports" },
  ],
};

const allowedRouteNames = routes_json.routes.map((route) => route.name);

const routes = [
  {
    name: "Inbox",
    layout: "/admin",
    icon: <Icon as={MdInbox} width="20px" height="20px" color="inherit" />,
    path: "/main",
    component: MyTasks,
  },
  {
    name: "MeetingDetails",
    layout: "/admin",
    path: "/meetings/details/:meetingID",
    component: MeetingPrepPage,
    hidden: true,
  },
  {
    name: "MeetingAnalysis",
    layout: "/admin",
    path: "/meetings/analysis/:meetingID",
    component: MeetingAnalysis,
    hidden: true,
  },
  {
    name: "Meetings",
    layout: "/admin",
    path: "/meetings",
    icon: <Icon as={MdEvent} width="20px" height="20px" color="inherit" />,
    component: Meetings,
  },
  {
    name: "DealDetails",
    layout: "/admin",
    path: "/deals/:dealId",
    component: DealDetailsPage,
    hidden: true,
  },
  {
    name: "Deals",
    layout: "/admin",
    icon: (
      <Icon as={MdTrackChanges} width="20px" height="20px" color="inherit" />
    ),
    path: "/deals",
    component: Deals,
  },
  {
    name: "ContactDetails",  // Route for Contact Details
    layout: "/admin",
    path: "/contacts/:contactId",
    component: ContactDetailsPage,
    hidden: true,  // Hidden from the sidebar but accessible
  },
  {
    name: "Contacts",
    layout: "/admin",
    icon: <Icon as={MdHail} width="20px" height="20px" color="inherit" />,
    path: "/contacts",
    component: Contacts,
  },
  {
    name: "AccountDetails",  // Route for Contact Details
    layout: "/admin",
    path: "/accounts/:accountId",
    component: AccountDetailsPage,
    hidden: true,  // Hidden from the sidebar but accessible
  },
  {
    name: "Accounts",
    layout: "/admin",
    icon: <Icon as={MdBusiness} width="20px" height="20px" color="inherit" />,
    path: "/accounts",
    component: Accounts,
  },
  {
    name: "Campaigns",
    layout: "/admin",
    path: "/campaigns",
    icon: <Icon as={MdCampaign} width="20px" height="20px" color="inherit" />,
    component: Campaigns,
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "/dashboard",
    icon: (
      <Icon as={MdEqualizer} width="20px" height="20px" color="inherit" />
    ),
    component: Reports,
  },
  {
    name: "Workflows",
    layout: "/admin",
    path: "/workflows",
    icon: (
      <Icon as={MdAccountTree} width="20px" height="20px" color="inherit" />
    ),
    component: Automations,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: Settings,
  },
  {
    name: "Leo",
    layout: "/admin",
    path: "/leo",
    icon: <Icon as={RiAiGenerate} width="20px" height="20px" color="inherit" />,
    component: Leo,
  },
].filter((route) => allowedRouteNames.includes(route.name));

export default routes;

