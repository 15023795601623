import React, { useEffect, useState, useCallback } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import ConversationTable from "views/admin/dataTables/components/ConversationTable";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import columnsCases from "./taskColumns";
import TaskModal from "./TaskModal";
import axios from "axios";

export default function Settings() {
  const [tasks, setTasks] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessTokenSilently } = useAuth0();

  const handleDeleteTask = useCallback((taskId) => {
    console.log("Deleting task with id:", taskId);
    console.log("Tasks before deletion:", tasks);
  
    setTasks((prevTasks) => {
      const updatedTasks = prevTasks.filter(task => task.id !== taskId);
      console.log("Updated tasks after deletion:", updatedTasks);
      return updatedTasks;
    });
  }, [tasks]);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!accountID || !userID) return;
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });
        const target = process.env.REACT_APP_NODE_ENV === 'local' ? 'http://localhost:8080' : 'https://lysto-be-tf.azurewebsites.net';
        const response = await axios.get(`${target}/api/accounts/${accountID}/users/${userID}/tasks`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: {
            page_number: 1,
            items_per_page: 10,
          }
        });
        if (response.data) {
          setTasks(response.data);
        }
      } catch (err) {
        console.error("Error fetching tasks:", err);
      }
    };
    fetchTasks();
  }, [accountID, userID, getAccessTokenSilently]);

  const handleClose = () => {
    console.log("Modal is closing");
    onClose();
  };

  const consistentColumns = columnsCases(onOpen, setSelectedCase);

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }}>
      <ConversationTable
        columnsData={consistentColumns}
        tableData={tasks}
        title="Inbox"
      />
      {(selectedCase?.type === "HubSpot" || selectedCase?.type === 'Slack') ? (
        <TaskModal 
          isOpen={isOpen}
          onClose={handleClose}
          selectedCase={selectedCase}
          accountID={accountID}
          onDelete={handleDeleteTask}
        />
      ) : (
        <TaskModal
          isOpen={isOpen}
          onClose={handleClose}
          selectedCase={selectedCase}
          onDelete={handleDeleteTask}
        />
      )}
    </Box>
  );
}
