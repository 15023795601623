import React from "react";
import {
  Flex,
  Avatar,
  useColorModeValue,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import UserProfileModal from "./UserProfileModal"; // Make sure this path is correct

const ProfileComponent = () => {
  const userData = useAuthData();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("#56595E", "white");

  return (
    <>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        mt="auto"
        mb={6}
        p={4}
        borderColor={borderColor}
      >
        <Flex
          fontSize="sm"
          alignItems="center"
          onClick={onOpen}
          cursor="pointer"
        >
          <Avatar
            size="xs"
            name={userData?.name}
            bg="#1A5BF6"
            color="white"
            mr={4}
            ml={2}
          />
          <Text color={textColor}>{userData?.name}</Text>
        </Flex>
      </Flex>

      <UserProfileModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ProfileComponent;
