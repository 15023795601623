import React from "react";
import { Button, Flex } from "@chakra-ui/react";

export function PaginationControls({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount
}) {
  return (
    <div className="pagination">
      <Flex justifyContent="center" mt="0px">
        <Button
          variant="action"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          mr="10px"
        >
          {"<<"}
        </Button>
        <Button
          variant="action"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          mr="10px"
        >
          {"<"}
        </Button>
        <Button
          variant="action"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          mr="10px"
        >
          {">"}
        </Button>
        <Button
          variant="action"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>
      </Flex>
    </div>
  );
}
