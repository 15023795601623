import React from "react";
import {
  Flex,
  Box,
  useColorModeValue,
  Text,
  Badge,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdEmail, MdCalendarMonth, MdOutbox } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlack, faHubspot, faJira } from "@fortawesome/free-brands-svg-icons";
import Card from "components/card/Card.js";
import AutomationModal from "views/admin/automations/components/AutomationModal";

const HubspotIcon = ({ color, ...props }) => (
  <FontAwesomeIcon icon={faHubspot} color={color} {...props} />
);
HubspotIcon.displayName = "HubspotIcon";

const SlackIcon = ({ color, ...props }) => (
  <FontAwesomeIcon icon={faSlack} color={color} {...props} />
);
SlackIcon.displayName = "SlackIcon";

const JiraIcon = ({ color, ...props }) => (
  <FontAwesomeIcon icon={faJira} color={color} {...props} />
);
JiraIcon.displayName = "JiraIcon";

export default function AutomationCard(props) {
  const { title, integrationIcons, status } = props;
  const textColor = useColorModeValue("#56595E", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getIconComponent = (iconName) => {
    switch (iconName.toLowerCase()) {
      case "gmail":
        return MdEmail;
      case "google calendar":
        return MdCalendarMonth;
      case "hubspot":
        return HubspotIcon;
      case "slack":
        return SlackIcon;
      case "jira":
        return JiraIcon;
      default:
        return MdOutbox;
    }
  };

  return (
    <Card py="15px">
      <Flex flexDirection="column" justifyContent="space-between" h="100%">
        <Box>
          <Flex alignItems="center">
            <Flex mr={2}>
              {integrationIcons.map((iconName, index) => {
                const IconComponent = getIconComponent(iconName);
                return (
                  <Box key={index} mr={1}>
                    <IconComponent
                      color={textColor}
                      style={{ width: "15px", height: "15px" }}
                    />
                  </Box>
                );
              })}
            </Flex>
            <Text fontSize="1xl" fontWeight="black" color={textColor}>
              {title}
            </Text>
          </Flex>
        </Box>

        <Flex
          mt={4}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
        >
          <Badge
            borderRadius="md"
            px={2}
            bg={
              status["Automation Type"].toLowerCase() === "automatic"
                ? "blue.400"
                : "gray.500"
            }
            color="white"
          >
            {status["Automation Type"].toUpperCase()}
          </Badge>
          <Button size="sm" variant="action" onClick={onOpen}>
            See More
          </Button>
        </Flex>
      </Flex>
      <AutomationModal isOpen={isOpen} onClose={onClose} stages={5} />
    </Card>
  );
}
